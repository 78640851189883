import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import { colors } from '../../styles/colors';

export const TextInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img {
    width: 60px;
    height: 60px;
  }
`;

export const Text = styled.p`
  font-size: 13px;
  color: ${colors.combobox.text};
  text-align: left;
  align-self: center;
  margin: 0;
  font-weight: normal;
`;

export const Info = styled.p`
  font-size: 15px;
  color: ${colors.combobox.info};
  text-align: right;
  align-self: center;
  margin: 0;
  font-weight: normal;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  height: 32px;
  background: white;
  margin-bottom: 1px;

  &.Mui-focused {
    fieldset {
      border-color: ${colors.combobox.border.focused} !important;
    }
  }

  .MuiSelect-root {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;

    ${TextInfo} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: 10px;

      ${Text} {
        font-size: 13px;
        color: ${colors.combobox.text};
        text-align: left;
        align-self: center;
        margin: 0;
        font-weight: normal;
      }

      ${Info} {
        font-size: 15px;
        color: ${colors.combobox.info};
        text-align: right;
        align-self: center;
        margin: 0;
        font-weight: normal;
      }
    }
  }

  fieldset {
    border-color: ${colors.combobox.border.default};
  }

  &:hover {
    fieldset {
      border-color: ${colors.combobox.border.hover} !important;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #f0f0f0 !important;
  }

  line-height: 30px;
`;

export const ButtonMenuItem = styled(MenuItem)`
  padding: 0;
  height: 32px;
`;

export const Button = styled.button`
  width: 100%;
  min-height: 100%;
  border: 0;
  border-radius: 0 0 4px 4px;
  color: ${colors.combobox.button.text};
  background: ${colors.combobox.button.background};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 42px;
  width: 100%;
  padding: 4px 8px;
`;

export const SearchInput = styled(Input)`
  width: 100%;
`;
