import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ComboboxEmpresas from '../../../components/Combobox/Empresas';
import ContentPage from '../../../components/ContentPage';
import Table from '../../../components/Table';
import EditIcon from '../../../components/CustomIcons/EditIcon';

import FormEdit from './FormEdit';

import Medias from '../../../consts/medias';
import { usuarioString as TiposUsuarioString } from '../../../consts/types';

import formatter from '../../../services/formatter';

import {
  getUsuariosRequest,
  deleteUsuarioRequest,
  ativaUsuarioRequest,
  desativaUsuarioRequest,
} from '../../../store/modules/ecosis/usuarios/actions';

import {
  StyledRemoveCircleIcon,
  TableContainer,
  StyledButton,
  StyledFab,
  StyledAddCircleOutlineIcon,
  StyledAddIcon,
  StyledCheckIcon,
} from './styles';

export default function Usuarios() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const usuarios = useSelector(state => state.ecosis.usuarios.usuarios);
  const loading = useSelector(state => state.ecosis.usuarios.loading);
  const [selectedUsuario, setSelectedUsuario] = useState(null);

  const [empresaId, setEmpresaId] = useState(undefined);
  function handleComboboxEmpresasChange(value) {
    setEmpresaId(value);
  }

  useEffect(() => {
    if (empresaId) dispatch(getUsuariosRequest(empresaId));
  }, [dispatch, empresaId]);

  const [table, setTable] = useState({});
  useEffect(() => {
    function edita(usuario) {
      setSelectedUsuario(usuario);
    }

    function deleta(usuarioId) {
      dispatch(deleteUsuarioRequest(empresaId, usuarioId));
    }

    function ativa(usuarioId) {
      dispatch(ativaUsuarioRequest(empresaId, usuarioId));
    }

    function desativa(usuarioId) {
      dispatch(desativaUsuarioRequest(empresaId, usuarioId));
    }

    setTable({
      columns: [
        { title: 'EMAIL', field: 'email' },
        { title: 'NOME', field: 'nome' },
        { title: 'CPF', field: 'cpf' },
        { title: 'TELEFONE', field: 'telefone' },
        { title: 'TIPO', field: 'tipoFormatted' },
      ],
      data: empresaId
        ? usuarios.map(usuario => ({
            id: usuario.id,
            email: usuario.email,
            nome: usuario.nome,
            cpf: formatter(usuario.cpf).toCpf(),
            telefone: formatter(usuario.telefone).toTelefone(),
            tipo: usuario.tipo,
            tipoFormatted: TiposUsuarioString[usuario.tipo],
            ativo: usuario.ativo,
          }))
        : [],
      actions: [
        rowData => {
          return {
            icon: () => (
              <StyledCheckIcon fontSize="large" ativo={rowData.ativo ? 1 : 0} />
            ),
            tooltip: rowData.ativo ? 'Desativar' : 'Ativar',
            onClick: (event, data) =>
              rowData.ativo ? desativa(data.id) : ativa(data.id),
          };
        },
        {
          icon: () => <EditIcon fontSize="large" />,
          tooltip: 'Editar',
          onClick: (event, rowData) => edita(rowData),
        },
        {
          icon: () => <StyledRemoveCircleIcon fontSize="large" />,
          tooltip: 'Excluir',
          onClick: (event, rowData) => deleta(rowData.id),
        },
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
      },
    });
  }, [dispatch, usuarios, empresaId]);

  return (
    <>
      <ContentPage
        title="Usuários"
        controls={
          !sm ? (
            <>
              <ComboboxEmpresas handleChange={handleComboboxEmpresasChange} />
              <StyledButton
                variant="contained"
                type="button"
                startIcon={<StyledAddCircleOutlineIcon />}
                onClick={() => setSelectedUsuario({})}
                disabled={!empresaId}
              >
                Adicionar
              </StyledButton>
            </>
          ) : (
            <ComboboxEmpresas handleChange={handleComboboxEmpresasChange} />
          )
        }
        content={
          <TableContainer>
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              isLoading={loading}
              autoSizing
            />
          </TableContainer>
        }
      />
      {sm && (
        <StyledFab onClick={() => setSelectedUsuario({})} disabled={!empresaId}>
          <StyledAddIcon />
        </StyledFab>
      )}
      {selectedUsuario && (
        <FormEdit
          empresaId={empresaId}
          usuario={selectedUsuario}
          onClose={() => setSelectedUsuario(null)}
        />
      )}
    </>
  );
}
