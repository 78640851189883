import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MTableBodyRow } from 'material-table';

import Table from '../../Table';
import ColetaChip from '../../ColetaChip';

import formatter, { toPalletECaixa } from '../../../services/formatter';

import {
  addItemColetaRequest,
  showStatusColeta,
} from '../../../store/modules/transportadora/coletas/actions';

import { getPedidosRequest } from '../../../store/modules/transportadora/pedidos/actions';

import { Container, StyledAddCircleIcon, InfoUser } from './styles';

export default function TablePedidos({ filtering }) {
  const dispatch = useDispatch();

  const loadingPedidos = useSelector(
    (state) => state.transportadora.pedidos.loading
  );
  const pedidos = useSelector((state) => state.transportadora.pedidos.pedidos);

  const isSubmitting = useSelector(
    (state) => state.transportadora.coletas.loading
  );
  const coletaSelecionada = useSelector(
    (state) => state.transportadora.coletas.selecionada
  );

  useEffect(() => {
    dispatch(
      getPedidosRequest(
        1,
        500,
        'ufEnderecoCliente,cidadeEnderecoCliente,razaoSocialCliente'
      )
    );
  }, [dispatch]);

  const [columns, setColumns] = useState([
    { title: 'UF', field: 'uf' },
    { title: 'CIDADE', field: 'cidade' },
    { title: 'CLIENTE', field: 'cliente' },
    { title: 'FORNECEDOR', field: 'fornecedor' },
    { title: 'PEDIDO', field: 'pedido' },
    { title: 'PRODUTO', field: 'produto' },
    { title: 'SALDO', field: 'saldo', filtering: false },
    { title: 'LIBERADO', field: 'liberado', filtering: false },
    { title: 'PESO', field: 'peso', filtering: false },
    { title: 'EMBALAGEM', field: 'embalagem', filtering: false },
  ]);

  const handleChangeGroup = (groupColumns) => {
    const oldColumns = columns.map((c) => ({ title: c.title, field: c.field }));
    const newColumns = [...oldColumns];

    groupColumns.forEach((groupColumn, index) => {
      const column = newColumns.find((c) => c.field === groupColumn.field);
      if (column) column.defaultGroupOrder = index;
    });

    if (JSON.stringify(oldColumns) !== JSON.stringify(newColumns))
      setColumns(newColumns);
  };

  function addItemColeta(cargaItemId) {
    if (!isSubmitting) {
      dispatch(addItemColetaRequest(coletaSelecionada.id, cargaItemId));
      dispatch(showStatusColeta(true));
    }
  }

  function rowStyle(rowData) {
    if (!rowData.disponivel) {
      return { opacity: 0.4, fontStyle: 'italic' };
    }
    return null;
  }

  return (
    <Container>
      <Table
        columns={columns}
        data={pedidos.map((item) => ({
          id: item.id,
          uf: item.clienteDestino.uf,
          cidade: item.clienteDestino.cidade,
          cliente: item.clienteDestino.razaoSocial,
          fornecedor: item.cliente.nomeFantasia,
          pedido: `${item.documento.numero}/${item.documento.item}`,
          produto: item.produto,
          saldo: formatter(item.quantidadeTotal).toM2(),
          liberado: formatter(item.saldoTransportadora.saldo.quantidade).toM2(),
          peso: formatter(item.saldoTransportadora.saldo.peso).toKg(),
          embalagem: toPalletECaixa(
            item.saldoTransportadora.saldo.paletes,
            item.saldoTransportadora.saldo.caixas
          ),
          disponivel:
            item.saldoTransportadora.saldo.paletes > 0 ||
            item.saldoTransportadora.saldo.caixas > 0,
          coletas: item.coletas,
        }))}
        actions={[
          (rowData) => ({
            icon: () => <StyledAddCircleIcon fontSize="large" />,
            tooltip: 'Adicionar item à coleta',
            onClick: (event, data) => addItemColeta(data.id),
            hidden: !coletaSelecionada || !rowData.disponivel,
          }),
        ]}
        components={{
          Row: (rowProps) => {
            const { data } = rowProps;
            return (
              <>
                <MTableBodyRow {...rowProps} />
                {data.coletas.length > 0 && (
                  <InfoUser>
                    <td colSpan="11">
                      {data.coletas.map((coleta) => (
                        <ColetaChip
                          key={String(coleta.id)}
                          coletaId={coleta.id}
                          nome={coleta.usuario && coleta.usuario.nome}
                          avatarUrl={
                            coleta.usuario.avatar && coleta.usuario.avatar.url
                          }
                        />
                      ))}
                    </td>
                  </InfoUser>
                )}
              </>
            );
          },
        }}
        options={{
          actionsColumnIndex: -1,
          toolbar: false,
          grouping: true,
          filtering,
          rowStyle,
        }}
        isLoading={loadingPedidos}
        onChangeGroup={handleChangeGroup}
        autoSizing
      />
    </Container>
  );
}

TablePedidos.propTypes = {
  filtering: PropTypes.bool,
};

TablePedidos.defaultProps = {
  filtering: false,
};

