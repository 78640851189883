import styled, { css } from 'styled-components';
import { colors } from '../../styles/colors';
import Medias from '../../consts/medias';

import Paper from '../Paper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${colors.page.background};
`;

export const Header = styled(Paper)`
  display: flex;
  background: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 26px;
  width: 100%;
  padding: 13px 26px;

  ${props =>
    props.isOver
      ? css`
          overflow: hidden;
          z-index: 104;
          position: sticky;
          top: 0px;
        `
      : css`
          box-shadow: none;
        `}

  > p {
    color: ${colors.page.header};
    font-size: 28px;
    text-align: left;
    line-height: 40px;
  }

  @media ${Medias.sm} {
    align-items: flex-start;
    flex-direction: column;
    padding: 13px 13px;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-height: 48px;

  @media ${Medias.sm} {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 26px;
  margin-bottom: 120px;
  background: ${colors.page.background};

  @media ${Medias.sm} {
    padding: 0 13px;
    margin-bottom: 135px;
  }
`;
