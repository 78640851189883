import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignInPage from '../pages/Signin';
import SignUpPage from '../pages/Signup';
import NovaSenhaPage from '../pages/NovaSenha';
import ResetSenha from '../pages/ResetSenha';
import MainPage from '../pages/Main';
import PATHS from '../consts/paths';

export function Routes() {
  return (
    <Switch>
      <Route path={PATHS.signin} exact component={SignInPage} />
      <Route path={PATHS.signup} exact component={SignUpPage} />
      <Route path={PATHS.novaSenha} exact component={NovaSenhaPage} />
      <Route path={PATHS.resetSenha} exact component={ResetSenha} />

      <Route path={PATHS.root} component={MainPage} isPrivate />
    </Switch>
  );
}
