import React, { useCallback, useEffect, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Container } from './styles';

export default function FormSelectWrapper({ children, name, className }) {
  const [selectInputNode, setSelectInputNode] = useState(null);
  const { fieldName, registerField, error } = useField(name);

  const selectInputRef = useCallback(node => {
    if (node !== null) {
      setSelectInputNode(node);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectInputNode,
      path: 'value',
    });
  }, [selectInputNode, fieldName]); // eslint-disable-line

  return (
    <Container error={error} className={className}>
      {cloneElement(children, {
        inputRef: selectInputRef,
        inputProps: {
          error,
        },
      })}
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </Container>
  );
}

FormSelectWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FormSelectWrapper.defaultProps = {
  className: '',
};
