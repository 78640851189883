import styled from 'styled-components';
import { Form } from '@rocketseat/unform';
import Button from '../../../components/Button';

import Medias from '../../../consts/medias';

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const EmpresaInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 12px;
  width: 100%;
  min-height: 50%;

  @media ${Medias.sm} {
    padding: 0;
  }
`;

export const StyledForm = styled(Form)`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .field {
    margin: 15px 0;
  }

  @media ${Medias.sm} {
    min-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  height: 50px;
  width: 100%;
  font-size: 18px;

  @media ${Medias.sm} {
    height: 36px;
    margin: 10px 0;
  }
`;
