import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  count: 0,
  pedidos: [],
  loading: false,
};

export default function pedidos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_PEDIDOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_PEDIDOS_SUCCESS: {
        draft.pedidos = action.payload.pedidos;
        draft.count = action.payload.count;
        draft.loading = false;
        break;
      }
      case Types.GET_PEDIDOS_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
