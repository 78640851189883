import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';

import ContentPage from '../../components/ContentPage';
import Empresa from './Empresa';
import Usuarios from './Usuarios';
import FormEditUsuarios from './Usuarios/FormEdit';
import DatasDisponiveis from './DatasDisponiveis';
import FormEditDatasDisponiveis from './DatasDisponiveis/FormEdit';

import Medias from '../../consts/medias';
import { empresa as type_empresa } from '../../consts/types';

import {
  ContentContainer,
  StyledTabs,
  StyledTab,
  Bar,
  StyledButton,
  StyledAddCircleOutlineIcon,
  StyledFab,
  StyledAddIcon,
} from './styles';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

// ----------------------------------

function FabAddButton(props) {
  return (
    <StyledFab {...props}>
      <StyledAddIcon />
    </StyledFab>
  );
}

function AddButton(props) {
  return (
    <StyledButton
      variant="contained"
      type="button"
      startIcon={<StyledAddCircleOutlineIcon />}
      {...props}
    >
      Adicionar
    </StyledButton>
  );
}

// ----------------------------------

export default function AdminPage() {
  const sm = useMediaQuery(Medias.sm);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [selectedDataDisponivel, setSelectedDataDisponivel] = useState(null);

  const empresa = useSelector((state) => state.admin.empresa.empresa);

  const [tabIndex, setTabIndex] = useState(0);

  function renderContent() {
    switch (tabIndex) {
      case 0:
        return <Empresa />;
      case 1:
        return <Usuarios />;
      case 2:
        return empresa && empresa.tipo === type_empresa.CLIENTE ? (
          <DatasDisponiveis />
        ) : null;
      default:
        return <></>;
    }
  }

  function renderControls() {
    switch (tabIndex) {
      case 1:
        return !sm ? (
          <AddButton onClick={() => setSelectedUsuario({})} />
        ) : null;
      case 2:
        return !sm ? (
          <AddButton onClick={() => setSelectedDataDisponivel({})} />
        ) : null;
      default:
        return <></>;
    }
  }

  return (
    <>
      <ContentPage
        controls={renderControls()}
        headerComponent={
          <Bar>
            <StyledTabs
              value={tabIndex}
              onChange={(e, newTabIndex) => setTabIndex(newTabIndex)}
              variant="scrollable"
              scrollButtons="auto"
            >
              <StyledTab label="Empresa" {...a11yProps(0)} />
              <StyledTab label="Usuários" {...a11yProps(1)} />
              {empresa && empresa.tipo === type_empresa.CLIENTE && (
                <StyledTab label="Datas Disponíveis" {...a11yProps(2)} />
              )}
            </StyledTabs>
          </Bar>
        }
        content={<ContentContainer>{renderContent()}</ContentContainer>}
      />
      {(tabIndex === 1 || tabIndex === 2) && sm && (
        <FabAddButton
          onClick={() =>
            tabIndex === 1
              ? setSelectedUsuario({})
              : setSelectedDataDisponivel({})
          }
        />
      )}
      {selectedUsuario && (
        <FormEditUsuarios
          usuario={selectedUsuario}
          onClose={() => setSelectedUsuario(null)}
        />
      )}
      {selectedDataDisponivel && (
        <FormEditDatasDisponiveis
          data={selectedDataDisponivel}
          onClose={() => setSelectedDataDisponivel(null)}
        />
      )}
    </>
  );
}
