import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MTableBodyRow } from 'material-table';

import TextField from '../../../../components/TextField';
import Table from '../../../../components/Table';
import history from '../../../../services/history';
import formatter, { toPalletECaixa } from '../../../../services/formatter';

import ArrowIcon from '../../../../assets/images/arrow.svg';

import {
  deleteColetaRequest,
  updItemColetaRequest,
  delItemColetaRequest,
  showStatusColeta,
} from '../../../../store/modules/transportadora/coletas/actions';

import ColetaChip from '../../../../components/ColetaChip';
import ContentPage from '../../../../components/ContentPage';
import SocketsCargaItem from '../../../../components/SocketsCargaItem';
import StatusColeta from '../../../../components/StatusColeta';
import ComboboxColetas from '../../../../components/Combobox/Coletas';

import {
  Header,
  TableContainer,
  ButtonContainer,
  Progress,
  StyledButton,
  StyledRemoveCircleIcon,
  InfoUser,
} from './styles';

import PATHS from '../../../../consts/paths';

export default function ColetasAndamento() {
  const dispatch = useDispatch();
  const coletaSelecionada = useSelector(
    state => state.transportadora.coletas.selecionada
  );
  const loading = useSelector(state => state.transportadora.coletas.loading);

  const [error, setError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    dispatch(showStatusColeta(true));
  }, [dispatch]);

  function deleteColeta() {
    dispatch(deleteColetaRequest(coletaSelecionada.id));
  }

  const [table, setTable] = useState({});
  useEffect(() => {
    function delItemColeta(coletaId, coletaItemId) {
      dispatch(delItemColetaRequest(coletaId, coletaItemId));
    }

    function updItemColeta(coletaId, coletaItemId, body) {
      dispatch(updItemColetaRequest(coletaId, coletaItemId, body));
    }

    function mostraErroQuantidade(item) {
      setError(false);
      if (item.palletsDisponiveis === 0 && item.caixasDisponiveis === 0) {
        setError(true);
        return {
          error: true,
          helperText: `Indisponível`,
        };
      }
      if (
        item.palletsDisponiveis < item.pallets ||
        item.caixasDisponiveis < item.caixas
      ) {
        setError(true);
        return {
          error: true,
          helperText: `Disponível: ${toPalletECaixa(
            item.palletsDisponiveis,
            item.caixasDisponiveis
          )}`,
        };
      }
      return {};
    }

    function cellStyle(value, rowData) {
      if (!rowData.disponivel) {
        return { opacity: 0.4, fontStyle: 'italic' };
      }
      return null;
    }

    setTable({
      columns: [
        { title: 'PEDIDO', field: 'pedido', cellStyle },
        { title: 'PRODUTO', field: 'produto', cellStyle },
        { title: 'CLIENTE', field: 'cliente', cellStyle },
        { title: 'QUANTIDADE', field: 'quantidade', cellStyle },
        { title: 'EMBALAGEM', field: 'embalagem', cellStyle },
        { title: 'PESO TOTAL', field: 'pesoTotal', cellStyle },
        {
          title: 'ALTERAR PALLETS',
          field: 'pallets',

          render: rowData => (
            <TextField
              value={rowData.pallets}
              type="number"
              inputProps={{
                min: rowData.caixas > 0 ? 0 : 1,
                max: rowData.palletsDisponiveis,
              }}
              onClick={e => e.target.select()}
              onChange={e =>
                updItemColeta(rowData.coletaId, rowData.id, {
                  paletes: parseInt(e.target.value, 10),
                  caixas: rowData.caixas,
                })
              }
              {...mostraErroQuantidade(rowData)}
            />
          ),
        },
      ],
      data: coletaSelecionada
        ? coletaSelecionada.itens.map(item => ({
            coletaId: coletaSelecionada.id,
            id: item.id,
            pedido: `${item.cargaItem.documento.numero}/${item.cargaItem.documento.item}`,
            produto: item.cargaItem.produto,
            cliente: item.cargaItem.clienteDestino.razaoSocial,
            quantidade: formatter(item.quantidade).toM2(),
            embalagem: toPalletECaixa(item.paletes, item.caixas),
            pesoTotal: formatter(item.peso).toKg(),
            palletsDisponiveis:
              item.cargaItem.saldoTransportadora.disponivel.paletes,
            caixasDisponiveis:
              item.cargaItem.saldoTransportadora.disponivel.caixas,
            pallets: item.paletes,
            caixas: item.caixas,
            coletas: item.cargaItem.coletas.filter(
              coleta => coleta.id !== coletaSelecionada.id
            ),
            disponivel:
              item.cargaItem.saldoTransportadora.disponivel.paletes > 0 ||
              item.cargaItem.saldoTransportadora.disponivel.caixas > 0,
          }))
        : [],
      actions: [
        {
          icon: () => <StyledRemoveCircleIcon fontSize="large" />,
          tooltip: 'Remover item da coleta',
          onClick: (event, rowData) =>
            delItemColeta(rowData.coletaId, rowData.id),
        },
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
        paging: true,
      },
    });
  }, [coletaSelecionada, dispatch]);

  useEffect(() => {
    setButtonDisabled(
      error || (coletaSelecionada && coletaSelecionada.peso === 0)
    );
  }, [error, coletaSelecionada]);

  return (
    <>
      <SocketsCargaItem />
      <ContentPage
        headerComponent={
          <Header>
            <button
              type="button"
              onClick={() => history.push(PATHS.transportadora.pedidos)}
            >
              <img src={ArrowIcon} alt="" />
            </button>
            Itens da Coleta
          </Header>
        }
        controls={<ComboboxColetas />}
        content={
          <TableContainer>
            <Table
              isLoading={loading}
              autoSizing={true}
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              components={{
                Row: rowProps => {
                  const { data } = rowProps;
                  return (
                    <>
                      <MTableBodyRow {...rowProps} />
                      {data.coletas.length > 0 && (
                        <InfoUser>
                          <td colSpan="11">
                            {data.coletas.map(coleta => (
                              <ColetaChip
                                key={String(coleta.id)}
                                coletaId={coleta.id}
                                nome={coleta.usuario && coleta.usuario.nome}
                                avatarUrl={
                                  coleta.usuario.avatar &&
                                  coleta.usuario.avatar.url
                                }
                              />
                            ))}
                          </td>
                        </InfoUser>
                      )}
                    </>
                  );
                },
              }}
            />
            {coletaSelecionada && (
              <ButtonContainer>
                <StyledButton
                  disabled={loading}
                  type="button"
                  variant="contained"
                  onClick={deleteColeta}
                >
                  Cancelar Coleta
                  {loading && <Progress size={30} />}
                </StyledButton>
              </ButtonContainer>
            )}
          </TableContainer>
        }
      />

      <StatusColeta
        buttonText="Enviar Coleta"
        buttonDisabled={buttonDisabled}
        handleButtonClick={() =>
          history.push(PATHS.transportadora.coletasFinalizar)
        }
      />
    </>
  );
}
