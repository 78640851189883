import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import formatter from '../../../services/formatter';

import {
  getColetasEmAndamentoRequest,
  setColetaSelecionada,
  createColetaRequest,
  showStatusColeta,
} from '../../../store/modules/transportadora/coletas/actions';

import { StyledComboboxInfo } from './styles';

export default function ComboboxColetas() {
  const dispatch = useDispatch();
  const coletasEmAndamento = useSelector(
    (state) => state.transportadora.coletas.emAndamento
  );
  const coletaSelecionada = useSelector(
    (state) => state.transportadora.coletas.selecionada
  );

  useEffect(() => {
    dispatch(getColetasEmAndamentoRequest());
  }, [dispatch, coletaSelecionada]);

  function handleSelect(coletaId) {
    dispatch(setColetaSelecionada(coletaId));
    dispatch(showStatusColeta(true));
  }

  function handleNovaColeta() {
    dispatch(createColetaRequest());
  }

  function coletaToComboOption(coleta) {
    return {
      text: `Coleta ${coleta.id}`,
      info: formatter(coleta.peso).toKg(),
      value: coleta.id,
      color: coleta.reaberta && 'yellow',
    };
  }

  return (
    <StyledComboboxInfo
      options={coletasEmAndamento.map((coleta) => coletaToComboOption(coleta))}
      handleChange={handleSelect}
      button={{ text: 'Nova Coleta', handleClick: handleNovaColeta }}
      value={coletaSelecionada ? String(coletaSelecionada.id) : undefined}
      showSearch={false}
    />
  );
}
