import React, { useEffect } from 'react'
import formatter from '../../services/formatter';
import ReportHeader from './ReportHeader'
import ReportDetail from './ReportDetail'
import { ReportContainer } from './styles'

export default function Report({ coletaDados, onPrint }) {

  const coleta = coletaDados

  useEffect(() => {

    if (onPrint !== null) {

      const reportWindow = window.open()

      const { motorista, veiculo } = { ...coleta }
      const cliente = coleta.itens[0].cargaItem.cliente
      const coletaObs = coleta.observacao ? coleta.observacao : 'Obs de coleta...'

      const AllCustomersId = coleta.itens.map(item => (
        item.cargaItem.clienteDestino.cnpj
      ))

      const uniqueCustomersId = AllCustomersId.filter((elem, index, self) => {
        return index === self.indexOf(elem)
      })

      const collectionTablesPerCustomer = uniqueCustomersId.map(customerId => {

        const itemsPerCustomer = coleta.itens.filter(item => {
          return item.cargaItem.clienteDestino.cnpj === customerId
        })

        return itemsPerCustomer
      })

      let html = ''
      html += '<html>'
      html += '<head>'
      html += '<title>Coleta: ' + coleta.id + '</title>'
      html += '<meta charset="utf-8"'
      html += '</head>'
      html += '<body>'
      html += '<div>'
      html += '<style>'
      html += ' .row {'
      html += 'display: flex;'
      html += 'justify-content:space-between;'
      html += 'line-height: 14px;}'
      html += '</style>'

      html += '<div style="font: 10px Arial, Helvetica, sans-serif" >'
      html += '<h3 style="text-align: center; font-size: 14px;">Coleta</h3>'
      html += '<div style="margin: 10px 0;">'
      html += '<div class="row">'
      html += '<span><b>' + cliente.razaoSocial + '</b></span>'
      html += '<span><b>Coleta: ' + coleta.id + '</b></span>'
      html += '</div>'
      html += '<div class="row">'
      html += '<span></span>'
      html += '<span><b>Data de emissão: </b>' + coleta.data + '</span>'
      html += '</div>'
      html += '<div class="row" style="margin: 15px 0;" >'
      html += '<span><b>Transportadora: ' + coleta.transportadora.nomeFantasia + '</b></span>'
      html += '<span><b>CNPJ: </b>' + formatter(coleta.transportadora.cnpj).toCNPJ() + '</span>'
      html += '</div>'
      html += '<div class="row">'
      html += '<span><b>Motorista: </b>' + [formatter(motorista.cpf).toCpf(), motorista.nome].join(' ').toUpperCase() + '</span>'
      html += '<span><b>Prev. coleta: </b>' + coleta.dataColeta + '</span>'
      html += '</div>'
      html += '<div class="row">'
      html += '<span><b>Placa: </b>' + formatter(veiculo.placa).toPlaca() + '</span>'
      html += '<span><b>Tara: </b>' + veiculo.tara + '</span>'
      html += '<span><b>ANTT: </b>' + veiculo.antt + '</span>'
      html += '</div>'
      html += '<div class="row" style="font-size: 11.5px; margin-top: 3px;">'
      html += '<span><b>Oberservações: </b>' + coletaObs + '</span>'
      html += '</div>'
      html += '</div>'

      collectionTablesPerCustomer.forEach(table => {

        const targetCustomer = table[0].cargaItem.clienteDestino

        html += '<div>'
        html += '<div>'
        html += '<div style="margin: 5px 0;">'
        html += '<div class="row">'
        html += '<span><b>Cliente: ' + targetCustomer.razaoSocial + '</b></span>'
        html += '<span><b>CNPJ:</b> ' + formatter(targetCustomer.cnpj).toCNPJ() + '</span>'
        html += '</div>'
        html += '<span><b>Entrega:</b> ' + [targetCustomer.cidade, targetCustomer.uf].join(' / ') + '</span>'
        html += '</div>'

        html += '<table style="'
        html += 'width: 100%;'
        html += 'font-size: 10px;'
        html += 'border-collapse: collapse;'
        html += 'border-spacing: 0;'
        html += '">'
        html += '<thead style="'
        html += 'border: solid #000;'
        html += 'border-width: 1px 0;'
        html += 'line-height: 16px;'
        html += '">'
        html += '<tr>'
        html += '<th align="start">Representante</th>'
        html += '<th align="start">Pedido</th>'
        html += '<th align="start">Emissão</th>'
        html += '<th align="start">Nome produto</th>'
        html += '<th align="end">Quantidade</th>'
        html += '<th align="end">Peso / Kg</th>'
        html += '</tr>'
        html += '</thead>'
        html += '<tbody>'

        table.forEach(carga => {

          const { cargaItem, cargaItem: { documento } } = carga
          const representante = cargaItem.representante ? cargaItem.representante : ' - '

          html += '<tr style="border-bottom:1px solid #ddd; line-height:15px;">'
          html += '<td>' + representante + '</td>'
          html += '<td>' + documento.numero + '</td>'
          html += '<td>' + formatter(cargaItem.data).toDayOnly() + '</td>'
          html += '<td>' + cargaItem.produto + '</td>'
          html += '<td align="end">' + formatter(carga.quantidade).toDecimal(2) + '</td>'
          html += '<td align="end">' + formatter(carga.peso).toDecimal(2) + '</td>'
          html += '</tr>'
        })

        html += '</tbody>'
        html += '</table >'
        html += '</div>'

      });

      html += '<div class="row" style="border-top:1px solid #000; margin-top:10px; padding-top:5px; font-weight:600;">'
      html += '<span>Total</span>'
      html += '<span>' + coleta.peso + '</span>'
      html += '</div></div></div></div>'
      html += '</body>'
      html += '</html>'

      reportWindow.document.write(html)
      reportWindow.print()
      reportWindow.close()

    }
  }, [onPrint, coleta])

  return coleta ? (
    <ReportContainer>
      <ReportHeader coletaDados={coleta} />
      <ReportDetail coletaDados={coleta} />
    </ReportContainer>
  ) : false
};
