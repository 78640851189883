import styled from 'styled-components';

import Paper from '../Paper';

import { defaultColors } from '../../styles/colors';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 250px;

  padding: 12px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const Title = styled.p`
  font-size: 20px;
  text-transform: uppercase;
`;

export const Info = styled.p`
  font-size: 18px;
  color: ${defaultColors.color12};
`;
