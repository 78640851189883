import { Types } from './types';

export function getVeiculosRequest() {
  return {
    type: Types.GET_VEICULOS_REQUEST,
  };
}

export function getVeiculosSuccess(veiculos) {
  return {
    type: Types.GET_VEICULOS_SUCCESS,
    payload: { veiculos },
  };
}

export function getVeiculosFailure() {
  return {
    type: Types.GET_VEICULOS_FAILURE,
  };
}

export function createVeiculoRequest(veiculo) {
  return {
    type: Types.CREATE_VEICULO_REQUEST,
    payload: { veiculo },
  };
}

export function createVeiculoSuccess(veiculo) {
  return {
    type: Types.CREATE_VEICULO_SUCCESS,
    payload: { veiculo },
  };
}

export function createVeiculoFailure() {
  return {
    type: Types.CREATE_VEICULO_FAILURE,
  };
}

export function updateVeiculoRequest(veiculo) {
  return {
    type: Types.UPDATE_VEICULO_REQUEST,
    payload: { veiculo },
  };
}

export function updateVeiculoSuccess(veiculo) {
  return {
    type: Types.UPDATE_VEICULO_SUCCESS,
    payload: { veiculo },
  };
}

export function updateVeiculoFailure() {
  return {
    type: Types.UPDATE_VEICULO_FAILURE,
  };
}

export function deleteVeiculoRequest(veiculoId) {
  return {
    type: Types.DELETE_VEICULO_REQUEST,
    payload: { veiculoId },
  };
}

export function deleteVeiculoSuccess(veiculoId) {
  return {
    type: Types.DELETE_VEICULO_SUCCESS,
    payload: { veiculoId },
  };
}

export function deleteVeiculoFailure() {
  return {
    type: Types.DELETE_VEICULO_FAILURE,
  };
}

export function deleteVeiculoCancel() {
  return {
    type: Types.DELETE_VEICULO_CANCEL,
  };
}
