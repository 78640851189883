import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';
import { confirmationDialog } from '../../../../components/ConfirmationDialog';

import {
  getMotoristasSuccess,
  getMotoristasFailure,
  createMotoristaSuccess,
  createMotoristaFailure,
  updateMotoristaSuccess,
  updateMotoristaFailure,
  deleteMotoristaSuccess,
  deleteMotoristaCancel,
  deleteMotoristaFailure,
} from './actions';
import { Types } from './types';

export function* getMotoristas() {
  try {
    const response = yield call(
      api.get,
      ApiEndPoints.transportadora.motoristas
    );

    const motoristas = response.data;

    yield put(getMotoristasSuccess(motoristas));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os motoristas');
    yield put(getMotoristasFailure());
  }
}

export function* createMotorista({ payload }) {
  try {
    const { motorista } = payload;

    const response = yield call(
      api.post,
      ApiEndPoints.transportadora.motoristas,
      motorista
    );

    const newMotorista = response.data;

    yield put(createMotoristaSuccess(newMotorista));
    toast.success('Motorista criado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível criar o motorista');
    yield put(createMotoristaFailure());
  }
}

export function* updateMotorista({ payload }) {
  try {
    const { motorista } = payload;

    const response = yield call(
      api.put,
      `${ApiEndPoints.transportadora.motoristas}/${motorista.id}`,
      motorista
    );

    const updatedMotorista = response.data;

    yield put(updateMotoristaSuccess(updatedMotorista));
    toast.success('Motorista atualizado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar o motorista');
    yield put(updateMotoristaFailure());
  }
}

export function* deleteMotorista({ payload }) {
  try {
    const { motoristaId } = payload;
    const confirm = yield call(
      confirmationDialog.open,
      'Motoristas',
      'Confirma exclusão?'
    );

    if (!confirm) {
      yield put(deleteMotoristaCancel());
      confirmationDialog.close();
      return;
    }

    yield call(
      api.delete,
      `${ApiEndPoints.transportadora.motoristas}/${motoristaId}`
    );

    confirmationDialog.close();
    yield put(deleteMotoristaSuccess(motoristaId));
    toast.success('Motorista excluído com sucesso');
  } catch (err) {
    confirmationDialog.close();
    trataErros(err, 'Não foi possível excluir o motorista');
    yield put(deleteMotoristaFailure());
  }
}

export default all([
  takeLatest(Types.GET_MOTORISTAS_REQUEST, getMotoristas),
  takeLatest(Types.CREATE_MOTORISTA_REQUEST, createMotorista),
  takeLatest(Types.UPDATE_MOTORISTA_REQUEST, updateMotorista),
  takeLatest(Types.DELETE_MOTORISTA_REQUEST, deleteMotorista),
]);
