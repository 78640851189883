import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContentPage from '../../../components/ContentPage';
import Table from '../../../components/Table';
import EditIcon from '../../../components/CustomIcons/EditIcon';

import FormEdit from './FormEdit';

import formatter from '../../../services/formatter';

import Medias from '../../../consts/medias';

import {
  getTiposVeiculosRequest,
  deleteTipoVeiculoRequest,
} from '../../../store/modules/ecosis/tiposVeiculos/actions';

import {
  StyledRemoveCircleIcon,
  TableContainer,
  StyledButton,
  StyledFab,
  StyledAddCircleOutlineIcon,
  StyledAddIcon,
  StyledImage,
} from './styles';

export default function TipoVeiculos() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const tiposVeiculos = useSelector(
    state => state.ecosis.tiposVeiculos.tiposVeiculos
  );
  const loading = useSelector(state => state.ecosis.tiposVeiculos.loading);
  const [selectedTipoVeiculo, setSelectedTipoVeiculo] = useState(null);

  useEffect(() => {
    dispatch(getTiposVeiculosRequest());
  }, [dispatch]);

  const [table, setTable] = useState({});
  useEffect(() => {
    function edita(tipoVeiculo) {
      setSelectedTipoVeiculo(tipoVeiculo);
    }

    function deleta(tipoVeiculoId) {
      dispatch(deleteTipoVeiculoRequest(tipoVeiculoId));
    }

    setTable({
      columns: [
        { title: 'SILHUETA', field: 'silhueta' },
        { title: 'DESCRIÇÃO', field: 'descricao' },
        { title: 'EIXOS', field: 'eixos' },
        { title: 'CLASSE', field: 'classe' },
        { title: 'PBT (Ton)', field: 'formattedPesoBruto' },
        { title: 'CODIGO', field: 'codigo' },
      ],

      data: tiposVeiculos.map(tipoVeiculo => ({
        id: tipoVeiculo.id,
        descricao: tipoVeiculo.descricao,
        eixos: tipoVeiculo.eixos,
        classe: tipoVeiculo.classe,
        formattedPesoBruto: formatter(tipoVeiculo.pesoBruto).toDecimal(1),
        pesoBruto: tipoVeiculo.pesoBruto,
        codigo: tipoVeiculo.codigo,
        silhueta:
          tipoVeiculo.silhueta && tipoVeiculo.silhueta.url.length > 1 ? (
            <StyledImage>
              <img src={tipoVeiculo.silhueta.url} alt="Silhueta" />
            </StyledImage>
          ) : (
            ''
          ),
      })),
      actions: [
        {
          icon: () => <EditIcon fontSize="large" />,
          tooltip: 'Editar',
          onClick: (event, rowData) => edita(rowData),
        },
        {
          icon: () => <StyledRemoveCircleIcon fontSize="large" />,
          tooltip: 'Excluir',
          onClick: (event, rowData) => deleta(rowData.id),
        },
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
      },
    });
  }, [dispatch, tiposVeiculos]);

  return (
    <>
      <ContentPage
        title="Tipos de Veículos"
        controls={
          !sm && (
            <StyledButton
              variant="contained"
              type="button"
              startIcon={<StyledAddCircleOutlineIcon />}
              onClick={() => setSelectedTipoVeiculo({})}
            >
              Adicionar
            </StyledButton>
          )
        }
        content={
          <TableContainer>
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              isLoading={loading}
              autoSizing
            />
          </TableContainer>
        }
      />
      {sm && (
        <StyledFab onClick={() => setSelectedTipoVeiculo({})}>
          <StyledAddIcon />
        </StyledFab>
      )}
      {selectedTipoVeiculo && (
        <FormEdit
          tipoVeiculo={selectedTipoVeiculo}
          onClose={() => setSelectedTipoVeiculo(null)}
        />
      )}
    </>
  );
}
