export const Types = {
  SET_COLETA_SELECIONADA: '@transportadora/coletas/SET_COLETA_SELECIONADA',
  SHOW_STATUS_COLETA: '@transportadora/coletas/SHOW_STATUS_COLETA',

  CREATE_COLETA_REQUEST: '@transportadora/coletas/CREATE_COLETA_REQUEST',
  CREATE_COLETA_SUCCESS: '@transportadora/coletas/CREATE_COLETA_SUCCESS',
  CREATE_COLETA_FAILURE: '@transportadora/coletas/CREATE_COLETA_FAILURE',

  UPDATE_COLETA_REQUEST: '@transportadora/coletas/UPDATE_COLETA_REQUEST',
  UPDATE_COLETA_SUCCESS: '@transportadora/coletas/UPDATE_COLETA_SUCCESS',
  UPDATE_COLETA_FAILURE: '@transportadora/coletas/UPDATE_COLETA_FAILURE',

  DELETE_COLETA_REQUEST: '@transportadora/coletas/DELETE_COLETA_REQUEST',
  DELETE_COLETA_SUCCESS: '@transportadora/coletas/DELETE_COLETA_SUCCESS',
  DELETE_COLETA_FAILURE: '@transportadora/coletas/DELETE_COLETA_FAILURE',

  FINALIZA_COLETA_REQUEST: '@transportadora/coletas/FINALIZA_COLETA_REQUEST',
  FINALIZA_COLETA_SUCCESS: '@transportadora/coletas/FINALIZA_COLETA_SUCCESS',
  FINALIZA_COLETA_FAILURE: '@transportadora/coletas/FINALIZA_COLETA_FAILURE',

  REABRE_COLETA_REQUEST: '@transportadora/coletas/REABRE_COLETA_REQUEST',
  REABRE_COLETA_SUCCESS: '@transportadora/coletas/REABRE_COLETA_SUCCESS',
  REABRE_COLETA_FAILURE: '@transportadora/coletas/REABRE_COLETA_FAILURE',

  ADD_ITEM_COLETA_REQUEST: '@transportadora/coletas/ADD_ITEM_COLETA_REQUEST',
  ADD_ITEM_COLETA_SUCCESS: '@transportadora/coletas/ADD_ITEM_COLETA_SUCCESS',
  ADD_ITEM_COLETA_FAILURE: '@transportadora/coletas/ADD_ITEM_COLETA_FAILURE',

  UPD_ITEM_COLETA_REQUEST: '@transportadora/coletas/UPD_ITEM_COLETA_REQUEST',
  UPD_ITEM_COLETA_SUCCESS: '@transportadora/coletas/UPD_ITEM_COLETA_SUCCESS',
  UPD_ITEM_COLETA_FAILURE: '@transportadora/coletas/UPD_ITEM_COLETA_FAILURE',

  DEL_ITEM_COLETA_REQUEST: '@transportadora/coletas/DEL_ITEM_COLETA_REQUEST',
  DEL_ITEM_COLETA_SUCCESS: '@transportadora/coletas/DEL_ITEM_COLETA_SUCCESS',
  DEL_ITEM_COLETA_FAILURE: '@transportadora/coletas/DEL_ITEM_COLETA_FAILURE',

  GET_COLETAS_EM_ANDAMENTO_REQUEST:
    '@transportadora/coletas/GET_COLETAS_EM_ANDAMENTO_REQUEST',
  GET_COLETAS_EM_ANDAMENTO_SUCCESS:
    '@transportadora/coletas/GET_COLETAS_EM_ANDAMENTO_SUCCESS',
  GET_COLETAS_EM_ANDAMENTO_FAILURE:
    '@transportadora/coletas/GET_COLETAS_EM_ANDAMENTO_FAILURE',

  GET_COLETAS_EMITIDAS_REQUEST:
    '@transportadora/coletas/GET_COLETAS_EMITIDAS_REQUEST',
  GET_COLETAS_EMITIDAS_SUCCESS:
    '@transportadora/coletas/GET_COLETAS_EMITIDAS_SUCCESS',
  GET_COLETAS_EMITIDAS_FAILURE:
    '@transportadora/coletas/GET_COLETAS_EMITIDAS_FAILURE',

  UPDATE_ITEM_COLETA_PEDIDO_SOCKET:
    '@transportadora/coletas/UPDATE_ITEM_COLETA_PEDIDO_SOCKET',
};
