import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormDatePicker from '../../../../components/Unform/FormDatePicker';
import Modal from '../../../../components/ModalEdit';

import { createDataRequest } from '../../../../store/modules/comuns/datasDisponiveis/actions';
import { Container } from './styles';

const schema = Yup.object().shape({
  data: Yup.string().required('Data deve ser informada'),
});

export default function FormEdit({ data, onClose }) {
  const dispatch = useDispatch();

  const [editData, setEditData] = useState(data);
  const [campoData, setCampoData] = useState(new Date());
  // const [isEditing, setIsEditing] = useState(false);

  // useEffect(() => {
  //   setIsEditing(!!(editData && editData.id));
  // }, [editData]);

  function handleCancel() {
    setEditData(null);
    onClose();
  }

  function handleSave(formData) {
    dispatch(createDataRequest({ data: formData.data, descr: formData.data }));
    setEditData(null);
    onClose();
  }

  return (
    <Modal
      open={!!editData}
      title="Nova Data"
      schema={schema}
      fieldsComponent={
        editData && (
          <Container>
            <FormDatePicker
              className="field"
              name="data"
              label="Data"
              value={campoData}
              onChange={setCampoData}
              autoFocus
              autoOk
            />
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  // data: PropTypes.shape({
  // }).isRequired,
  onClose: PropTypes.func.isRequired,
};
