import axios from 'axios';
import Client from './eventEmitter';
import { events } from '../components/SignOutChecker';

function checkTokenExpired(error) {
  const { response } = error;

  if (response.status === 401) {
    Client.emit(events.signout);
  }

  return Promise.reject(error);
}

const api = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.response.use(null, checkTokenExpired);

export default api;
