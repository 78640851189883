import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from '../../styles/colors';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 53px;
  border-style: solid;
  border-width: 0 0 0 5px;
  border-color: ${colors.menu.background};
  padding: 8px 16px 8px 3px;
  color: #a5a4bf;

  ${({ active }) =>
    active
      ? css`
          background: rgba(0, 0, 0, 0.1);
          border-color: ${colors.menu.item.active};
        `
      : null}

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: ${colors.menu.item.active};
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledListItemText = styled(ListItemText)`
  font-size: 15px;
  text-align: left;
  color: ${colors.menu.item.text};
`;

export const StyledIcon = styled(SvgIcon)`
  fill: #a5a4bf;
  width: 20px;
  height: 20px;
`;
