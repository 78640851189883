import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  datas: [],
  loading: false,
};

export default function datas(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_DATAS_REQUEST:
      case Types.CREATE_DATA_REQUEST:
      case Types.DELETE_DATA_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_DATAS_SUCCESS: {
        draft.datas = action.payload.datas;
        draft.loading = false;
        break;
      }

      case Types.CREATE_DATA_SUCCESS: {
        draft.datas.push(action.payload.data);
        draft.loading = false;
        break;
      }

      case Types.DELETE_DATA_SUCCESS: {
        draft.datas = draft.datas.filter(
          d => d.data !== action.payload.data.data
        );
        draft.loading = false;
        break;
      }
      case Types.GET_DATAS_FAILURE:
      case Types.CREATE_DATA_FAILURE:
      case Types.DELETE_DATA_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
