import { empresa as TiposEmpresa } from './types';

const PATHS = {
  root: process.env.PUBLIC_URL,
  get signin() {
    return `${PATHS.root}/login`;
  },
  get signup() {
    return `${PATHS.root}/registro/:hash`;
  },
  get novaSenha() {
    return `${PATHS.root}/novasenha`;
  },
  get resetSenha() {
    return `${PATHS.root}/resetsenha/:hash`;
  },
  transportadora: {
    get home() {
      return `${PATHS.root}/transportadora`;
    },
    get pedidos() {
      return `${PATHS.transportadora.home}/pedidos`;
    },
    get coletasEmitidas() {
      return `${PATHS.transportadora.home}/emitidas`;
    },
    get coletasEmAndamento() {
      return `${PATHS.transportadora.home}/andamento`;
    },
    get motoristas() {
      return `${PATHS.transportadora.home}/motoristas`;
    },
    get veiculos() {
      return `${PATHS.transportadora.home}/veiculos`;
    },
    get coletasFinalizar() {
      return `${PATHS.transportadora.home}/finalizar`;
    },
    get comunicados() {
      return `${PATHS.transportadora.home}/comunicados`;
    },
    get profile() {
      return `${PATHS.transportadora.home}/profile`;
    },
  },
  cliente: {
    get home() {
      return `${PATHS.root}/cliente`;
    },
    get pedidos() {
      return `${PATHS.cliente.home}/pedidos`;
    },
    get coletasEmitidas() {
      return `${PATHS.cliente.home}/emitidas`;
    },
    get transportadoras() {
      return `${PATHS.cliente.home}/transportadoras`;
    },
    get comunicados() {
      return `${PATHS.cliente.home}/comunicados`;
    },
    get profile() {
      return `${PATHS.cliente.home}/profile`;
    },
  },
  ecosis: {
    get home() {
      return `${PATHS.root}/ecosis`;
    },
    get empresas() {
      return `${PATHS.ecosis.home}/empresas`;
    },
    get tiposVeiculos() {
      return `${PATHS.ecosis.home}/tiposveiculos`;
    },
    get usuarios() {
      return `${PATHS.ecosis.home}/usuarios`;
    },
  },
  admin: {
    get home() {
      return `${PATHS.root}/admin`;
    },
    get empresa() {
      return `${PATHS.admin.home}/empresa`;
    },
  },
  comum: {
    get perfil() {
      return `${PATHS.root}/perfil`;
    },
  },
  firstPath(tipoEmpresa) {
    switch (tipoEmpresa) {
      case TiposEmpresa.CLIENTE:
        return PATHS.cliente.pedidos;
      case TiposEmpresa.TRANSPORTADORA:
        return PATHS.transportadora.pedidos;
      case TiposEmpresa.ECOSIS:
        return PATHS.ecosis.empresas;
      default:
        return PATHS.signin;
    }
  },
};

export default PATHS;
