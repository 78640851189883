import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';

import { getPedidosSuccess, getPedidosFailure } from './actions';
import { Types } from './types';

export function* getPedidos() {
  try {

    const responsePedidos = yield call(
      api.get,
      ApiEndPoints.transportadora.cargaItens
    );

    const pedidos = responsePedidos.data;
    const { count } = responsePedidos.headers;

    yield put(getPedidosSuccess(pedidos, Number(count)));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os itens disponíveis');
    yield put(getPedidosFailure());
  }
}

export default all([takeLatest(Types.GET_PEDIDOS_REQUEST, getPedidos)]);
