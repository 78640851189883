import styled from 'styled-components';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'

export const CollectionContainer = styled.div`
  width:100%;
  min-width:450px;
`;
export const StyledTable = styled(Table)`
`;
export const StyledTableHead = styled(TableHead)`
  background-color:#d6d8e2;
`;
export const StyledTableBody = styled(TableBody)`
`;
export const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid #d6d8e2;
`;
export const StyledTableCell = styled(TableCell)`
`;
export const StyledTableHeadCell = styled(TableCell)`
  font-weight:bold;
`;
