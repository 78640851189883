import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import formatter from '../../../services/formatter';

import { getEmpresasRequest } from '../../../store/modules/ecosis/empresas/actions';

import { StyledComboboxInfo } from './styles';

export default function ComboboxEmpresas({ value, ...rest }) {
  const dispatch = useDispatch();

  const { empresas, loading } = useSelector(state => state.ecosis.empresas);
  useEffect(() => {
    dispatch(getEmpresasRequest());
  }, [dispatch]);

  const [loaded, setLoaded] = useState(false);
  const [beforeLoading, setBeforeLoading] = useState(false);
  useEffect(() => {
    if (beforeLoading && !loading) {
      setLoaded(true);
    } else {
      setBeforeLoading(loading);
    }
  }, [beforeLoading, loading]);

  function empresaToComboOption(empresa) {
    return {
      text: `Empresa`,
      info: empresa.nomeFantasia,
      value: empresa.id,
      additionalInfo: [
        { text: 'CNPJ', info: formatter(empresa.cnpj).toCNPJOrCPF() },
        { text: 'Tipo', info: empresa.tipo },
      ],
    };
  }

  const [optionsCombobox, setOptionsCombobox] = useState([]);
  useEffect(() => {
    const options = empresas.map(empresa => empresaToComboOption(empresa));
    setOptionsCombobox(options);
  }, [empresas]);

  return (
    <StyledComboboxInfo
      displayEmpty
      options={[
        { text: 'Empresa', info: 'Nenhuma', value: '' },
        ...optionsCombobox,
      ]}
      value={loaded ? value : ''}
      {...rest}
    />
  );
}

ComboboxEmpresas.propTypes = {
  value: PropTypes.string,
};

ComboboxEmpresas.defaultProps = {
  value: '',
};
