import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import transportadora from './transportadora/reducers';
import cliente from './cliente/reducers';
import ecosis from './ecosis/reducers';
import comuns from './comuns/reducers';
import admin from './admin/reducers';

import { Types as AuthTypes } from './auth/types';

const appReducer = combineReducers({
  auth,
  user,
  transportadora,
  cliente,
  ecosis,
  comuns,
  admin,
});

export default (state, action) => {
  if (action.type === AuthTypes.SIGN_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};
