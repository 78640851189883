import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';

import {
  getEmpresaSuccess,
  getEmpresaFailure,
  updateEmpresaSuccess,
  updateEmpresaFailure,
} from './actions';
import { Types } from './types';

export function* getEmpresa() {
  try {
    const response = yield call(api.get, ApiEndPoints.admin.empresa);

    const empresa = response.data;

    yield put(getEmpresaSuccess(empresa));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar a empresa');
    yield put(getEmpresaFailure());
  }
}

export function* updateEmpresa({ payload }) {
  try {
    const { empresa } = payload;

    const response = yield call(api.put, ApiEndPoints.admin.empresa, empresa);

    const updatedEmpresa = response.data;

    yield put(updateEmpresaSuccess(updatedEmpresa));
    toast.success('Empresa atualizada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar a empresa');
    yield put(updateEmpresaFailure());
  }
}

export default all([
  takeLatest(Types.GET_EMPRESA_REQUEST, getEmpresa),
  takeLatest(Types.UPDATE_EMPRESA_REQUEST, updateEmpresa),
]);
