import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import formatter from '../../../services/formatter';

import { getMotoristasRequest } from '../../../store/modules/transportadora/motoristas/actions';

import { StyledComboboxInfo } from './styles';

export default function ComboboxMotoristas({ value, ...rest }) {
  const dispatch = useDispatch();

  const { motoristas, loading } = useSelector(
    state => state.transportadora.motoristas
  );
  useEffect(() => {
    dispatch(getMotoristasRequest());
  }, [dispatch]);

  const [loaded, setLoaded] = useState(false);
  const [beforeLoading, setBeforeLoading] = useState(false);
  useEffect(() => {
    if (beforeLoading && !loading) {
      setLoaded(true);
    } else {
      setBeforeLoading(loading);
    }
  }, [beforeLoading, loading]);

  function motoristaToComboOption(motorista) {
    return {
      text: `Motorista`,
      info: motorista.nome,
      value: motorista.id,
      additionalInfo: [{ text: 'CPF', info: formatter(motorista.cpf).toCpf() }],
    };
  }

  const [optionsCombobox, setOptionsCombobox] = useState([]);
  useEffect(() => {
    const options = motoristas.map(motorista =>
      motoristaToComboOption(motorista)
    );
    setOptionsCombobox(options);
  }, [motoristas]);

  return (
    <StyledComboboxInfo
      displayEmpty
      options={[
        { text: 'Motorista', info: 'Nenhum', value: '' },
        ...optionsCombobox,
      ]}
      value={loaded ? value : ''}
      {...rest}
    />
  );
}

ComboboxMotoristas.propTypes = {
  value: PropTypes.string,
};

ComboboxMotoristas.defaultProps = {
  value: '',
};
