import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ContentPage from '../../../../components/ContentPage';
import Table from '../../../../components/Table';
import InfoCard from '../../../../components/InfoCard';

import formatter from '../../../../services/formatter';

import { getColetasEmitidasRequest } from '../../../../store/modules/cliente/coletas/actions';

import {
  TableContainer,
  InfoColeta,
  StyledTableItens,
  MotoristaIcon,
  CaminhaoIcon,
  InfoCards,
} from './styles';

export default function ColetasEmitidas() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.cliente.coletas.loading);
  const coletas = useSelector(state => state.cliente.coletas.emitidas);
  const count = useSelector(state => state.cliente.coletas.count);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(getColetasEmitidasRequest(page + 1, pageSize));
  }, [page, pageSize, dispatch]);

  const handleChangePage = newPage => {
    setPage(newPage);
  };

  const handleChangePageSize = newPageSize => {
    setPage(0);
    setPageSize(newPageSize);
  };

  const [columns, setColumns] = useState([
    { title: 'TRANSPORTADORA', field: 'transportadora' },
    { title: 'COLETA', field: 'coleta' },
    { title: 'FINALIZADA', field: 'finalizada' },
    { title: 'PREVISÂO COLETA', field: 'dataPrevisaoColeta' },
    { title: 'PREVISÂO ENTREGA', field: 'dataPrevisaoEntrega' },
    { title: 'PESO', field: 'peso' },
    { title: 'OBSERVAÇÃO', field: 'observacao' },
  ]);
  const handleChangeGroup = groupColumns => {
    const oldColumns = columns.map(c => ({ title: c.title, field: c.field }));
    const newColumns = [...oldColumns];

    groupColumns.forEach((groupColumn, index) => {
      const column = newColumns.find(c => c.field === groupColumn.field);
      if (column) column.defaultGroupOrder = index;
    });

    if (JSON.stringify(oldColumns) !== JSON.stringify(newColumns))
      setColumns(newColumns);
  };

  const [table, setTable] = useState();
  useEffect(() => {
    setTable({
      columns,
      data: coletas.map(item => ({
        id: item.id,
        transportadora: item.transportadora.nomeFantasia,
        coleta: item.id,
        finalizada: formatter(item.dataFinalizada).toDayOnly(),
        dataPrevisaoColeta: formatter(item.dataPrevisaoColeta).toDayOnly(),
        dataPrevisaoEntrega: formatter(item.dataPrevisaoEntrega).toDayOnly(),
        peso: formatter(
          item.itens.reduce((sum, coletaItem) => sum + coletaItem.peso, 0)
        ).toKg(),
        motorista: item.motorista,
        veiculo: item.veiculo,
        observacao: item.observacao,
        itens: item.itens,
      })),
      options: {
        toolbar: false,
        grouping: true,
        pageSizeOptions: [10, 20, 50],
        pageSize,
      },
    });
  }, [columns, pageSize, coletas, dispatch]);

  return (
    <ContentPage
      title="Coletas Emitidas"
      content={
        <TableContainer>
          {table && (
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              isLoading={loading}
              onChangeGroup={handleChangeGroup}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangePageSize}
              page={page}
              totalCount={count}
              detailPanel={({ itens, motorista, veiculo }) => (
                <InfoColeta>
                  <InfoCards>
                    <InfoCard
                      icon={() => <MotoristaIcon />}
                      title={motorista && motorista.nome}
                      info={motorista && formatter(motorista.cpf).toCpf()}
                    />
                    <InfoCard
                      icon={() => <CaminhaoIcon />}
                      title={veiculo && veiculo.tipo && veiculo.tipo.descricao}
                      info={veiculo && formatter(veiculo.placa).toPlaca()}
                    />
                  </InfoCards>
                  <StyledTableItens itens={itens} />
                </InfoColeta>
              )}
            />
          )}
        </TableContainer>
      }
    />
  );
}
