import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';
import { confirmationDialog } from '../../../../components/ConfirmationDialog';

import {
  getEmpresasSuccess,
  getEmpresasFailure,
  createEmpresaSuccess,
  createEmpresaFailure,
  updateEmpresaSuccess,
  updateEmpresaFailure,
  ativaEmpresaSuccess,
  ativaEmpresaFailure,
  desativaEmpresaSuccess,
  desativaEmpresaFailure,
  deleteEmpresaSuccess,
  deleteEmpresaFailure,
  deleteEmpresaCancel,
} from './actions';
import { Types } from './types';

export function* getEmpresas() {
  try {
    const response = yield call(api.get, ApiEndPoints.ecosis.empresas);

    const empresas = response.data;

    yield put(getEmpresasSuccess(empresas));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar as empresas');
    yield put(getEmpresasFailure());
  }
}

export function* createEmpresa({ payload }) {
  try {
    const { empresa } = payload;

    const response = yield call(
      api.post,
      ApiEndPoints.ecosis.empresas,
      empresa
    );

    const newEmpresa = response.data;

    yield put(createEmpresaSuccess(newEmpresa));
    toast.success('Empresa criada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível criar a empresa');
    yield put(createEmpresaFailure());
  }
}

export function* updateEmpresa({ payload }) {
  try {
    const { empresa } = payload;

    const response = yield call(
      api.put,
      `${ApiEndPoints.ecosis.empresas}/${empresa.id}`,
      empresa
    );

    const updatedEmpresa = response.data;

    yield put(updateEmpresaSuccess(updatedEmpresa));
    toast.success('Empresa atualizada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar a empresa');
    yield put(updateEmpresaFailure());
  }
}

export function* ativaEmpresa({ payload }) {
  try {
    const { empresaId } = payload;

    const response = yield call(
      api.post,
      `${ApiEndPoints.ecosis.empresas}/${empresaId}/ativar`
    );

    const updatedEmpresa = response.data;

    yield put(ativaEmpresaSuccess(updatedEmpresa.id));
    toast.success('Empresa ativada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível ativar a empresa');
    yield put(ativaEmpresaFailure());
  }
}

export function* desativaEmpresa({ payload }) {
  try {
    const { empresaId } = payload;

    const response = yield call(
      api.post,
      `${ApiEndPoints.ecosis.empresas}/${empresaId}/desativar`
    );

    const updatedEmpresa = response.data;

    yield put(desativaEmpresaSuccess(updatedEmpresa.id));
    toast.success('Empresa desativada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível desativar a empresa');
    yield put(desativaEmpresaFailure());
  }
}

export function* deleteEmpresa({ payload }) {
  try {
    const { empresaId } = payload;
    const confirm = yield call(
      confirmationDialog.open,
      'Empresas',
      'Confirma exclusão?'
    );

    if (!confirm) {
      yield put(deleteEmpresaCancel());
      confirmationDialog.close();
      return;
    }

    yield call(api.delete, `${ApiEndPoints.ecosis.empresas}/${empresaId}`);

    confirmationDialog.close();
    yield put(deleteEmpresaSuccess(empresaId));
    toast.success('Empresa excluída com sucesso');
  } catch (err) {
    confirmationDialog.close();
    trataErros(err, 'Não foi possível excluir a empresa');
    yield put(deleteEmpresaFailure());
  }
}

export default all([
  takeLatest(Types.GET_EMPRESAS_REQUEST, getEmpresas),
  takeLatest(Types.CREATE_EMPRESA_REQUEST, createEmpresa),
  takeLatest(Types.UPDATE_EMPRESA_REQUEST, updateEmpresa),
  takeLatest(Types.ATIVA_EMPRESA_REQUEST, ativaEmpresa),
  takeLatest(Types.DESATIVA_EMPRESA_REQUEST, desativaEmpresa),
  takeLatest(Types.DELETE_EMPRESA_REQUEST, deleteEmpresa),
]);
