export const Types = {
  GET_EMPRESAS_REQUEST: '@ecosis/empresas/GET_EMPRESAS_REQUEST',
  GET_EMPRESAS_SUCCESS: '@ecosis/empresas/GET_EMPRESAS_SUCCESS',
  GET_EMPRESAS_FAILURE: '@ecosis/empresas/GET_EMPRESAS_FAILURE',

  CREATE_EMPRESA_REQUEST: '@ecosis/empresas/CREATE_EMPRESA_REQUEST',
  CREATE_EMPRESA_SUCCESS: '@ecosis/empresas/CREATE_EMPRESA_SUCCESS',
  CREATE_EMPRESA_FAILURE: '@ecosis/empresas/CREATE_EMPRESA_FAILURE',

  UPDATE_EMPRESA_REQUEST: '@ecosis/empresas/UPDATE_EMPRESA_REQUEST',
  UPDATE_EMPRESA_SUCCESS: '@ecosis/empresas/UPDATE_EMPRESA_SUCCESS',
  UPDATE_EMPRESA_FAILURE: '@ecosis/empresas/UPDATE_EMPRESA_FAILURE',

  ATIVA_EMPRESA_REQUEST: '@ecosis/empresas/ATIVA_EMPRESA_REQUEST',
  ATIVA_EMPRESA_SUCCESS: '@ecosis/empresas/ATIVA_EMPRESA_SUCCESS',
  ATIVA_EMPRESA_FAILURE: '@ecosis/empresas/ATIVA_EMPRESA_FAILURE',

  DESATIVA_EMPRESA_REQUEST: '@ecosis/empresas/DESATIVA_EMPRESA_REQUEST',
  DESATIVA_EMPRESA_SUCCESS: '@ecosis/empresas/DESATIVA_EMPRESA_SUCCESS',
  DESATIVA_EMPRESA_FAILURE: '@ecosis/empresas/DESATIVA_EMPRESA_FAILURE',

  DELETE_EMPRESA_REQUEST: '@ecosis/empresas/DELETE_EMPRESA_REQUEST',
  DELETE_EMPRESA_SUCCESS: '@ecosis/empresas/DELETE_EMPRESA_SUCCESS',
  DELETE_EMPRESA_FAILURE: '@ecosis/empresas/DELETE_EMPRESA_FAILURE',
  DELETE_EMPRESA_CANCEL: '@ecosis/empresas/DELETE_EMPRESA_CANCEL',
};
