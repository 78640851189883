import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { colors } from '../../styles/colors';

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    font-size: 15px;
    &::placeholder {
      color: ${colors.field.placeholder};
    }

    &:before {
      border-bottom-color: ${colors.field.border};
    }

    &:after {
      border-bottom-color: ${colors.field.active};
    }

    &.Mui-error:after {
      border-bottom-color: ${colors.form.error};
    }

    &:hover {
      &:before {
        border-bottom-color: ${colors.field.hover};
      }
    }
  }

  label {
    color: ${colors.field.placeholder};
    &.Mui-focused {
      color: ${colors.field.label.active};
    }
  }

  p.Mui-error {
    font-weight: bold;
  }
`;
