export const Types = {
  GET_USUARIOS_REQUEST: '@ecosis/usuarios/GET_USUARIOS_REQUEST',
  GET_USUARIOS_SUCCESS: '@ecosis/usuarios/GET_USUARIOS_SUCCESS',
  GET_USUARIOS_FAILURE: '@ecosis/usuarios/GET_USUARIOS_FAILURE',

  CREATE_USUARIO_REQUEST: '@ecosis/usuarios/CREATE_USUARIO_REQUEST',
  CREATE_USUARIO_SUCCESS: '@ecosis/usuarios/CREATE_USUARIO_SUCCESS',
  CREATE_USUARIO_FAILURE: '@ecosis/usuarios/CREATE_USUARIO_FAILURE',

  UPDATE_USUARIO_REQUEST: '@ecosis/usuarios/UPDATE_USUARIO_REQUEST',
  UPDATE_USUARIO_SUCCESS: '@ecosis/usuarios/UPDATE_USUARIO_SUCCESS',
  UPDATE_USUARIO_FAILURE: '@ecosis/usuarios/UPDATE_USUARIO_FAILURE',

  ATIVA_USUARIO_REQUEST: '@ecosis/usuarios/ATIVA_USUARIO_REQUEST',
  ATIVA_USUARIO_SUCCESS: '@ecosis/usuarios/ATIVA_USUARIO_SUCCESS',
  ATIVA_USUARIO_FAILURE: '@ecosis/usuarios/ATIVA_USUARIO_FAILURE',

  DESATIVA_USUARIO_REQUEST: '@ecosis/usuarios/DESATIVA_USUARIO_REQUEST',
  DESATIVA_USUARIO_SUCCESS: '@ecosis/usuarios/DESATIVA_USUARIO_SUCCESS',
  DESATIVA_USUARIO_FAILURE: '@ecosis/usuarios/DESATIVA_USUARIO_FAILURE',

  DELETE_USUARIO_REQUEST: '@ecosis/usuarios/DELETE_USUARIO_REQUEST',
  DELETE_USUARIO_SUCCESS: '@ecosis/usuarios/DELETE_USUARIO_SUCCESS',
  DELETE_USUARIO_FAILURE: '@ecosis/usuarios/DELETE_USUARIO_FAILURE',
  DELETE_USUARIO_CANCEL: '@ecosis/usuarios/DELETE_USUARIO_CANCEL',
};
