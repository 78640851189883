import React from 'react';

import BusinessIcon from '@material-ui/icons/Business';

import MenuItem from '../../MenuItem';
import PATHS from '../../../consts/paths';

import { StyledDivider } from '../styles';

import { ReactComponent as IconPedidos } from '../../../assets/images/menu/chart-bar-32.svg';

export default function ClienteMenuItems() {
  return (
    <div>
      <MenuItem
        Icon={IconPedidos}
        text="Pedidos"
        path={PATHS.cliente.pedidos}
      />
      <MenuItem
        Icon={BusinessIcon}
        text="Transportadoras"
        path={PATHS.cliente.transportadoras}
      />
      <StyledDivider />
    </div>
  );
}
