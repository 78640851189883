import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  empresa: null,
  loading: false,
};

export default function empresa(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_EMPRESA_REQUEST:
      case Types.UPDATE_EMPRESA_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_EMPRESA_SUCCESS: {
        draft.empresa = action.payload.empresa;
        draft.loading = false;
        break;
      }

      case Types.UPDATE_EMPRESA_SUCCESS: {
        draft.empresa = action.payload.empresa;
        draft.loading = false;
        break;
      }
      case Types.GET_EMPRESA_FAILURE:
      case Types.UPDATE_EMPRESA_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
