import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  showStatusColeta: false,
  selecionada: null,
  emAndamento: [],
  emitidas: [],
  loading: false,
  count: 0,
};

function updatePedido(coleta, newPedido) {
  if (!(coleta && coleta.itens)) return;

  coleta.itens.forEach(item => {
    if (item.cargaItem.id === newPedido.id) {
      item.cargaItem = newPedido;
    }
  });
}

export default function coletas(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_COLETA_SELECIONADA: {
        draft.selecionada = draft.emAndamento.find(
          coleta => coleta.id === action.payload.coletaId
        );
        break;
      }
      case Types.SHOW_STATUS_COLETA: {
        draft.showStatusColeta = action.payload.show;
        break;
      }
      case Types.GET_COLETAS_EM_ANDAMENTO_REQUEST:
      case Types.GET_COLETAS_EMITIDAS_REQUEST:
      case Types.CREATE_COLETA_REQUEST:
      case Types.UPDATE_COLETA_REQUEST:
      case Types.DELETE_COLETA_REQUEST:
      case Types.FINALIZA_COLETA_REQUEST:
      case Types.REABRE_COLETA_REQUEST:
      case Types.ADD_ITEM_COLETA_REQUEST:
      case Types.UPD_ITEM_COLETA_REQUEST:
      case Types.DEL_ITEM_COLETA_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_COLETAS_EM_ANDAMENTO_SUCCESS: {
        draft.selecionada = state.selecionada || action.payload.coletas[0];
        draft.emAndamento = action.payload.coletas;
        draft.loading = false;
        break;
      }
      case Types.GET_COLETAS_EMITIDAS_SUCCESS: {
        draft.emitidas = action.payload.coletas;
        draft.count = action.payload.count;
        draft.loading = false;
        break;
      }
      case Types.CREATE_COLETA_SUCCESS: {
        draft.selecionada = action.payload.newColeta;
        draft.selecionada.itens = [];
        draft.emAndamento = action.payload.coletas;
        draft.loading = false;
        break;
      }
      case Types.UPDATE_COLETA_SUCCESS: {
        draft.ativa = action.payload.coleta;
        draft.loading = false;
        break;
      }
      case Types.DELETE_COLETA_SUCCESS: {
        [draft.selecionada] = action.payload.coletas;
        draft.emAndamento = action.payload.coletas;
        draft.loading = false;
        break;
      }
      case Types.FINALIZA_COLETA_SUCCESS: {
        [draft.selecionada] = action.payload.coletas;
        draft.emAndamento = action.payload.coletas;
        draft.loading = false;
        break;
      }
      case Types.REABRE_COLETA_SUCCESS: {
        draft.emAndamento = action.payload.coletas;
        draft.emitidas = draft.emitidas.filter(
          coleta => coleta.id !== action.payload.coletaId
        );
        draft.loading = false;
        break;
      }
      case Types.ADD_ITEM_COLETA_SUCCESS: {
        const { coleta, ...item } = action.payload.item;

        draft.selecionada = coleta;
        draft.selecionada.itens = [...state.selecionada.itens, item];

        const index = draft.emAndamento.findIndex(
          coletaEmAndamento => coletaEmAndamento.id === coleta.id
        );
        draft.emAndamento[index] = coleta;

        draft.loading = false;
        break;
      }
      case Types.UPD_ITEM_COLETA_SUCCESS: {
        const { coleta, ...item } = action.payload.item;

        draft.selecionada = coleta;
        draft.selecionada.itens = [...state.selecionada.itens];
        const index = draft.selecionada.itens.findIndex(
          coletaItem => coletaItem.id === item.id
        );
        draft.selecionada.itens[index] = item;

        const coletaIndex = draft.emAndamento.findIndex(
          coletaEmAndamento => coletaEmAndamento.id === coleta.id
        );
        draft.emAndamento[coletaIndex] = coleta;

        draft.loading = false;
        break;
      }
      case Types.DEL_ITEM_COLETA_SUCCESS: {
        const { coletaItemId, coleta } = action.payload;

        draft.selecionada = coleta;
        draft.selecionada.itens = [...state.selecionada.itens];
        draft.selecionada.itens = draft.selecionada.itens.filter(
          item => item.id !== coletaItemId
        );

        const coletaIndex = draft.emAndamento.findIndex(
          coletaEmAndamento => coletaEmAndamento.id === coleta.id
        );
        draft.emAndamento[coletaIndex] = coleta;

        draft.loading = false;
        break;
      }
      case Types.UPDATE_ITEM_COLETA_PEDIDO_SOCKET: {
        const { pedido } = action.payload;

        updatePedido(draft.selecionada, pedido);

        draft.emAndamento.forEach(coleta => {
          updatePedido(coleta, pedido);
        });

        break;
      }
      case Types.GET_COLETAS_EM_ANDAMENTO_FAILURE:
      case Types.GET_COLETAS_EMITIDAS_FAILURE:
      case Types.CREATE_COLETA_FAILURE:
      case Types.UPDATE_COLETA_FAILURE:
      case Types.DELETE_COLETA_FAILURE:
      case Types.FINALIZA_COLETA_FAILURE:
      case Types.REABRE_COLETA_FAILURE:
      case Types.ADD_ITEM_COLETA_FAILURE:
      case Types.UPD_ITEM_COLETA_FAILURE:
      case Types.DEL_ITEM_COLETA_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
