import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FilterListIcon from '@material-ui/icons/FilterList';
import ToolTip from '@material-ui/core/Tooltip';

import history from '../../../services/history';
import PATHS from '../../../consts/paths';

import ContentPage from '../../../components/ContentPage';
import SocketsCargaItem from '../../../components/SocketsCargaItem';
import StatusColeta from '../../../components/StatusColeta';
import ComboboxColetas from '../../../components/Combobox/Coletas';
import TablePedidos from '../../../components/Transportadora/TablePedidos';

import { showStatusColeta } from '../../../store/modules/transportadora/coletas/actions';

import { ControlsContainer, FilterButton } from './styles';

export default function PedidosPage() {
  const dispatch = useDispatch();
  const [filtering, setFiltering] = useState(false);

  useEffect(() => {
    let timeout;

    const setStatusColetaHide = () => {
      window.clearTimeout(timeout);

      timeout = setTimeout(() => {
        timeout = null;
        dispatch(showStatusColeta(true));
      }, 1000);
      dispatch(showStatusColeta(false));
    };
    window.addEventListener('scroll', setStatusColetaHide, true);
    return () => {
      window.removeEventListener('scroll', setStatusColetaHide, true);
    };
  }, [dispatch]);

  return (
    <>
      <SocketsCargaItem />
      <ContentPage
        title="Pedidos"
        controls={
          <ControlsContainer>
            <ToolTip title="Filtrar itens">
              <FilterButton
                onClick={() => setFiltering(!filtering)}
                active={filtering ? 1 : 0}
              >
                <FilterListIcon />
              </FilterButton>
            </ToolTip>
            <ComboboxColetas />
          </ControlsContainer>
        }
        content={<TablePedidos filtering={filtering} />}
      />

      <StatusColeta
        buttonText="Revisar Coleta"
        handleButtonClick={() =>
          history.push(PATHS.transportadora.coletasEmAndamento)
        }
      />
    </>
  );
}
