import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';

import { getTiposVeiculosSuccess, getTiposVeiculosFailure } from './actions';
import { Types } from './types';

export function* getTiposVeiculos() {
  try {
    const response = yield call(api.get, ApiEndPoints.comuns.tiposVeiculos);

    const tiposVeiculos = response.data;

    yield put(getTiposVeiculosSuccess(tiposVeiculos));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os tipos de veículos');
    yield put(getTiposVeiculosFailure());
  }
}

export default all([
  takeLatest(Types.GET_TIPOSVEICULOS_REQUEST, getTiposVeiculos),
]);
