import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../../components/Table';

import { Container } from './styles';

import formatter, { toPalletECaixa } from '../../../../../services/formatter';

export default function TableItens({ className, itens }) {
  return (
    <Container className={className}>
      <Table
        columns={[
          { title: 'PEDIDO', field: 'pedido' },
          { title: 'PRODUTO', field: 'produto' },
          { title: 'CLIENTE', field: 'cliente' },
          { title: 'QUANTIDADE', field: 'quantidade' },
          { title: 'EMBALAGEM', field: 'embalagem' },
          { title: 'PESO TOTAL', field: 'pesoTotal' },
        ]}
        data={itens.map(item => ({
          pedido: `${item.cargaItem.documento.numero}/${item.cargaItem.documento.item}`,
          produto: item.cargaItem.produto,
          cliente: item.cargaItem.clienteDestino.razaoSocial,
          quantidade: formatter(item.quantidade).toM2(),
          embalagem: toPalletECaixa(item.paletes, item.caixas),
          pesoTotal: formatter(item.peso).toKg(),
        }))}
        options={{ toolbar: false, paging: false }}
      />
    </Container>
  );
}

TableItens.propTypes = {
  itens: PropTypes.arrayOf(
    PropTypes.shape({
      itemCarga: PropTypes.shape({
        numeroDocumento: PropTypes.string,
        itemDocumento: PropTypes.number,
        produtoDocumento: PropTypes.string,
        razaoSocialCliente: PropTypes.string,
      }),
      quantidade: PropTypes.number,
      embalagem: PropTypes.number,
      pesoTotal: PropTypes.number,
    })
  ).isRequired,
  className: PropTypes.string,
};

TableItens.defaultProps = {
  className: '',
};
