import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContentPage from '../../../components/ContentPage';
import Table from '../../../components/Table';
import EditIcon from '../../../components/CustomIcons/EditIcon';

import FormEdit from './FormEdit';

import Medias from '../../../consts/medias';
import { empresaString as TiposEmpresaString } from '../../../consts/types';

import formatter from '../../../services/formatter';

import {
  getEmpresasRequest,
  deleteEmpresaRequest,
  ativaEmpresaRequest,
  desativaEmpresaRequest,
} from '../../../store/modules/ecosis/empresas/actions';

import {
  StyledRemoveCircleIcon,
  TableContainer,
  StyledButton,
  StyledFab,
  StyledAddCircleOutlineIcon,
  StyledAddIcon,
  StyledCheckIcon,
} from './styles';

export default function Empresas() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const empresas = useSelector(state => state.ecosis.empresas.empresas);
  const loading = useSelector(state => state.ecosis.empresas.loading);
  const [selectedEmpresa, setSelectedEmpresa] = useState(null);

  useEffect(() => {
    dispatch(getEmpresasRequest());
  }, [dispatch]);

  const [table, setTable] = useState({});
  useEffect(() => {
    function edita(empresa) {
      setSelectedEmpresa(empresa);
    }

    function deleta(empresaId) {
      dispatch(deleteEmpresaRequest(empresaId));
    }

    function ativa(empresaId) {
      dispatch(ativaEmpresaRequest(empresaId));
    }

    function desativa(empresaId) {
      dispatch(desativaEmpresaRequest(empresaId));
    }

    setTable({
      columns: [
        { title: 'EMAIL', field: 'email' },
        { title: 'CNPJ/CPF', field: 'formattedCnpjCpf' },
        { title: 'NOME', field: 'nomeFantasia' },
        { title: 'RAZÃO SOCIAL', field: 'razaoSocial' },
        { title: 'TELEFONE', field: 'telefone' },
        { title: 'TIPO', field: 'tipoFormatted' },
      ],
      data: empresas.map(empresa => ({
        id: empresa.id,
        email: empresa.email,
        cnpj: empresa.cnpj,
        formattedCnpjCpf: formatter(empresa.cnpj).toCNPJOrCPF(),
        nomeFantasia: empresa.nomeFantasia,
        razaoSocial: empresa.razaoSocial,
        telefone: formatter(empresa.telefone).toTelefone(),
        tipo: empresa.tipo,
        tipoFormatted: TiposEmpresaString[empresa.tipo],
        ativo: empresa.ativo,
      })),
      actions: [
        rowData => {
          return {
            icon: () => (
              <StyledCheckIcon fontSize="large" ativo={rowData.ativo ? 1 : 0} />
            ),
            tooltip: rowData.ativo ? 'Desativar' : 'Ativar',
            onClick: (event, data) =>
              rowData.ativo ? desativa(data.id) : ativa(data.id),
          };
        },
        {
          icon: () => <EditIcon fontSize="large" />,
          tooltip: 'Editar',
          onClick: (event, rowData) => edita(rowData),
        },
        {
          icon: () => <StyledRemoveCircleIcon fontSize="large" />,
          tooltip: 'Excluir',
          onClick: (event, rowData) => deleta(rowData.id),
        },
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
      },
    });
  }, [dispatch, empresas]);

  return (
    <>
      <ContentPage
        title="Empresas"
        controls={
          !sm && (
            <StyledButton
              variant="contained"
              type="button"
              startIcon={<StyledAddCircleOutlineIcon />}
              onClick={() => setSelectedEmpresa({})}
            >
              Adicionar
            </StyledButton>
          )
        }
        content={
          <TableContainer>
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              isLoading={loading}
              autoSizing
            />
          </TableContainer>
        }
      />
      {sm && (
        <StyledFab onClick={() => setSelectedEmpresa({})}>
          <StyledAddIcon />
        </StyledFab>
      )}
      {selectedEmpresa && (
        <FormEdit
          empresa={selectedEmpresa}
          onClose={() => setSelectedEmpresa(null)}
        />
      )}
    </>
  );
}
