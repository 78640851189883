import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';
import { confirmationDialog } from '../../../../components/ConfirmationDialog';

import {
  getTiposVeiculosSuccess,
  getTiposVeiculosFailure,
  createTipoVeiculoSuccess,
  createTipoVeiculoFailure,
  updateTipoVeiculoSuccess,
  updateTipoVeiculoFailure,
  deleteTipoVeiculoSuccess,
  deleteTipoVeiculoFailure,
  deleteTipoVeiculoCancel,
} from './actions';
import { Types } from './types';

export function* getTiposVeiculos() {
  try {
    const response = yield call(api.get, ApiEndPoints.ecosis.tiposVeiculos);

    const tiposVeiculos = response.data;

    yield put(getTiposVeiculosSuccess(tiposVeiculos));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os tipos de veículos');
    yield put(getTiposVeiculosFailure());
  }
}

export function* createTipoVeiculo({ payload }) {
  try {
    const { tipoVeiculo } = payload;

    const response = yield call(
      api.post,
      ApiEndPoints.ecosis.tiposVeiculos,
      tipoVeiculo
    );

    const newTipoVeiculo = response.data;

    yield put(createTipoVeiculoSuccess(newTipoVeiculo));
    toast.success('Tipo de veículo criado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível criar o tipo de veículo');
    yield put(createTipoVeiculoFailure());
  }
}

export function* updateTipoVeiculo({ payload }) {
  try {
    const { tipoVeiculo } = payload;

    const response = yield call(
      api.put,
      `${ApiEndPoints.ecosis.tiposVeiculos}/${tipoVeiculo.id}`,
      tipoVeiculo
    );

    const updatedTipoVeiculo = response.data;

    yield put(updateTipoVeiculoSuccess(updatedTipoVeiculo));
    toast.success('Tipo de veículo atualizado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar o tipo de veículo');
    yield put(updateTipoVeiculoFailure());
  }
}

export function* deleteTipoVeiculo({ payload }) {
  try {
    const { tipoVeiculoId } = payload;
    const confirm = yield call(
      confirmationDialog.open,
      'Tipos de Veículos',
      'Confirma exclusão?'
    );

    if (!confirm) {
      yield put(deleteTipoVeiculoCancel());
      confirmationDialog.close();
      return;
    }

    yield call(
      api.delete,
      `${ApiEndPoints.ecosis.tiposVeiculos}/${tipoVeiculoId}`
    );

    confirmationDialog.close();
    yield put(deleteTipoVeiculoSuccess(tipoVeiculoId));
    toast.success('Tipo de veículo excluído com sucesso');
  } catch (err) {
    confirmationDialog.close();
    trataErros(err, 'Não foi possível excluir o tipo de veículo');
    yield put(deleteTipoVeiculoFailure());
  }
}

export default all([
  takeLatest(Types.GET_TIPOSVEICULOS_REQUEST, getTiposVeiculos),
  takeLatest(Types.CREATE_TIPOVEICULO_REQUEST, createTipoVeiculo),
  takeLatest(Types.UPDATE_TIPOVEICULO_REQUEST, updateTipoVeiculo),
  takeLatest(Types.DELETE_TIPOVEICULO_REQUEST, deleteTipoVeiculo),
]);
