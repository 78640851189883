import styled from 'styled-components';
import Medias from '../../../consts/medias';

import ComboboxInfo from '../../ComboboxInfo';

export const StyledComboboxInfo = styled(ComboboxInfo)`
  margin-left: 5px;
  width: 230px;

  @media ${Medias.sm} {
    margin-left: 0;
    width: 100%;
  }
`;
