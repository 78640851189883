import styled from 'styled-components';
import { colors } from '../../styles/colors';
import Button from '../Button';
import Medias from '../../consts/medias';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 75px;
  opacity: ${props => (props.show ? 1 : 0)};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  width: 100%;
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 4%);
  background: ${colors.statusColeta.background};
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 102;
  transition: 0.3s;

  @media ${Medias.sm} {
    flex-direction: column;
    height: auto;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px; 

  p {
    font-size: 15px;
    line-height: 25px;
    color: ${colors.statusColeta.info.title};
    white-space: nowrap;
  }

  span {
    font-size: 25px;
    line-height: 32px;
    font-weight: bold;
    white-space: nowrap;
    color: ${props =>
      props.light
        ? colors.statusColeta.info.lightText
        : colors.statusColeta.info.text};
  }

  @media ${Medias.sm} {
    p {
      font-size: 12px;
      line-height: 18px;
    }

    span {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  ${Info} {
    p {
      text-align: right;
    }

    span {
      text-align: right;
    }
  }
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  ${Info} {
    p {
      text-align: left;
    }

    span {
      text-align: left;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;

  @media ${Medias.sm} {
    padding: 0 10px 10px 10px;
  }
`;

export const StyledButton = styled(Button)`
  height: 50px;
  width: 185px;
  font-size: 18px;

  @media ${Medias.sm} {
    height: 100%;
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
`;
