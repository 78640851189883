export const Types = {
  GET_MOTORISTAS_REQUEST: '@transportadora/motoristas/GET_MOTORISTAS_REQUEST',
  GET_MOTORISTAS_SUCCESS: '@transportadora/motoristas/GET_MOTORISTAS_SUCCESS',
  GET_MOTORISTAS_FAILURE: '@transportadora/motoristas/GET_MOTORISTAS_FAILURE',

  CREATE_MOTORISTA_REQUEST:
    '@transportadora/motoristas/CREATE_MOTORISTA_REQUEST',
  CREATE_MOTORISTA_SUCCESS:
    '@transportadora/motoristas/CREATE_MOTORISTA_SUCCESS',
  CREATE_MOTORISTA_FAILURE:
    '@transportadora/motoristas/CREATE_MOTORISTA_FAILURE',

  UPDATE_MOTORISTA_REQUEST:
    '@transportadora/motoristas/UPDATE_MOTORISTA_REQUEST',
  UPDATE_MOTORISTA_SUCCESS:
    '@transportadora/motoristas/UPDATE_MOTORISTA_SUCCESS',
  UPDATE_MOTORISTA_FAILURE:
    '@transportadora/motoristas/UPDATE_MOTORISTA_FAILURE',

  DELETE_MOTORISTA_REQUEST:
    '@transportadora/motoristas/DELETE_MOTORISTA_REQUEST',
  DELETE_MOTORISTA_SUCCESS:
    '@transportadora/motoristas/DELETE_MOTORISTA_SUCCESS',
  DELETE_MOTORISTA_FAILURE:
    '@transportadora/motoristas/DELETE_MOTORISTA_FAILURE',
  DELETE_MOTORISTA_CANCEL: '@transportadora/motoristas/DELETE_MOTORISTA_CANCEL',
};
