import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import formatter from '../../../services/formatter';

import { getTiposVeiculosRequest } from '../../../store/modules/comuns/tiposVeiculos/actions';

import { StyledComboboxInfo } from './styles';

export default function ComboboxTiposVeiculos({ value, ...rest }) {
  const dispatch = useDispatch();

  const { tiposVeiculos, loading } = useSelector(
    state => state.comuns.tiposVeiculos
  );

  const [loaded, setLoaded] = useState(false);
  const [beforeLoading, setBeforeLoading] = useState(false);
  useEffect(() => {
    if (beforeLoading && !loading) {
      setLoaded(true);
    } else {
      setBeforeLoading(loading);
    }
  }, [beforeLoading, loading]);

  useEffect(() => {
    dispatch(getTiposVeiculosRequest());
  }, [dispatch]);

  function tipoVeiculoToComboOption(tipoVeiculo) {
    return {
      text: 'Tipo',
      info: tipoVeiculo.descricao,
      value: tipoVeiculo.id,
      silhueta:
        tipoVeiculo.silhueta && tipoVeiculo.silhueta.url.length > 1
          ? tipoVeiculo.silhueta.url
          : '',
      additionalInfo: [
        {
          text: 'Peso Bruto (Ton)',
          info: formatter(tipoVeiculo.pesoBruto).toDecimal(2),
        },
      ],
    };
  }

  const [optionsCombobox, setOptionsCombobox] = useState([]);
  useEffect(() => {
    const options = tiposVeiculos.map(tipoVeiculo =>
      tipoVeiculoToComboOption(tipoVeiculo)
    );
    setOptionsCombobox(options);
  }, [tiposVeiculos]);

  return (
    loaded && (
      <StyledComboboxInfo
        displayEmpty
        options={[
          { text: 'Tipo', info: 'Nenhum', value: '' },
          ...optionsCombobox,
        ]}
        value={loaded ? value : ''}
        {...rest}
      />
    )
  );
}

ComboboxTiposVeiculos.propTypes = {
  value: PropTypes.string,
};

ComboboxTiposVeiculos.defaultProps = {
  value: '',
};
