import React from 'react';
import BusinessIcon from '@material-ui/icons/Business';
import UsersIcon from '@material-ui/icons/People';
import CommuteIcon from '@material-ui/icons/Commute';

import MenuItem from '../../MenuItem';
import PATHS from '../../../consts/paths';
import { StyledDivider } from '../styles';

export default function EcosisMenuItems() {
  return (
    <div>
      <MenuItem
        Icon={BusinessIcon}
        text="Empresas"
        path={PATHS.ecosis.empresas}
      />
      <MenuItem Icon={UsersIcon} text="Usuários" path={PATHS.ecosis.usuarios} />
      <MenuItem
        Icon={CommuteIcon}
        text="Tipos de Veículos"
        path={PATHS.ecosis.tiposVeiculos}
      />
      <StyledDivider />
    </div>
  );
}
