import { Types } from './types';

export function getTiposVeiculosRequest() {
  return {
    type: Types.GET_TIPOSVEICULOS_REQUEST,
  };
}

export function getTiposVeiculosSuccess(tiposVeiculos) {
  return {
    type: Types.GET_TIPOSVEICULOS_SUCCESS,
    payload: { tiposVeiculos },
  };
}

export function getTiposVeiculosFailure() {
  return {
    type: Types.GET_TIPOSVEICULOS_FAILURE,
  };
}
