import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import io from '../../services/io';
import { SalaColetaItem, SalaCargaItem } from '../../consts/salas';

import { updatePedidoItemColetaFromSocket } from '../../store/modules/transportadora/coletas/actions';
import { updatePedidoFromSocket } from '../../store/modules/transportadora/pedidos/actions';

export default function SocketsCargaitem() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    const { sala, eventos } = SalaColetaItem;
    const socketColetaItem = io(sala, token);

    socketColetaItem.on(eventos.store, coletaItem => {
      const { cargaItem: pedido } = coletaItem;
      dispatch(updatePedidoFromSocket(pedido));
      dispatch(updatePedidoItemColetaFromSocket(pedido));
    });

    socketColetaItem.on(eventos.update, coletaItem => {
      const { cargaItem: pedido } = coletaItem;
      dispatch(updatePedidoFromSocket(pedido));
      dispatch(updatePedidoItemColetaFromSocket(pedido));
    });

    return function disconnect() {
      socketColetaItem.disconnect();
    };
  }, [dispatch, token]);

  useEffect(() => {
    const { sala, eventos } = SalaCargaItem;
    const socketCargaItem = io(sala, token);

    socketCargaItem.on(eventos.update, pedido => {
      dispatch(updatePedidoFromSocket(pedido));
      dispatch(updatePedidoItemColetaFromSocket(pedido));
    });

    return function disconnect() {
      socketCargaItem.disconnect();
    };
  }, [dispatch, token]);

  return null;
}
