import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

import Paper from '../../../../components/Paper';
import Button from '../../../../components/Button';

import { colors } from '../../../../styles/colors';
import Medias from '../../../../consts/medias';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 13px;

  background: ${colors.page.background};
`;

export const Header = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    padding: 6px 6px 6px 0;
    background: none;
    border: 0;
    img {
      transform: rotate(90deg);
    }
  }
`;

export const TableContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  background: ${colors.pedidos.background};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 24px;
`;

export const StyledButton = styled(Button)`
  position: relative;
  height: 50px;
  width: 185px;
  font-size: 18px;

  @media ${Medias.sm} {
    height: 100%;
    width: 100%;
  }
`;

export const Progress = styled(CircularProgress)`
  position: absolute;
  color: ${colors.button.filled.background};
`;

export const StyledRemoveCircleIcon = styled(RemoveCircle)`
  color: red;
`;

export const InfoUser = styled.tr`
  td {
    background-color: ${colors.pedidos.info.background};
    padding: 5px;
  }
`;
