import styled from 'styled-components';
import { Form } from '@rocketseat/unform';
import { colors } from '../../styles/colors';
import Medias from '../../consts/medias';

import Button from '../../components/Button';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Group = styled.div`
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 766px;
  max-width: 1366px;
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 40%;

  img {
    height: 100%;
    width: 100%;
  }
`;

export const SignInContainer = styled.div`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p.subtitle {
    font-size: 18px;
    text-align: center;
    line-height: 13px;
    color: ${colors.text};
    opacity: 0.5;
  }

  p.copyright {
    font-size: 15px;
    text-align: center;
    color: ${colors.copyright};
  }

  @media ${Medias.sm} {
    width: 100%;
  }
`;

export const SignInForm = styled(Form)`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .field {
    margin: 15px 0;
  }

  @media ${Medias.sm} {
    width: 100%;
  }
`;

export const Password = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  a {
    font-size: 15px;
    color: ${colors.field.text};
    text-decoration: none;

    &:hover {
      color: ${colors.link.active};
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  a {
    text-decoration: none;
  }

  @media ${Medias.sm} {
    margin: 10px 0;
    display: inline-block;
  }
`;

export const StyledButton = styled(Button)`
  height: 50px;
  width: 100%;
  font-size: 18px;

  @media ${Medias.sm} {
    height: 36px;
    margin: 10px 0;
  }
`;

export const StyledLogo = styled(Logo)`
  width: 170px;
  height: 170px;
  overflow: visible;
`;
