export const Types = {
  GET_VEICULOS_REQUEST: '@transportadora/veiculos/GET_VEICULOS_REQUEST',
  GET_VEICULOS_SUCCESS: '@transportadora/veiculos/GET_VEICULOS_SUCCESS',
  GET_VEICULOS_FAILURE: '@transportadora/veiculos/GET_VEICULOS_FAILURE',

  CREATE_VEICULO_REQUEST: '@transportadora/veiculos/CREATE_VEICULO_REQUEST',
  CREATE_VEICULO_SUCCESS: '@transportadora/veiculos/CREATE_VEICULO_SUCCESS',
  CREATE_VEICULO_FAILURE: '@transportadora/veiculos/CREATE_VEICULO_FAILURE',

  UPDATE_VEICULO_REQUEST: '@transportadora/veiculos/UPDATE_VEICULO_REQUEST',
  UPDATE_VEICULO_SUCCESS: '@transportadora/veiculos/UPDATE_VEICULO_SUCCESS',
  UPDATE_VEICULO_FAILURE: '@transportadora/veiculos/UPDATE_VEICULO_FAILURE',

  DELETE_VEICULO_REQUEST: '@transportadora/veiculos/DELETE_VEICULO_REQUEST',
  DELETE_VEICULO_SUCCESS: '@transportadora/veiculos/DELETE_VEICULO_SUCCESS',
  DELETE_VEICULO_FAILURE: '@transportadora/veiculos/DELETE_VEICULO_FAILURE',
  DELETE_VEICULO_CANCEL: '@transportadora/veiculos/DELETE_VEICULO_CANCEL',
};
