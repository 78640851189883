import React from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';

import MenuIcon from '@material-ui/icons/Menu';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Medias from '../../consts/medias';

import Paths from '../../consts/paths';

import Avatar from '../../assets/images/avatar.png';

import {
  Container,
  User,

  // StyledNotification,
  Divider,
  MenuButton,
} from './styles';

// import NotificationIcon from '../../assets/images/notification.svg';

import { signOut } from '../../store/modules/auth/actions';

export default function Header({ onMenuButtonClick }) {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();

  const profile = useSelector(state => state.user.profile);

  function sair() {
    dispatch(signOut());
  }

  return (
    <Container>
      {/* <StyledNotification icon={NotificationIcon} active /> */}

      <Divider />

      <User>
        <Link to={Paths.comum.perfil}>
          <div>
            <strong>{profile && profile.nome}</strong>

            <span>{profile && profile.empresa.nomeFantasia}</span>
          </div>

          <img
            src={(profile && profile.avatar && profile.avatar.url) || Avatar}
            alt="Avatar"
          />
        </Link>

        <button type="button" onClick={sair}>
          <p>Sair</p>
        </button>
      </User>

      {sm && (
        <MenuButton onClick={onMenuButtonClick}>
          <MenuIcon />
        </MenuButton>
      )}
    </Container>
  );
}

Header.propTypes = {
  onMenuButtonClick: PropTypes.func,
};

Header.defaultProps = {
  onMenuButtonClick: undefined,
};
