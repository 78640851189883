import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledLink,
  StyledListItem,
  StyledListItemIcon,
  StyledIcon,
  StyledListItemText,
} from './styles';

export default function MenuItem({ Icon, text, path }) {
  return (
    <StyledLink to={path}>
      <StyledListItem
        button
        key={text}
        active={path === window.location.pathname ? 1 : 0}
      >
        <StyledListItemIcon>
          <StyledIcon
            component={({ viewBox, ...svgProps }) => <Icon {...svgProps} />}
          />
        </StyledListItemIcon>
        <StyledListItemText primary={text} />
      </StyledListItem>
    </StyledLink>
  );
}

MenuItem.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
