import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import pedidosTransportadora from './transportadora/pedidos/sagas';
import coletasTransportadora from './transportadora/coletas/sagas';
import motoristasTransportadora from './transportadora/motoristas/sagas';
import veiculosTransportadora from './transportadora/veiculos/sagas';
import pedidosCliente from './cliente/pedidos/sagas';
import coletasCliente from './cliente/coletas/sagas';
import transportadorasCliente from './cliente/transportadoras/sagas';
import empresasEcosis from './ecosis/empresas/sagas';
import usuariosEcosis from './ecosis/usuarios/sagas';
import tiposVeiculosEcosis from './ecosis/tiposVeiculos/sagas';
import tiposVeiculosComuns from './comuns/tiposVeiculos/sagas';
import datasDisponiveis from './comuns/datasDisponiveis/sagas';

import empresaAdmin from './admin/empresa/sagas';
import usuariosAdmin from './admin/usuarios/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    pedidosTransportadora,
    coletasTransportadora,
    motoristasTransportadora,
    veiculosTransportadora,
    pedidosCliente,
    coletasCliente,
    transportadorasCliente,
    empresasEcosis,
    usuariosEcosis,
    tiposVeiculosEcosis,
    tiposVeiculosComuns,
    datasDisponiveis,
    empresaAdmin,
    usuariosAdmin,
  ]);
}
