const toDigitsOnly = str => str.replace(/[^\d]+/g, '');

const sizeOfStringIs = str => size => str.length === size;

const getNumeral = str => lastCharIndex =>
  str.substr(0, lastCharIndex).split('');

const getTwoLastDigits = str =>
  str
    .split('')
    .slice(-2)
    .join('');

const isRepeatingChars = str => str.split('').every(elem => elem === str[0]);

const mod11 = num => num % 11;

const NOT = x => !x;

const isEqual = a => b => b === a;

const mergeDigits = array => array.join('');

const getSumOfProducts = (list, initialMultiplier, toSumOfProducts) =>
  list.reduce(toSumOfProducts(initialMultiplier), 0);

const getDigit = num => (num > 1 ? 11 - num : 0);

export function validaCnpj(cnpjToCheck) {
  const sanitizedCnpj = toDigitsOnly(cnpjToCheck);

  if (NOT(sizeOfStringIs(sanitizedCnpj)(14))) return false;

  const isRepeatingNumbersCpf = isRepeatingChars;

  if (isRepeatingNumbersCpf(sanitizedCnpj)) return false;

  const getCnpjNumeral = cnpj => getNumeral(cnpj)(12);

  const toSumOfProducts = multiplier => (result, num) => {
    const sum = result + num * multiplier;
    multiplier -= 1;
    if (multiplier < 2) multiplier = 9;
    return sum;
  };

  const getValidationDigit = multiplier => cnpj =>
    getDigit(mod11(getSumOfProducts(cnpj, multiplier, toSumOfProducts)));

  const isValidCNPJ = cnpj => {
    const CNPJ = getCnpjNumeral(cnpj);
    const firstDigit = getValidationDigit(5)(CNPJ);
    const secondDigit = getValidationDigit(6)(CNPJ.concat(firstDigit));

    return isEqual(getTwoLastDigits(cnpj))(
      mergeDigits([firstDigit, secondDigit])
    );
  };

  return isValidCNPJ(sanitizedCnpj);
}

export function validaCpf(cpfToCheck) {
  const sanitizedCpf = toDigitsOnly(cpfToCheck);

  if (NOT(sizeOfStringIs(sanitizedCpf)(11))) return false;

  const isRepeatingNumbersCpf = isRepeatingChars;

  if (isRepeatingNumbersCpf(sanitizedCpf)) return false;

  const getCpfNumeral = cpf => getNumeral(cpf)(9);

  const toSumOfProducts = multiplier => (result, num) => {
    const sum = result + num * multiplier;
    multiplier -= 1;
    return sum;
  };

  const getValidationDigit = multiplier => cpf =>
    getDigit(mod11(getSumOfProducts(cpf, multiplier, toSumOfProducts)));

  const isValidCPF = cpf => {
    const CPF = getCpfNumeral(cpf);
    const firstDigit = getValidationDigit(10)(CPF);
    const secondDigit = getValidationDigit(11)(CPF.concat(firstDigit));

    return isEqual(getTwoLastDigits(cpf))(
      mergeDigits([firstDigit, secondDigit])
    );
  };

  return isValidCPF(sanitizedCpf);
}
