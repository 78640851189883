import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { persistor } from '../../store';

import {
  Container,
  ImageContainer,
  SignInContainer,
  SignInForm,
  Group,
  Password,
  Buttons,
  StyledButton,
  StyledLogo,
} from './styles';

import FormCheckbox from '../../components/Unform/FormCheckbox';
import FormTextField from '../../components/Unform/FormTextField';

import Image from '../../assets/images/login-truck.png';

import Medias from '../../consts/medias';
import Paths from '../../consts/paths';

import { signInRequest } from '../../store/modules/auth/actions';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Email deve ser informado'),
  senha: Yup.string().required('Senha deve ser informada'),
  lembra: Yup.bool(),
});

export default function SignInPage() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = data => {
    persistor.persist();
    if (!data.lembra) persistor.pause();
    if (data) dispatch(signInRequest(data.email, data.senha, data.lembra));
  };

  return (
    <Container>
      <Group>
        {!sm && (
          <ImageContainer>
            <img src={Image} alt="Imagem de Caminhão" />
          </ImageContainer>
        )}
        <SignInContainer>
          <StyledLogo />
          {!sm && (
            <p className="subtitle">
              Bem vindo novamente. Preencha os dados abaixo para logar.
            </p>
          )}
          <SignInForm schema={schema} onSubmit={handleSubmit}>
            <FormTextField
              className="field"
              name="email"
              type="email"
              placeholder="Usuário"
            />
            <FormTextField
              className="field"
              name="senha"
              type="password"
              placeholder="Senha"
            />
            <Password>
              <FormCheckbox
                name="lembra"
                label="Lembrar senha"
                defaultChecked
              />
              <Link to={Paths.novaSenha}>Esqueci minha senha</Link>
            </Password>
            <Buttons>
              <StyledButton variant="contained" type="submit">
                {loading ? 'Carregando...' : 'Entrar'}
              </StyledButton>
            </Buttons>
          </SignInForm>
          <p className="copyright">
            Copyright © 2019 Ecosis. Todos os direitos reservados.
          </p>
        </SignInContainer>
      </Group>
    </Container>
  );
}
