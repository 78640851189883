/* eslint-disable react/prop-types */
import React from 'react';
import Modal from '../../../../components/ModalInfo';
import Report from '../../../../components/Report'

export default function ColetaResumo({ coleta, onClose }) {

  const [printReport, setPrintReport] = React.useState(null)

  function handleCancel() {
    onClose();
  }

  const onPrint = () => {
    setPrintReport(print => !print)
  }

  return (
    <Modal
      onPrint={onPrint}
      open={!!coleta}
      title="Coleta"
      fieldsComponent={
        coleta && (<Report onPrint={printReport} coletaDados={coleta} />)
      }
      onCancel={handleCancel}
    />
  );
}
