import styled from 'styled-components';

import { Delete } from '@material-ui/icons';
import Paper from '../../../components/Paper';

import { colors } from '../../../styles/colors';

export const TableContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 12px;

  width: min(800px, 100%);

  margin: 0 auto;
  background: ${colors.pedidos.background};
`;

export const DeleteIcon = styled(Delete)`
  color: ${colors.button.filled.background};
`;
