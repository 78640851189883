import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';

import TextField from '../../TextField';
import TextMaskCustom from '../../TextMaskCustom';

export default function FormTextField({
  name,
  helperText,
  textMaskProps,
  ...props
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  return (
    <TextField
      defaultValue={defaultValue}
      name={fieldName}
      error={!!error}
      helperText={error || helperText}
      inputRef={ref}
      inputProps={{
        id: fieldName,
        'aria-label': fieldName,
        ...textMaskProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={
        textMaskProps && {
          inputComponent: TextMaskCustom,
        }
      }
      {...props}
    />
  );
}

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  textMaskProps: PropTypes.objectOf(PropTypes.any),
};

FormTextField.defaultProps = {
  helperText: '',
  textMaskProps: null,
};
