import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

import {
  getColetasEmitidasRequest,
  reabreColetaRequest,
} from '../../../../store/modules/transportadora/coletas/actions';

import { COLETA_REABRIR, COLETA_IMPRIMIR } from '../../../../consts/features';
import { useFeatureEnabled } from '../../../../components/ToggledFeatures';
import formatter from '../../../../services/formatter';

import ColetaResumo from '../Resumo';
import ContentPage from '../../../../components/ContentPage';
import Table from '../../../../components/Table';
import InfoCard from '../../../../components/InfoCard';
import EditIcon from '../../../../components/CustomIcons/EditIcon';

import {
  TableContainer,
  InfoColeta,
  StyledTableItens,
  MotoristaIcon,
  CaminhaoIcon,
  InfoCards,
  StyledPrintCircleIcon,
} from './styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ColetasEmitidas() {
  const dispatch = useDispatch();
  const isFeatureReabrirColetaEnabled = useFeatureEnabled(COLETA_REABRIR);
  const isFeatureImprimirColetaEnabled = useFeatureEnabled(COLETA_IMPRIMIR);

  const coletas = useSelector(state => state.transportadora.coletas.emitidas);
  const count = useSelector(state => state.transportadora.coletas.count);
  const loading = useSelector(state => state.transportadora.coletas.loading);

  const [selectedColeta, setSelectedColeta] = useState(null);
  const [filtroConfirmadas, setFiltroConfirmadas] = useState(false);
  const [filtroCarregadas, setFiltroCarregadas] = useState(false);

  const [currentSort, setCurrentSort] = useState({
    sortBy: 'id',
    sortType: 'DESC',
  });
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    dispatch(
      getColetasEmitidasRequest(
        page,
        pageSize,
        filtroConfirmadas,
        filtroCarregadas,
        currentSort
      )
    );
  }, [
    page,
    pageSize,
    currentSort,
    filtroConfirmadas,
    filtroCarregadas,
    dispatch,
  ]);

  const [table, setTable] = useState({});
  const [columns, setColumns] = useState([
    { title: 'COLETA', field: 'coleta', sortBy: 'id' },
    { title: 'DATA', field: 'data', sortBy: 'data' },
    { title: 'FINALIZADA', field: 'finalizada', sortBy: 'data_finalizada' },
    {
      title: 'DATA COLETA',
      field: 'dataColeta',
      sortBy: 'data_previsao_coleta',
    },
    { title: 'PESO', field: 'peso', sortBy: 'peso' },
    { title: 'MOTORISTA', field: 'motoristaNome', sortBy: 'motorista.nome' },
    { title: 'USUÁRIO', field: 'usuario', sortBy: 'usuario.nome' },
    { title: 'OBSERVAÇÃO', field: 'observacao', sortBy: 'observacao' },
  ]);

  useEffect(() => {
    function reabreColeta(coletaId) {
      dispatch(reabreColetaRequest(coletaId));
    }

    function openResumo(coleta) {
      setSelectedColeta(coleta);
    }

    function renderReabreColeta(coleta) {
      if (coleta.confirmada || !isFeatureReabrirColetaEnabled) return null;

      return {
        icon: () => <EditIcon fontSize="large" />,
        tooltip: 'Editar coleta',
        onClick: (_event, rowData) => reabreColeta(rowData.id),
      };
    }

    function renderImprimeColeta(coleta) {
      if (!coleta.confirmada || !isFeatureImprimirColetaEnabled) return null;

      return {
        icon: () => <StyledPrintCircleIcon fontSize="large" />,
        tooltip: 'Imprimir Resumo Coleta',
        onClick: (_event, rowData) => openResumo(rowData),
      };
    }

    const actReabre = rowData => renderReabreColeta(rowData);
    const actImprime = rowData => renderImprimeColeta(rowData);
    const allActions = [actReabre, actImprime];

    setTable({
      actions: allActions,
      columns,
      data: coletas.map(item => ({
        id: item.id,
        coleta: item.id,
        data: formatter(item.data).toDayOnly(),
        finalizada: formatter(item.dataFinalizada).toDayOnly(),
        dataColeta: formatter(item.dataPrevisaoColeta).toDayOnly(),
        peso: formatter(item.peso).toKg(),
        motoristaNome: item.motorista && item.motorista.nome,
        motorista: item.motorista,
        veiculo: item.veiculo,
        usuario: item.usuario.nome,
        observacao: item.observacao,
        itens: item.itens,
        confirmada: item.confirmada,
        transportadora: item.transportadora,
      })),
    });
  }, [
    columns,
    pageSize,
    coletas,
    isFeatureReabrirColetaEnabled,
    dispatch,
    isFeatureImprimirColetaEnabled,
  ]);

  useEffect(() => {
    setPage(0);
  }, [pageSize, filtroCarregadas, filtroConfirmadas, currentSort]);

  const handleCarregadas = event => {
    setFiltroCarregadas(event.target.value);
  };

  const handleConfirmadas = event => {
    setFiltroConfirmadas(event.target.value);
  };

  const handleChangePage = newPage => {
    setPage(newPage);
  };

  const handleChangePageSize = newPageSize => {
    setPageSize(newPageSize);
  };

  const handleOrderChange = (fieldId, sortType) => {
    const { sortBy: relativeColumnField } = columns[fieldId] || {};
    setCurrentSort({
      sortBy: relativeColumnField,
      sortType: sortType.toUpperCase(),
    });
  };

  const handleChangeGroup = groupColumns => {
    const oldColumns = columns.map(c => ({ title: c.title, field: c.field }));
    const newColumns = [...oldColumns];

    groupColumns.forEach((groupColumn, index) => {
      const column = newColumns.find(c => c.field === groupColumn.field);
      if (column) column.defaultGroupOrder = index;
    });

    if (JSON.stringify(oldColumns) !== JSON.stringify(newColumns))
      setColumns(newColumns);
  };

  return (
    <>
      <ContentPage
        title="Coletas Emitidas"
        controls={
          <>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="confirmadas-label">Confirmação</InputLabel>
              <Select
                labelId="confirmadas-label"
                id="confirmadas-select"
                value={filtroConfirmadas}
                onChange={handleConfirmadas}
              >
                <MenuItem value={false}>Não Confirmadas</MenuItem>
                <MenuItem value>Confirmadas</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              className={classes.formControl}
              disabled={!filtroConfirmadas}
            >
              <InputLabel id="carregadas-label">Carregamento</InputLabel>
              <Select
                labelId="carregadas-label"
                id="carregadas-select"
                value={filtroCarregadas}
                onChange={handleCarregadas}
              >
                <MenuItem value>Carregadas</MenuItem>
                <MenuItem value={false}>Não Carregadas</MenuItem>
              </Select>
            </FormControl>
          </>
        }
        content={
          <TableContainer>
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              isLoading={loading}
              onOrderChange={handleOrderChange}
              onChangeGroup={handleChangeGroup}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangePageSize}
              page={page}
              totalCount={parseInt(count)}
              detailPanel={({ itens, motorista, veiculo }) => (
                <InfoColeta>
                  <InfoCards>
                    <InfoCard
                      icon={() => <MotoristaIcon />}
                      title={motorista && motorista.nome}
                      info={motorista && formatter(motorista.cpf).toCpf()}
                    />
                    <InfoCard
                      icon={() => <CaminhaoIcon />}
                      title={veiculo && veiculo.tipo && veiculo.tipo.descricao}
                      info={veiculo && formatter(veiculo.placa).toPlaca()}
                    />
                  </InfoCards>
                  <StyledTableItens itens={itens} />
                </InfoColeta>
              )}
              options={{
                actionsColumnIndex: -1,
                toolbar: false,
                grouping: true,
                page,
                rowStyle: rowData => ({
                  borderLeft: rowData.confirmada
                    ? '10px solid limegreen'
                    : '10px solid gold',
                }),
              }}
            />
          </TableContainer>
        }
      />
      {selectedColeta && (
        <ColetaResumo
          coleta={selectedColeta}
          onClose={() => setSelectedColeta(null)}
        />
      )}
    </>
  );
}
