import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormTextField from '../../../../components/Unform/FormTextField';
import FormSelectWrapper from '../../../../components/Unform/FormSelectWrapper';
import Modal from '../../../../components/ModalEdit';

import {
  empresa as TiposEmpresa,
  empresaString as TiposEmpresaString,
} from '../../../../consts/types';
import { telefoneCelular, telefoneFixo } from '../../../../consts/regex';
import { unmask, phoneMask, cnpjCpfMask } from '../../../../services/masks';
import { validaCnpj, validaCpf } from '../../../../services/validators';

import {
  createEmpresaRequest,
  updateEmpresaRequest,
} from '../../../../store/modules/ecosis/empresas/actions';

import { Container, TipoEmpresa } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email deve ser informado'),
  cnpj: Yup.string()
    .test(
      'CNPJ',
      'CNPJ/CPF inválido',
      value => !value || validaCnpj(value) || validaCpf(value)
    )
    .required('CNPJ deve ser informado'),
  nomeFantasia: Yup.string().required('Nome fantasia deve ser informado'),
  razaoSocial: Yup.string().required('Razão social deve ser informada'),
  telefone: Yup.string().test('Telefone', 'Telefone inválido', telefone => {
    if (!telefone) return true;

    if (unmask(telefone).length > 10) {
      return telefoneCelular.regex.test(telefone);
    }
    return telefoneFixo.regex.test(telefone);
  }),
  tipo: Yup.string().required('Informe o tipo'),
});

export default function FormEdit({ empresa, onClose }) {
  const dispatch = useDispatch();
  const [editEmpresa, setEditEmpresa] = useState(empresa);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsEditing(!!(editEmpresa && editEmpresa.id));
  }, [editEmpresa]);

  function handleCancel() {
    setEditEmpresa(null);
    onClose();
  }

  const [cnpjCpf, setCnpjCpf] = useState('');
  function getCnpjCpfLabel(value) {
    if (!value) return 'CNPJ/CPF';

    if (value.length > 11) return 'CNPJ';

    return 'CPF';
  }

  useEffect(() => {
    if (empresa && empresa.cnpj) setCnpjCpf(unmask(empresa.cnpj));
  }, [empresa]);

  function handleOnChangeCnpjCpf(value) {
    setCnpjCpf(unmask(value));
  }

  function handleSave(empresaData) {
    empresaData.cnpj = unmask(empresaData.cnpj);
    empresaData.telefone = unmask(empresaData.telefone);
    if (!isEditing) {
      dispatch(createEmpresaRequest(empresaData));
    } else {
      empresaData.id = editEmpresa.id;
      dispatch(updateEmpresaRequest(empresaData));
    }
    setEditEmpresa(null);
    onClose();
  }

  return (
    <Modal
      open={!!editEmpresa}
      title="Empresa"
      schema={schema}
      fieldsComponent={
        editEmpresa && (
          <Container>
            <FormTextField
              className="field"
              name="email"
              type="email"
              label="Email"
              spellCheck="false"
              defaultValue={editEmpresa.email}
              autoFocus={!isEditing}
            />
            <FormTextField
              className="field"
              name="cnpj"
              type="text"
              label={getCnpjCpfLabel(cnpjCpf)}
              spellCheck="false"
              defaultValue={editEmpresa.cnpj}
              textMaskProps={{
                mask: cnpjCpfMask,
              }}
              onChange={e => handleOnChangeCnpjCpf(e.target.value)}
            />
            <FormTextField
              className="field"
              name="nomeFantasia"
              type="text"
              label="Nome"
              spellCheck="false"
              inputProps={{ maxLength: 30 }}
              defaultValue={editEmpresa.nomeFantasia}
            />
            <FormTextField
              className="field"
              name="razaoSocial"
              type="text"
              label="Razão Social"
              spellCheck="false"
              inputProps={{ maxLength: 60 }}
              defaultValue={editEmpresa.razaoSocial}
            />
            <FormTextField
              className="field"
              name="telefone"
              type="text"
              label="Telefone"
              spellCheck="false"
              defaultValue={editEmpresa.telefone}
              textMaskProps={{
                mask: phoneMask,
              }}
            />
            <FormSelectWrapper name="tipo" className="field">
              <TipoEmpresa
                showSearch={false}
                options={Object.keys(TiposEmpresa)
                  .filter(tipo => tipo !== TiposEmpresa.ECOSIS)
                  .map(tipo => ({
                    text: 'Tipo',
                    info: TiposEmpresaString[tipo],
                    value: TiposEmpresa[tipo],
                  }))}
                value={editEmpresa.tipo || TiposEmpresa.TRANSPORTADORA}
              />
            </FormSelectWrapper>
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  empresa: PropTypes.shape({
    cnpj: PropTypes.string,
    nomeFantasia: PropTypes.string,
    razaoSocial: PropTypes.string,
    telefone: PropTypes.string,
    tipo: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
