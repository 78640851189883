import styled from 'styled-components';
import Paper from '../../components/Paper';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: row;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  transition: width 0.5s ease;
`;

export const HeaderContainer = styled(Paper)`
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
  position: relative;
  z-index: 102;
`;

export const ContentContainer = styled.div`
  overflow: auto;
  height: 100%;
`;
