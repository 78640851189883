import React from 'react'
import formatter from '../../../services/formatter';
import { ReportHeader, ReportRow, ReportData, Bold } from '../styles'

export default ({ coletaDados }) => {

  const collection = coletaDados
  const customer = collection.itens[0].cargaItem.cliente
  const { motorista, veiculo } = { ...collection }
  const driverDescription = motorista && [formatter(motorista.cpf).toCpf(), motorista.nome].join(' ').toUpperCase()

  return (
    <ReportHeader>
      <ReportRow>
        <Bold children={customer.razaoSocial} />
        <Bold size="medium" children={`Coleta: ${collection.id}`} />
      </ReportRow>
      <ReportRow>
        <ReportData />
        <ReportData>
          <Bold children="Data Emissão: " />
          {collection.data}
        </ReportData>
      </ReportRow>
      <ReportRow padding={3}>
        <ReportData>
          <Bold children={`Transportadora: ${collection.transportadora.nomeFantasia}`} />
        </ReportData>
        <ReportData>
          <Bold children="CNPJ:" />
          {formatter(collection.transportadora.cnpj).toCNPJ()}
        </ReportData>
      </ReportRow>
      <ReportRow>
        {
          ["Motorista:", "Prev. Coleta:"].map(info => (
            <ReportData key={info.trim()}>
              <Bold children={info} />
              {info === "Motorista:" ? driverDescription : collection.dataColeta}
            </ReportData>
          ))
        }
      </ReportRow>
      <ReportRow>
        <ReportData>
          <Bold children="Placa:" />
          {formatter(veiculo.placa).toPlaca()}
        </ReportData>
        {
          ['Tara:', "ANTT:"].map(info => (
            <ReportData key={info.trim()}>
              <Bold children={info} />
              {info === 'Tara:' ? veiculo.tara : veiculo.antt}
            </ReportData>
          ))
        }
      </ReportRow>
      <ReportRow>
        <Bold size="medium">{`Observações: ${collection.obervacao || 'Obs de coleta..'}`}</Bold>
      </ReportRow>
    </ReportHeader>
  )
}
