import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { colors } from '../../styles/colors';

export const StyledButton = styled(Button)`
  color: ${props =>
    props.variant === 'outlined'
      ? colors.button.outlined.text
      : colors.button.filled.text};
  background-color: ${props =>
    props.variant === 'outlined'
      ? colors.button.outlined.background
      : colors.button.filled.background};
  text-transform: none;
  padding: 6px;

  border-radius: 4px;
  line-height: 23px;
  border: ${props =>
    props.variant === 'outlined'
      ? `1px solid ${colors.button.outlined.border}`
      : null};

  &:hover {
    background-color: ${props =>
      props.variant === 'outlined'
        ? colors.button.outlined.hover
        : colors.button.filled.hover};
  }
`;
