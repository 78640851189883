export const SalaColetaItem = {
  sala: 'coletaitem',
  eventos: {
    store: 'store',
    update: 'update',
  },
};

export const SalaCargaItem = {
  sala: 'cargaitem',
  eventos: {
    update: 'update',
  },
};
