import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContentPage from '../../../components/ContentPage';
import Table from '../../../components/Table';
import EditIcon from '../../../components/CustomIcons/EditIcon';

import FormEdit from './FormEdit';

import formatter from '../../../services/formatter';

import Medias from '../../../consts/medias';

import {
  getVeiculosRequest,
  deleteVeiculoRequest,
} from '../../../store/modules/transportadora/veiculos/actions';

import {
  StyledRemoveCircleIcon,
  TableContainer,
  StyledButton,
  StyledFab,
  StyledAddCircleOutlineIcon,
  StyledAddIcon,
  StyledImage,
} from './styles';

export default function Veiculos() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const veiculos = useSelector(state => state.transportadora.veiculos.veiculos);
  const loading = useSelector(state => state.transportadora.veiculos.loading);
  const [selectedVeiculo, setSelectedVeiculo] = useState(null);

  useEffect(() => {
    dispatch(getVeiculosRequest());
  }, [dispatch]);

  const [table, setTable] = useState({});
  useEffect(() => {
    function edita(veiculo) {
      setSelectedVeiculo(veiculo);
    }

    function deleta(veiculoId) {
      dispatch(deleteVeiculoRequest(veiculoId));
    }

    setTable({
      columns: [
        { title: 'SILHUETA', field: 'silhueta' },
        { title: 'PLACA', field: 'placa' },
        { title: 'TIPO', field: 'tipoDescricao' },
        { title: 'ANTT', field: 'antt' },
        { title: 'TARA', field: 'tara' },
      ],
      data: veiculos.map(veiculo => ({
        id: veiculo.id,
        placa: formatter(veiculo.placa).toPlaca(),
        tipoDescricao: veiculo.tipo && veiculo.tipo.descricao,
        tipo: veiculo.tipo,
        docto: veiculo.docto,
        antt: veiculo.antt,
        tara: formatter(veiculo.tara).toDecimal(2),
        silhueta:
          veiculo.tipo.silhueta && veiculo.tipo.silhueta.url.length > 1 ? (
            <StyledImage>
              <img src={veiculo.tipo.silhueta.url} alt="Silhueta" />
            </StyledImage>
          ) : (
            ''
          ),
      })),
      actions: [
        {
          icon: () => <EditIcon fontSize="large" />,
          iconProps: { fontSize: 'large' },
          tooltip: 'Editar',
          onClick: (event, rowData) => edita(rowData),
        },
        {
          icon: () => <StyledRemoveCircleIcon fontSize="large" />,
          tooltip: 'Excluir',
          onClick: (event, rowData) => deleta(rowData.id),
        },
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
      },
    });
  }, [dispatch, veiculos]);

  return (
    <>
      <ContentPage
        title="Veículos"
        controls={
          !sm && (
            <StyledButton
              variant="contained"
              type="button"
              startIcon={<StyledAddCircleOutlineIcon />}
              onClick={() => setSelectedVeiculo({})}
            >
              Adicionar
            </StyledButton>
          )
        }
        content={
          <TableContainer>
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              isLoading={loading}
              autoSizing
            />
          </TableContainer>
        }
      />
      {sm && (
        <StyledFab onClick={() => setSelectedVeiculo({})}>
          <StyledAddIcon />
        </StyledFab>
      )}
      {selectedVeiculo && (
        <FormEdit
          veiculo={selectedVeiculo}
          onClose={() => setSelectedVeiculo(null)}
        />
      )}
    </>
  );
}
