export const Types = {
  GET_TRANSPORTADORAS_REQUEST:
    '@cliente/transportadoras/GET_TRANSPORTADORAS_REQUEST',
  GET_TRANSPORTADORAS_SUCCESS:
    '@cliente/transportadoras/GET_TRANSPORTADORAS_SUCCESS',
  GET_TRANSPORTADORAS_FAILURE:
    '@cliente/transportadoras/GET_TRANSPORTADORAS_FAILURE',

  CREATE_TRANSPORTADORA_REQUEST:
    '@cliente/transportadoras/CREATE_TRANSPORTADORA_REQUEST',
  CREATE_TRANSPORTADORA_SUCCESS:
    '@cliente/transportadoras/CREATE_TRANSPORTADORA_SUCCESS',
  CREATE_TRANSPORTADORA_FAILURE:
    '@cliente/transportadoras/CREATE_TRANSPORTADORA_FAILURE',

  UPDATE_TRANSPORTADORA_REQUEST:
    '@cliente/transportadoras/UPDATE_TRANSPORTADORA_REQUEST',
  UPDATE_TRANSPORTADORA_SUCCESS:
    '@cliente/transportadoras/UPDATE_TRANSPORTADORA_SUCCESS',
  UPDATE_TRANSPORTADORA_FAILURE:
    '@cliente/transportadoras/UPDATE_TRANSPORTADORA_FAILURE',

  CREATE_CONVITE_REQUEST: '@cliente/transportadoras/CREATE_CONVITE_REQUEST',
  CREATE_CONVITE_SUCCESS: '@cliente/transportadoras/CREATE_CONVITE_SUCCESS',
  CREATE_CONVITE_FAILURE: '@cliente/transportadoras/CREATE_CONVITE_FAILURE',
};
