import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  tiposVeiculos: [],
  loading: false,
};

export default function tiposVeiculos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_TIPOSVEICULOS_REQUEST:
      case Types.CREATE_TIPOVEICULO_REQUEST:
      case Types.UPDATE_TIPOVEICULO_REQUEST:
      case Types.DELETE_TIPOVEICULO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_TIPOSVEICULOS_SUCCESS: {
        draft.tiposVeiculos = action.payload.tiposVeiculos;
        draft.loading = false;
        break;
      }
      case Types.CREATE_TIPOVEICULO_SUCCESS: {
        draft.tiposVeiculos = [
          ...state.tiposVeiculos,
          action.payload.tipoVeiculo,
        ];
        draft.loading = false;
        break;
      }
      case Types.UPDATE_TIPOVEICULO_SUCCESS: {
        const index = draft.tiposVeiculos.findIndex(
          tipoVeiculo => tipoVeiculo.id === action.payload.tipoVeiculo.id
        );
        draft.tiposVeiculos[index] = action.payload.tipoVeiculo;
        draft.loading = false;
        break;
      }
      case Types.DELETE_TIPOVEICULO_SUCCESS: {
        draft.tiposVeiculos = state.tiposVeiculos.filter(
          tipoVeiculo => tipoVeiculo.id !== action.payload.tipoVeiculoId
        );
        draft.loading = false;
        break;
      }
      case Types.GET_TIPOSVEICULOS_FAILURE:
      case Types.CREATE_TIPOVEICULO_FAILURE:
      case Types.UPDATE_TIPOVEICULO_FAILURE:
      case Types.DELETE_TIPOVEICULO_FAILURE:
      case Types.DELETE_TIPOVEICULO_CANCEL: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
