import { Types } from './types';

export function signInRequest(email, senha) {
  return {
    type: Types.SIGN_IN_REQUEST,
    payload: { email, senha },
  };
}

export function signInSuccess(token, usuario) {
  return {
    type: Types.SIGN_IN_SUCCESS,
    payload: { token, usuario },
  };
}

export function signUpRequest(usuario, hash) {
  return {
    type: Types.SIGN_UP_REQUEST,
    payload: { usuario, hash },
  };
}

export function signUpSuccess() {
  return {
    type: Types.SIGN_UP_SUCCESS,
  };
}

export function novaSenhaRequest(email) {
  return {
    type: Types.NOVA_SENHA_REQUEST,
    payload: { email },
  };
}

export function novaSenhaSuccess() {
  return {
    type: Types.NOVA_SENHA_SUCCESS,
  };
}

export function resetSenhaRequest(senha, confirmSenha, hash) {
  return {
    type: Types.RESET_SENHA_REQUEST,
    payload: { senha, confirmSenha, hash },
  };
}

export function resetSenhaSuccess() {
  return {
    type: Types.RESET_SENHA_SUCCESS,
  };
}

export function signFailure() {
  return {
    type: Types.SIGN_FAILURE,
  };
}

export function signOut() {
  return {
    type: Types.SIGN_OUT,
  };
}
