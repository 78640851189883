import styled from 'styled-components';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Paper from '../../Paper';

import { colors } from '../../../styles/colors';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background: ${colors.pedidos.background};
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  color: #4ad991;
`;

export const InfoUser = styled.tr`
  td {
    background-color: ${colors.pedidos.info.background};
    padding: 5px;
  }
`;
