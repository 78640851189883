import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import DatePickerBase from '../../DatePickerBase';
import TextField from '../../TextField';

function CustomTextField({
  inputRef,
  unformName,
  helperText,
  ...textFieldProps
}) {
  const { fieldName, registerField, defaultValue, error } = useField(
    unformName
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [inputRef.current, fieldName]); // eslint-disable-line

  return (
    <TextField
      defaultValue={defaultValue}
      name={fieldName}
      error={!!error}
      helperText={error || helperText}
      inputRef={inputRef}
      inputProps={{
        id: fieldName,
        'aria-label': fieldName,
      }}
      {...textFieldProps}
    />
  );
}

CustomTextField.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  unformName: PropTypes.string,
  helperText: PropTypes.string,
};

CustomTextField.defaultProps = {
  inputRef: undefined,
  unformName: '',
  helperText: '',
};

export default function FormDatePicker({ name, helperText, ...props }) {
  return (
    <DatePickerBase
      unformName={name}
      TextFieldComponent={CustomTextField}
      {...props}
    />
  );
}
FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

FormDatePicker.defaultProps = {
  helperText: '',
};
