import { Types } from './types';

export function getTiposVeiculosRequest() {
  return {
    type: Types.GET_TIPOSVEICULOS_REQUEST,
  };
}

export function getTiposVeiculosSuccess(tiposVeiculos) {
  return {
    type: Types.GET_TIPOSVEICULOS_SUCCESS,
    payload: { tiposVeiculos },
  };
}

export function getTiposVeiculosFailure() {
  return {
    type: Types.GET_TIPOSVEICULOS_FAILURE,
  };
}

export function createTipoVeiculoRequest(tipoVeiculo) {
  return {
    type: Types.CREATE_TIPOVEICULO_REQUEST,
    payload: { tipoVeiculo },
  };
}

export function createTipoVeiculoSuccess(tipoVeiculo) {
  return {
    type: Types.CREATE_TIPOVEICULO_SUCCESS,
    payload: { tipoVeiculo },
  };
}

export function createTipoVeiculoFailure() {
  return {
    type: Types.CREATE_TIPOVEICULO_FAILURE,
  };
}

export function updateTipoVeiculoRequest(tipoVeiculo) {
  return {
    type: Types.UPDATE_TIPOVEICULO_REQUEST,
    payload: { tipoVeiculo },
  };
}

export function updateTipoVeiculoSuccess(tipoVeiculo) {
  return {
    type: Types.UPDATE_TIPOVEICULO_SUCCESS,
    payload: { tipoVeiculo },
  };
}

export function updateTipoVeiculoFailure() {
  return {
    type: Types.UPDATE_TIPOVEICULO_FAILURE,
  };
}

export function deleteTipoVeiculoRequest(tipoVeiculoId) {
  return {
    type: Types.DELETE_TIPOVEICULO_REQUEST,
    payload: { tipoVeiculoId },
  };
}

export function deleteTipoVeiculoSuccess(tipoVeiculoId) {
  return {
    type: Types.DELETE_TIPOVEICULO_SUCCESS,
    payload: { tipoVeiculoId },
  };
}

export function deleteTipoVeiculoFailure() {
  return {
    type: Types.DELETE_TIPOVEICULO_FAILURE,
  };
}

export function deleteTipoVeiculoCancel() {
  return {
    type: Types.DELETE_TIPOVEICULO_CANCEL,
  };
}
