import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '../../../components/Table';
import EditIcon from '../../../components/CustomIcons/EditIcon';

import FormEdit from './FormEdit';

import formatter from '../../../services/formatter';
import {
  usuarioString as TiposUsuarioString,
  statusConvite as TiposStatusConvite,
  statusConviteString as TiposStatusConviteString,
} from '../../../consts/types';

import {
  getConvitesRequest,
  reenviaConviteRequest,
  getUsuariosRequest,
  ativaUsuarioRequest,
  desativaUsuarioRequest,
} from '../../../store/modules/admin/usuarios/actions';

import {
  TableContainer,
  StyledCheckIcon,
  StyledMailOutlineIcon,
} from './styles';

export default function Usuarios() {
  const dispatch = useDispatch();
  const usuarios = useSelector(state => state.admin.usuarios.usuarios);
  const convites = useSelector(state => state.admin.usuarios.convites);
  const loading = useSelector(state => state.admin.usuarios.loading);
  const [selectedUsuario, setSelectedUsuario] = useState(null);

  useEffect(() => {
    dispatch(getUsuariosRequest());
    dispatch(getConvitesRequest());
  }, [dispatch]);

  const [table, setTable] = useState({});
  useEffect(() => {
    function edita(usuario) {
      setSelectedUsuario(usuario);
    }

    function ativa(usuarioId) {
      dispatch(ativaUsuarioRequest(usuarioId));
    }

    function desativa(usuarioId) {
      dispatch(desativaUsuarioRequest(usuarioId));
    }

    function reenviaConvite(conviteId) {
      dispatch(reenviaConviteRequest(conviteId));
    }

    function tableData() {
      return convites
        .map(convite => ({
          id: convite.id,
          email: convite.email,
          tipo: convite.tipo,
          tipoFormatted: TiposUsuarioString[convite.tipo],
          statusConvite: TiposStatusConvite.PENDENTE,
          statusConviteFormatted:
            TiposStatusConviteString[TiposStatusConvite.PENDENTE],
        }))
        .concat(
          usuarios.map(usuario => ({
            id: usuario.id,
            email: usuario.email,
            nome: usuario.nome,
            cpf: formatter(usuario.cpf).toCpf(),
            telefone: formatter(usuario.telefone).toTelefone(),
            tipo: usuario.tipo,
            tipoFormatted: TiposUsuarioString[usuario.tipo],
            ativo: usuario.ativo,
            statusConvite: TiposStatusConvite.ACEITO,
            statusConviteFormatted:
              TiposStatusConviteString[TiposStatusConvite.ACEITO],
          }))
        );
    }

    function renderAtivarDesativarButton(usuario) {
      if (usuario.statusConvite === TiposStatusConvite.PENDENTE) return null;

      return {
        icon: () => (
          <StyledCheckIcon fontSize="large" ativo={usuario.ativo ? 1 : 0} />
        ),
        tooltip: usuario.ativo ? 'Desativar' : 'Ativar',
        onClick: (event, data) =>
          usuario.ativo ? desativa(data.id) : ativa(data.id),
      };
    }

    function renderEditarButton(usuario) {
      if (usuario.statusConvite === TiposStatusConvite.PENDENTE) return null;

      return {
        icon: () => <EditIcon fontSize="large" />,
        tooltip: 'Editar',
        onClick: (event, data) => edita(data),
      };
    }

    function renderReenviar(usuario) {
      if (usuario.statusConvite === TiposStatusConvite.ACEITO) return null;
      return {
        icon: () => <StyledMailOutlineIcon fontSize="large" />,
        tooltip: 'Reenviar convite',
        onClick: (event, data) => reenviaConvite(data.id),
      };
    }

    setTable({
      columns: [
        { title: 'EMAIL', field: 'email' },
        { title: 'CONVITE', field: 'statusConviteFormatted' },
        { title: 'NOME', field: 'nome' },
        { title: 'CPF', field: 'cpf' },
        { title: 'TELEFONE', field: 'telefone' },
        { title: 'TIPO', field: 'tipoFormatted' },
      ],
      data: tableData(),
      actions: [
        rowData => renderAtivarDesativarButton(rowData),
        rowData => renderEditarButton(rowData),
        rowData => renderReenviar(rowData),
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
      },
    });
  }, [dispatch, usuarios, convites]);

  return (
    <>
      <TableContainer>
        <Table
          columns={table.columns}
          data={table.data}
          actions={table.actions}
          options={table.options}
          isLoading={loading}
          autoSizing
        />
      </TableContainer>

      {selectedUsuario && (
        <FormEdit
          usuario={selectedUsuario}
          onClose={() => setSelectedUsuario(null)}
        />
      )}
    </>
  );
}
