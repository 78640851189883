import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';

import {
  getDatasSuccess,
  getDatasFailure,
  createDataSuccess,
  createDataFailure,
  deleteDataFailure,
  deleteDataSuccess,
} from './actions';

import { Types } from './types';

export function* getDatas() {
  try {
    const response = yield call(api.get, ApiEndPoints.comuns.datasDisponiveis);
    const datasDisponiveis = response.data;

    yield put(getDatasSuccess(datasDisponiveis));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar as datas disponíveis');
    yield put(getDatasFailure());
  }
}

export function* createData({ payload }) {
  try {
    const { data } = payload;

    const response = yield call(
      api.post,
      ApiEndPoints.comuns.datasDisponiveis,
      data
    );

    const createdData = response.data;

    yield put(createDataSuccess(createdData));
    toast.success('Data criada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível criar a data');
    yield put(createDataFailure());
  }
}

export function* deleteData({ payload }) {
  try {
    const { data } = payload;

    yield call(
      api.delete,
      `${ApiEndPoints.comuns.datasDisponiveis}/${data.data}`
    );

    yield put(deleteDataSuccess(data));
  } catch (err) {
    trataErros(err, 'Não foi possível excluir a data');
    yield put(deleteDataFailure());
  }
}

export default all([
  takeLatest(Types.GET_DATAS_REQUEST, getDatas),
  takeLatest(Types.CREATE_DATA_REQUEST, createData),
  takeLatest(Types.DELETE_DATA_REQUEST, deleteData),
]);
