import { Types } from './types';

export function getEmpresaRequest() {
  return {
    type: Types.GET_EMPRESA_REQUEST,
  };
}

export function getEmpresaSuccess(empresa) {
  return {
    type: Types.GET_EMPRESA_SUCCESS,
    payload: { empresa },
  };
}

export function getEmpresaFailure() {
  return {
    type: Types.GET_EMPRESA_FAILURE,
  };
}

export function updateEmpresaRequest(empresa) {
  return {
    type: Types.UPDATE_EMPRESA_REQUEST,
    payload: { empresa },
  };
}

export function updateEmpresaSuccess(empresa) {
  return {
    type: Types.UPDATE_EMPRESA_SUCCESS,
    payload: { empresa },
  };
}

export function updateEmpresaFailure() {
  return {
    type: Types.UPDATE_EMPRESA_FAILURE,
  };
}
