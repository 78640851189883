import { Types } from './types';

export function updateUsuarioRequest(usuario) {
  return {
    type: Types.UPDATE_USUARIO_REQUEST,
    payload: { usuario },
  };
}

export function updateUsuarioSuccess(usuario) {
  return {
    type: Types.UPDATE_USUARIO_SUCCESS,
    payload: { usuario },
  };
}

export function updateUsuarioFailure() {
  return {
    type: Types.UPDATE_USUARIO_FAILURE,
  };
}
