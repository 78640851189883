import { Types } from './types';

export function getTransportadorasRequest() {
  return {
    type: Types.GET_TRANSPORTADORAS_REQUEST,
  };
}

export function getTransportadorasSuccess(transportadoras) {
  return {
    type: Types.GET_TRANSPORTADORAS_SUCCESS,
    payload: { transportadoras },
  };
}

export function getTransportadorasFailure() {
  return {
    type: Types.GET_TRANSPORTADORAS_FAILURE,
  };
}

export function createTransportadoraRequest(transportadora) {
  return {
    type: Types.CREATE_TRANSPORTADORA_REQUEST,
    payload: { transportadora },
  };
}

export function createTransportadoraSuccess(transportadora) {
  return {
    type: Types.CREATE_TRANSPORTADORA_SUCCESS,
    payload: { transportadora },
  };
}

export function createTransportadoraFailure() {
  return {
    type: Types.CREATE_TRANSPORTADORA_FAILURE,
  };
}

export function updateTransportadoraRequest(transportadora) {
  return {
    type: Types.UPDATE_TRANSPORTADORA_REQUEST,
    payload: { transportadora },
  };
}

export function updateTransportadoraSuccess(transportadora) {
  return {
    type: Types.UPDATE_TRANSPORTADORA_SUCCESS,
    payload: { transportadora },
  };
}

export function updateTransportadoraFailure() {
  return {
    type: Types.UPDATE_TRANSPORTADORA_FAILURE,
  };
}

export function createConviteRequest(transportadora) {
  return {
    type: Types.CREATE_CONVITE_REQUEST,
    payload: { transportadora },
  };
}

export function createConviteSuccess(transportadora) {
  return {
    type: Types.CREATE_CONVITE_SUCCESS,
    payload: { transportadora },
  };
}

export function createConviteFailure() {
  return {
    type: Types.CREATE_CONVITE_FAILURE,
  };
}
