import React, { useState } from 'react';
import Scroll from 'react-perfect-scrollbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

import { ContentRoutes } from '../../routes/contentRoutes';

import Medias from '../../consts/medias';

import { Container, Body, HeaderContainer, ContentContainer } from './styles';

export default function MainPage() {
  const sm = useMediaQuery(Medias.sm);
  const [sidebarWidth, setSidebarWidth] = useState(0);

  const [openMenu, setOpenMenu] = useState(false);

  function handleSidebarSizeChange(width) {
    setSidebarWidth(width);
  }

  return (
    <Container>
      <Sidebar
        open={openMenu}
        onClose={() => setOpenMenu(!openMenu)}
        onSizeChange={handleSidebarSizeChange}
      />
      <Body style={{ width: `calc(100% - ${sidebarWidth}px)` }}>
        <HeaderContainer>
          <Header onMenuButtonClick={() => setOpenMenu(!openMenu)} />
        </HeaderContainer>
        <ContentContainer>
          {sm ? (
            <ContentRoutes />
          ) : (
            <Scroll>
              <ContentRoutes />
            </Scroll>
          )}
        </ContentContainer>
      </Body>
    </Container>
  );
}
