export const Types = {
  GET_DATAS_REQUEST: '@admin/datas/GET_DATAS_REQUEST',
  GET_DATAS_SUCCESS: '@admin/datas/GET_DATAS_SUCCESS',
  GET_DATAS_FAILURE: '@admin/datas/GET_DATAS_FAILURE',

  CREATE_DATA_REQUEST: '@admin/datas/CREATE_DATA_REQUEST',
  CREATE_DATA_SUCCESS: '@admin/datas/CREATE_DATA_SUCCESS',
  CREATE_DATA_FAILURE: '@admin/datas/CREATE_DATA_FAILURE',

  DELETE_DATA_REQUEST: '@admin/datas/DELETE_DATA_REQUEST',
  DELETE_DATA_SUCCESS: '@admin/datas/DELETE_DATA_SUCCESS',
  DELETE_DATA_FAILURE: '@admin/datas/DELETE_DATA_FAILURE',
};
