import React from 'react';
import PropTypes from 'prop-types';
import TextMaskCustom from '../TextMaskCustom';

import { StyledTextField } from './styles';

export default function TextField({ textMaskProps, ...props }) {
  return (
    <StyledTextField
      inputProps={{
        ...textMaskProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={
        textMaskProps && {
          inputComponent: TextMaskCustom,
        }
      }
      {...props}
    />
  );
}

TextField.propTypes = {
  textMaskProps: PropTypes.objectOf(PropTypes.any),
};

TextField.defaultProps = {
  textMaskProps: null,
};
