import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  transportadoras: [],
  loading: false,
};

export default function transportadoras(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_TRANSPORTADORAS_REQUEST:
      case Types.CREATE_TRANSPORTADORA_REQUEST:
      case Types.UPDATE_TRANSPORTADORA_REQUEST:
      case Types.CREATE_CONVITE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_TRANSPORTADORAS_SUCCESS: {
        draft.transportadoras = action.payload.transportadoras;
        draft.loading = false;
        break;
      }
      case Types.CREATE_TRANSPORTADORA_SUCCESS: {
        draft.transportadoras = [
          ...state.transportadoras,
          action.payload.transportadora,
        ];
        draft.loading = false;
        break;
      }
      case Types.UPDATE_TRANSPORTADORA_SUCCESS:
      case Types.CREATE_CONVITE_SUCCESS: {
        const index = draft.transportadoras.findIndex(
          transportadora =>
            transportadora.id === action.payload.transportadora.id
        );
        draft.transportadoras[index] = action.payload.transportadora;
        draft.loading = false;
        break;
      }
      case Types.GET_TRANSPORTADORAS_FAILURE:
      case Types.CREATE_TRANSPORTADORA_FAILURE:
      case Types.UPDATE_TRANSPORTADORA_FAILURE:
      case Types.CREATE_CONVITE_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
