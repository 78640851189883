/* eslint-disable react/prop-types */
import React from 'react';

import {
  StyledModal,
  Container,
  Title,
  FormContainer,
  StyledForm,
  Buttons,
  StyledButton,
  Footer,
} from './styles';

export default function ModalInfo({
  title,
  open,
  fieldsComponent,
  onCancel,
  onPrint,
  initialData,
}) {
  return (
    <StyledModal open={open}>
      <Container>
        <StyledForm initialData={initialData} autoComplete="off">
          <Title>
            <p>{title}</p>
          </Title>
          <FormContainer>{fieldsComponent}</FormContainer>
          <Footer>
            <Buttons>
              <StyledButton
                className="cancelButton"
                variant="contained"
                type="button"
                onClick={onCancel}
              >
                Fechar
              </StyledButton>
              <StyledButton
                className="saveButton"
                variant="contained"
                type="button"
                onClick={onPrint}
              >
                Imprimir
              </StyledButton>
            </Buttons>
          </Footer>
        </StyledForm>
      </Container>
    </StyledModal>
  );
}
