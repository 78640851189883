import styled, { css } from 'styled-components';

export const Container = styled.div`
  .Mui-error {
    font-weight: bold;
  }

  ${props =>
    props.error &&
    css`
      fieldset {
        border-color: red;
      }
    `}
`;
