import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'PortalCargas',
      storage,
      whitelist: ['auth', 'user'],
      stateReconciler: autoMergeLevel2,
    },
    reducers
  );

  return persistedReducer;
};
