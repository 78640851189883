const _queryParams = (params = {}) => {
  const queryParams = Object.entries(params).reduce((acc, [key, value]) => {
    const qp = `${key}=${value}`;
    return value !== null && value !== undefined && value !== ''
      ? `${acc}${qp}&`
      : acc;
  }, '');
  return queryParams;
};

const ApiEndpoints = {
  get sessoes() {
    return `/sessoes`;
  },
  get novaSenha() {
    return `/novasenha`;
  },
  usuarios: {
    root: '/usuarios',
    get resetsenha() {
      return `${this.root}/resetsenha`;
    },
  },
  admin: {
    root: '/admin',
    get empresa() {
      return `${this.root}/empresa`;
    },
    get usuarios() {
      return `${this.root}/usuarios`;
    },
    get convites() {
      return `${this.root}/convites`;
    },
  },
  comuns: {
    root: '/comuns',
    get arquivos() {
      return `${this.root}/arquivos`;
    },
    get usuario() {
      return `${this.root}/usuario`;
    },
    get tiposVeiculos() {
      return `${this.root}/tiposveiculos`;
    },
    get feriados() {
      return `${this.root}/feriados`;
    },
    get datasDisponiveis() {
      return `${this.root}/data_disponivel`;
    },
  },
  cliente: {
    root: '/cliente',
    cargaItens({ ...params }) {
      return `${this.root}/cargaItens?${_queryParams(params)}`;
    },
    coletasEmitidas(page, pageSize) {
      return `${this.root}/coletas?finalizadas=true&reabertas=false&page=${page}&pageSize=${pageSize}`;
    },
    get transportadoras() {
      return `${this.root}/transportadoras`;
    },
    convitesTransportadoras(transportadoraId) {
      return `${this.root}/transportadoras/${transportadoraId}/convites`;
    },
  },
  ecosis: {
    root: '/ecosis',
    get empresas() {
      return `${this.root}/empresas`;
    },
    get tiposVeiculos() {
      return `${this.root}/tiposveiculos`;
    },
    usuarios(empresaId) {
      return `${this.root}/empresas/${empresaId}/usuarios`;
    },
  },
  transportadora: {
    root: '/transportadora',
    get cargaItens() {
      return `${this.root}/cargaItens`;
    },
    get coletas() {
      return `${this.root}/coletas`;
    },
    coletaItens(coletaId) {
      return `${this.root}/coletas/${coletaId}/itens`;
    },
    get coletasEmAndamento() {
      return `${this.root}/coletas?finalizadas=false&reabertas=true&itens=true&usuario=true`;
    },
    coletasEmitidas({ ...params }) {
      return `${
        this.root
      }/coletas?finalizadas=true&reabertas=false&itens=true&${_queryParams(
        params
      )}`;
    },
    get motoristas() {
      return `${this.root}/motoristas`;
    },
    get veiculos() {
      return `${this.root}/veiculos`;
    },
    get tipoVeiculos() {
      return `${this.root}/tiposveiculos`;
    },
    datas(coletaId) {
      return `${this.root}/coletas/${coletaId}/datas`;
    },
  },
};

export default ApiEndpoints;
