import styled from 'styled-components';
import { Form } from '@rocketseat/unform';

import Paper from '../../../../components/Paper';
import ComboboxMotoristas from '../../../../components/Combobox/Motoristas';
import ComboboxVeiculos from '../../../../components/Combobox/Veiculos';


import { colors } from '../../../../styles/colors';
import Medias from '../../../../consts/medias';

export const StyledComboboxMotoristas = styled(ComboboxMotoristas)`
  margin-bottom: 20px;
`;

export const StyledComboboxVeiculos = styled(ComboboxVeiculos)`
  margin-bottom: 20px;
`;

export const Header = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    padding: 6px 6px 6px 0;
    background: none;
    border: 0;
    img {
      transform: rotate(90deg);
    }
  }
`;

export const ContentContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  padding: 20px 12px;
  width: 100%;
  background: ${colors.pedidos.background};
  max-height: 500px;
`;

export const ColetaInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 12px;
  width: 100%;
  min-height: 50%;

  @media ${Medias.sm} {
    padding: 0;
  }
`;

export const StyledForm = styled(Form)`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .field {
    margin: 15px 0;
  }

  #buttonFinalizar {
    visibility: hidden;
  }

  @media ${Medias.sm} {
    min-width: 100%;
  }
`;

export const Datas = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .data {
    max-width: 200px;
  }

  @media ${Medias.sm} {
    display: inline-block;
    .data {
      min-width: 100%;
    }
  }
`;