import { Types } from './types';

export function getPedidosRequest(dataInicial, dataFinal, page, pageSize, currentSort) {
  return {
    type: Types.GET_PEDIDOS_REQUEST,
    payload: { dataInicial, dataFinal, page, pageSize, currentSort },
  };
}

export function getPedidosSuccess(pedidos, count) {
  return {
    type: Types.GET_PEDIDOS_SUCCESS,
    payload: { pedidos, count },
  };
}

export function getPedidosFailure() {
  return {
    type: Types.GET_PEDIDOS_FAILURE,
  };
}
