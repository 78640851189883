export const Types = {
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_UP_REQUEST: '@auth/SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: '@auth/SIGN_UP_SUCCESS',
  NOVA_SENHA_REQUEST: '@auth/NOVA_SENHA_REQUEST',
  NOVA_SENHA_SUCCESS: '@auth/NOVA_SENHA_SUCCESS',
  RESET_SENHA_REQUEST: '@auth/RESET_SENHA_REQUEST',
  RESET_SENHA_SUCCESS: '@auth/RESET_SENHA_SUCCESS',
  SIGN_FAILURE: '@auth/SIGN_FAILURE',
  SIGN_OUT: '@auth/SIGN_OUT',
};
