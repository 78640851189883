import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormTextField from '../../../../components/Unform/FormTextField';
import Modal from '../../../../components/ModalEdit';

import { unmask, phoneMask } from '../../../../services/masks';
import { validaCpf } from '../../../../services/validators';

import { cpf, telefoneCelular, telefoneFixo } from '../../../../consts/regex';

import {
  createMotoristaRequest,
  updateMotoristaRequest,
} from '../../../../store/modules/transportadora/motoristas/actions';

import CNH from '../../../../assets/images/cnh.png';

import { Container, CNHInput } from './styles';

const schema = Yup.object().shape({
  nome: Yup.string()
    .trim()
    .min(4, 'Nome deve possuir mais que 3 caracteres')
    .required('Nome deve ser informado'),
  cpf: Yup.string()
    .test('validaCPF', 'CPF inválido', value => !value || validaCpf(value))
    .nullable(),
  cnhId: Yup.number(),
  telefone: Yup.string().test('Telefone', 'Telefone inválido', telefone => {
    if (!telefone) return true;

    if (unmask(telefone).length > 10) {
      return telefoneCelular.regex.test(telefone);
    }
    return telefoneFixo.regex.test(telefone);
  }),
  email: Yup.string()
    .email()
    .nullable(),
});

export default function FormEdit({ motorista, onClose }) {
  const dispatch = useDispatch();
  const [editMotorista, setEditMotorista] = useState(motorista);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsEditing(!!(editMotorista && editMotorista.id));
  }, [editMotorista]);

  function handleCancel() {
    setEditMotorista(null);
    onClose();
  }

  function handleSave(motoristaData) {
    motoristaData.cpf = unmask(motoristaData.cpf);
    if (!isEditing) {
      dispatch(createMotoristaRequest(motoristaData));
    } else {
      motoristaData.id = editMotorista.id;
      dispatch(updateMotoristaRequest(motoristaData));
    }
    setEditMotorista(null);
    onClose();
  }

  return (
    <Modal
      open={!!editMotorista}
      title="Motorista"
      schema={schema}
      fieldsComponent={
        editMotorista && (
          <Container>
            <FormTextField
              className="field"
              name="nome"
              type="text"
              label="Nome"
              spellCheck="false"
              defaultValue={editMotorista.nome}
              autoFocus={!isEditing}
            />
            <FormTextField
              className="field"
              name="cpf"
              type="text"
              label="CPF"
              defaultValue={editMotorista.cpf}
              textMaskProps={{
                mask: cpf.array,
              }}
            />
            <FormTextField
              className="field"
              name="telefone"
              type="text"
              label="Telefone"
              spellCheck="false"
              textMaskProps={{
                mask: phoneMask,
              }}
              defaultValue={motorista.telefone}
            />
            <FormTextField
              className="field"
              name="email"
              type="email"
              label="Email"
              spellCheck="false"
              defaultValue={motorista.email}
            />
            <CNHInput
              className="field"
              name="cnhId"
              url={editMotorista.cnh && editMotorista.cnh.url}
              altImage={CNH}
              labelText="CNH"
              defaultValue={editMotorista.cnh && editMotorista.cnh.id}
            />
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  motorista: PropTypes.shape({
    nome: PropTypes.string,
    cpf: PropTypes.string,
    telefone: PropTypes.string,
    email: PropTypes.string,
    cnh: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
