import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

import { colors, defaultColors } from '../../styles/colors';

export const Container = styled(Chip)`
  background-color: ${defaultColors.color2};
  color: ${colors.pedidos.info.chip.color};
  margin-right: 5px;
  height: 42px;
  border-radius: 42px;

  .MuiChip-avatar {
    height: 32px;
    width: 32px;
  }
`;

export const ChipLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  p {
    font-size: 14px;
  }

  span {
    font-size: 12px;
    color: #a3a6b4;
  }
`;
