import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';

import { StyledFormControlLabel, StyledCheckbox } from '../../Checkbox/styles';

export default function FormCheckbox({ name, label, ...props }) {
  const ref = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'checked',
      clearValue: inputRef => {
        inputRef.checked = false;
      },
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  const { inputProps } = { ...props };

  return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          color="default"
          id={fieldName}
          inputProps={{
            ...inputProps,
            ref,
            name: fieldName,
            id: fieldName,
            'aria-label': fieldName,
          }}
          {...props}
        />
      }
      label={label}
    />
  );
}

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

FormCheckbox.defaultProps = {
  label: '',
};
