import styled from 'styled-components';
import Medias from '../../../../consts/medias';

export const Container = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 13px;

  .field {
    margin: 15px 0;
  }

  @media ${Medias.sm} {
    width: 100%;
  }
`;
