import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledModal,
  Container,
  Title,
  FormContainer,
  StyledForm,
  Buttons,
  StyledButton,
  Footer,
} from './styles';

export default function ModalEdit({
  title,
  open,
  fieldsComponent,
  onSave,
  onCancel,
  schema,
  initialData,
}) {
  function handleSubmit(formData) {
    onSave(formData);
  }

  return (
    <StyledModal open={open}>
      <Container>
        <StyledForm
          onSubmit={handleSubmit}
          schema={schema}
          initialData={initialData}
          autoComplete="off"
        >
          <Title>
            <p>{title}</p>
          </Title>
          <FormContainer>{fieldsComponent}</FormContainer>
          <Footer>
            <Buttons>
              <StyledButton
                className="cancelButton"
                variant="outlined"
                type="button"
                onClick={onCancel}
              >
                Cancelar
              </StyledButton>
              <StyledButton
                className="saveButton"
                variant="contained"
                type="submit"
              >
                Salvar
              </StyledButton>
            </Buttons>
          </Footer>
        </StyledForm>
      </Container>
    </StyledModal>
  );
}

ModalEdit.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  fieldsComponent: PropTypes.element,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  schema: PropTypes.shape(),
  initialData: PropTypes.shape(),
};

ModalEdit.defaultProps = {
  schema: null,
  initialData: null,
  fieldsComponent: null,
};
