import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormTextField from '../../../../components/Unform/FormTextField';
import Modal from '../../../../components/ModalEdit';

import { telefoneCelular, telefoneFixo } from '../../../../consts/regex';
import { unmask, phoneMask, cnpjCpfMask } from '../../../../services/masks';
import { validaCnpj, validaCpf } from '../../../../services/validators';

import { statusConvite as TiposStatusConvite } from '../../../../consts/types';

import {
  createTransportadoraRequest,
  updateTransportadoraRequest,
} from '../../../../store/modules/cliente/transportadoras/actions';

import { Container } from './styles';

const schema = Yup.object().shape({
  email: Yup.string().email().required('Email deve ser informado'),
  cnpj: Yup.string()
    .test(
      'CNPJ',
      'CNPJ/CPF inválido',
      (value) => !value || validaCnpj(value) || validaCpf(value)
    )
    .required('CNPJ deve ser informado'),
  nomeFantasia: Yup.string().required('Nome fantasia deve ser informado'),
  razaoSocial: Yup.string().required('Razão social deve ser informada'),
  telefone: Yup.string().test('Telefone', 'Telefone inválido', (telefone) => {
    if (!telefone) return true;

    if (unmask(telefone).length > 10) {
      return telefoneCelular.regex.test(telefone);
    }
    return telefoneFixo.regex.test(telefone);
  }),
});

export default function FormEdit({ transportadora, onClose }) {
  const dispatch = useDispatch();
  const [editTransportadora, setEditTransportadora] = useState(transportadora);
  const [isEditing, setIsEditing] = useState(false);

  const inviteIsAccepted =
    transportadora.statusConvite === TiposStatusConvite.ACEITO;

  useEffect(() => {
    setIsEditing(!!(editTransportadora && editTransportadora.id));
  }, [editTransportadora]);

  function handleCancel() {
    setEditTransportadora(null);
    onClose();
  }

  const [cnpjCpf, setCnpjCpf] = useState('');
  function getCnpjCpfLabel(value) {
    if (!value) return 'CNPJ/CPF';

    if (value.length > 11) return 'CNPJ';

    return 'CPF';
  }

  useEffect(() => {
    if (transportadora && transportadora.cnpj)
      setCnpjCpf(unmask(transportadora.cnpj));
  }, [transportadora]);

  function handleOnChangeCnpjCpf(value) {
    setCnpjCpf(unmask(value));
  }

  function handleSave(transportadoraData) {
    transportadoraData.cnpj = unmask(transportadoraData.cnpj);
    transportadoraData.telefone = unmask(transportadoraData.telefone);

    if (!isEditing) {
      dispatch(createTransportadoraRequest(transportadoraData));
    } else {
      transportadoraData.id = editTransportadora.id;
      transportadoraData.emailAnterior = editTransportadora.email;
      transportadoraData.statusConvite = editTransportadora.statusConvite;

      dispatch(updateTransportadoraRequest(transportadoraData));
    }

    setEditTransportadora(null);
    onClose();
  }

  return (
    <Modal
      open={!!editTransportadora}
      title="Transportadora"
      schema={schema}
      fieldsComponent={
        editTransportadora && (
          <Container>
            <FormTextField
              className="field"
              name="email"
              type="email"
              label="Email"
              spellCheck="false"
              defaultValue={editTransportadora.email}
              autoFocus={!isEditing}
            />
            <FormTextField
              className="field"
              name="cnpj"
              type="text"
              label={getCnpjCpfLabel(cnpjCpf)}
              spellCheck="false"
              defaultValue={editTransportadora.cnpj}
              textMaskProps={{
                mask: cnpjCpfMask,
              }}
              onChange={(e) => handleOnChangeCnpjCpf(e.target.value)}
              disabled={isEditing}
            />
            <FormTextField
              className="field"
              name="nomeFantasia"
              type="text"
              label="Nome"
              spellCheck="false"
              inputProps={{ maxLength: 30 }}
              defaultValue={editTransportadora.nomeFantasia}
              disabled={inviteIsAccepted}
            />
            <FormTextField
              className="field"
              name="razaoSocial"
              type="text"
              label="Razão Social"
              spellCheck="false"
              inputProps={{ maxLength: 60 }}
              defaultValue={editTransportadora.razaoSocial}
              disabled={inviteIsAccepted}
            />
            <FormTextField
              className="field"
              name="telefone"
              type="text"
              label="Telefone"
              spellCheck="false"
              defaultValue={editTransportadora.telefone}
              textMaskProps={{
                mask: phoneMask,
              }}
              disabled={inviteIsAccepted}
            />
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  transportadora: PropTypes.shape({
    cnpj: PropTypes.string,
    nomeFantasia: PropTypes.string,
    razaoSocial: PropTypes.string,
    telefone: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

