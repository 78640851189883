export default {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'linhas',
    labelRowsPerPage: 'Linhas por página:',
    firstAriaLabel: 'Primeira página',
    firstTooltip: 'Primeira página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Próxima página',
    nextTooltip: 'Próxima página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
  },
  toolbar: {
    nRowsSelected: '{0} linha(s) selecionada(s)',
  },
  header: {
    actions: '',
  },
  body: {
    emptyDataSourceMessage: 'Nenhum registro foi encontrado',
    addTooltip: 'Adicionar',
    deleteTooltip: 'Excluir',
    editTooltip: 'Editar',
    filterRow: {
      filterTooltip: 'Filtro',
    },
  },
  grouping: {
    placeholder: 'Arraste os campos para agrupar...',
    groupedBy: 'Agrupado por:',
  },
};
