import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';

import {
  getConvitesSuccess,
  getConvitesFailure,
  createConviteSuccess,
  createConviteFailure,
  reenviaConviteSuccess,
  reenviaConviteFailure,
  getUsuariosSuccess,
  getUsuariosFailure,
  updateUsuarioSuccess,
  updateUsuarioFailure,
  ativaUsuarioSuccess,
  ativaUsuarioFailure,
  desativaUsuarioSuccess,
  desativaUsuarioFailure,
} from './actions';
import { Types } from './types';

export function* getConvites() {
  try {
    const response = yield call(api.get, ApiEndPoints.admin.convites);

    const convites = response.data;

    yield put(getConvitesSuccess(convites));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os convites');
    yield put(getConvitesFailure());
  }
}

export function* createConvite({ payload }) {
  try {
    const { convite } = payload;

    const response = yield call(api.post, ApiEndPoints.admin.convites, convite);

    const newConvite = response.data;

    yield put(createConviteSuccess(newConvite));
    toast.success('Usuário foi convidado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível convidar o usuário');
    yield put(createConviteFailure());
  }
}

export function* reenviaConvite({ payload }) {
  try {
    const { conviteId } = payload;

    const response = yield call(
      api.post,
      `${ApiEndPoints.admin.convites}/${conviteId}/reenviar`
    );

    const updatedConvite = response.data;

    yield put(reenviaConviteSuccess(updatedConvite));
    toast.success('Convite reenviado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível reenviar o convite');
    yield put(reenviaConviteFailure());
  }
}

export function* getUsuarios() {
  try {
    const response = yield call(api.get, ApiEndPoints.admin.usuarios);

    const usuarios = response.data;

    yield put(getUsuariosSuccess(usuarios));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os usuários');
    yield put(getUsuariosFailure());
  }
}

export function* updateUsuario({ payload }) {
  try {
    const { usuario } = payload;

    const response = yield call(
      api.put,
      `${ApiEndPoints.admin.usuarios}/${usuario.id}`,
      usuario
    );

    const updatedUsuario = response.data;

    yield put(updateUsuarioSuccess(updatedUsuario));
    toast.success('Usuário atualizado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar o usuário');
    yield put(updateUsuarioFailure());
  }
}

export function* ativaUsuario({ payload }) {
  try {
    const { usuarioId } = payload;

    const response = yield call(
      api.post,
      `${ApiEndPoints.admin.usuarios}/${usuarioId}/ativar`
    );

    const updatedUsuario = response.data;

    yield put(ativaUsuarioSuccess(updatedUsuario.id));
    toast.success('Usuário ativado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível ativar o usuário');
    yield put(ativaUsuarioFailure());
  }
}

export function* desativaUsuario({ payload }) {
  try {
    const { usuarioId } = payload;

    const response = yield call(
      api.post,
      `${ApiEndPoints.admin.usuarios}/${usuarioId}/desativar`
    );

    const updatedUsuario = response.data;

    yield put(desativaUsuarioSuccess(updatedUsuario.id));
    toast.success('Usuário desativado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível desativar o usuário');
    yield put(desativaUsuarioFailure());
  }
}

export default all([
  takeLatest(Types.GET_CONVITES_REQUEST, getConvites),
  takeLatest(Types.CREATE_CONVITE_REQUEST, createConvite),
  takeLatest(Types.REENVIA_CONVITE_REQUEST, reenviaConvite),
  takeLatest(Types.GET_USUARIOS_REQUEST, getUsuarios),
  takeLatest(Types.UPDATE_USUARIO_REQUEST, updateUsuario),
  takeLatest(Types.ATIVA_USUARIO_REQUEST, ativaUsuario),
  takeLatest(Types.DESATIVA_USUARIO_REQUEST, desativaUsuario),
]);
