import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Loader from 'react-loader-spinner';

import FormTextField from '../../../components/Unform/FormTextField';

import { unmask, phoneMask, cnpjCpfMask } from '../../../services/masks';
import { validaCnpj, validaCpf } from '../../../services/validators';

import { telefoneCelular, telefoneFixo } from '../../../consts/regex';

import { defaultColors } from '../../../styles/colors';

import {
  getEmpresaRequest,
  updateEmpresaRequest,
} from '../../../store/modules/admin/empresa/actions';

import {
  LoaderContainer,
  EmpresaInfo,
  StyledForm,
  StyledButton,
} from './styles';

const schema = Yup.object().shape({
  email: Yup.string().email().required('Email deve ser informado'),
  cnpj: Yup.string()
    .test(
      'CNPJ',
      'CNPJ/CPF inválido',
      (value) => !value || validaCnpj(value) || validaCpf(value)
    )
    .required('CNPJ deve ser informado'),
  nomeFantasia: Yup.string().required('Nome fantasia deve ser informado'),
  razaoSocial: Yup.string().required('Razão social deve ser informada'),
  telefone: Yup.string().test('Telefone', 'Telefone inválido', (telefone) => {
    if (!telefone) return true;

    if (unmask(telefone).length > 10) {
      return telefoneCelular.regex.test(telefone);
    }
    return telefoneFixo.regex.test(telefone);
  }),
});

export default function Empresa() {
  const dispatch = useDispatch();

  const empresa = useSelector((state) => state.admin.empresa.empresa);
  const loading = useSelector((state) => state.admin.empresa.loading);

  useEffect(() => {
    dispatch(getEmpresaRequest());
  }, [dispatch]);

  function handleSave(empresaData) {
    empresaData.cnpj = unmask(empresaData.cnpj);
    empresaData.telefone = unmask(empresaData.telefone);
    if (!empresaData.cnpj) delete empresaData.cnpj;

    empresaData.id = empresa.id;
    dispatch(updateEmpresaRequest(empresaData));
  }

  const [cnpjCpf, setCnpjCpf] = useState('');
  function getCnpjCpfLabel(value) {
    if (!value) return 'CNPJ/CPF';

    if (value.length > 11) return 'CNPJ';

    return 'CPF';
  }

  useEffect(() => {
    if (empresa && empresa.cnpj) setCnpjCpf(unmask(empresa.cnpj));
  }, [empresa]);

  function handleOnChangeCnpjCpf(value) {
    setCnpjCpf(unmask(value));
  }

  return (
    <>
      {!empresa && loading ? (
        <LoaderContainer>
          <Loader
            type="TailSpin"
            color={defaultColors.color6}
            height={40}
            width={40}
          />
        </LoaderContainer>
      ) : (
        empresa && (
          <EmpresaInfo>
            <StyledForm
              id="formFinalizar"
              onSubmit={handleSave}
              autoComplete="off"
              schema={schema}
            >
              <FormTextField
                className="field"
                name="email"
                type="email"
                label="Email"
                spellCheck="false"
                defaultValue={empresa.email}
              />
              <FormTextField
                disabled
                className="field"
                name="cnpj"
                type="text"
                label={getCnpjCpfLabel(cnpjCpf)}
                spellCheck="false"
                defaultValue={empresa.cnpj}
                textMaskProps={{
                  mask: cnpjCpfMask,
                }}
                onChange={(e) => handleOnChangeCnpjCpf(e.target.value)}
              />
              <FormTextField
                className="field"
                name="nomeFantasia"
                type="text"
                label="Nome"
                spellCheck="false"
                inputProps={{ maxLength: 30 }}
                defaultValue={empresa.nomeFantasia}
              />
              <FormTextField
                className="field"
                name="razaoSocial"
                type="text"
                label="Razão Social"
                spellCheck="false"
                inputProps={{ maxLength: 60 }}
                defaultValue={empresa.razaoSocial}
              />
              <FormTextField
                className="field"
                name="telefone"
                type="text"
                label="Telefone"
                spellCheck="false"
                defaultValue={empresa.telefone}
                textMaskProps={{
                  mask: phoneMask,
                }}
              />
              <StyledButton variant="contained" type="submit">
                {loading ? (
                  <LoaderContainer>
                    <Loader
                      type="TailSpin"
                      color={defaultColors.color6}
                      height={24}
                      width={24}
                    />
                  </LoaderContainer>
                ) : (
                  'Gravar'
                )}
              </StyledButton>
            </StyledForm>
          </EmpresaInfo>
        )
      )}
    </>
  );
}
