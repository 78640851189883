import produce from 'immer';
import { Types } from './types';
import { Types as coletasTypes } from '../coletas/types';

const INITIAL_STATE = {
  pedidos: [],
  count: 0,
  loading: false,
};

export default function pedidos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_PEDIDOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_PEDIDOS_SUCCESS: {
        draft.pedidos = action.payload.pedidos;
        draft.count = action.payload.count;
        draft.loading = false;
        break;
      }
      case coletasTypes.ADD_ITEM_COLETA_SUCCESS: {
        draft.pedidos = draft.pedidos.filter(
          pedido => pedido.id !== action.payload.cargaItemId
        );
        break;
      }
      case Types.UPDATE_PEDIDO_SOCKET: {
        const { pedido } = action.payload;
        const index = draft.pedidos.findIndex(item => item.id === pedido.id);
        draft.pedidos[index] = pedido;
        break;
      }
      case Types.GET_PEDIDOS_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
