import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormTextField from '../../../../components/Unform/FormTextField';
import FormSelectWrapper from '../../../../components/Unform/FormSelectWrapper';
import Modal from '../../../../components/ModalEdit';
import ComboboxTipoVeiculos from '../../../../components/Combobox/TiposVeiculos';

import { placa } from '../../../../consts/regex';
import {
  comboboxInteger,
  localeNumber,
} from '../../../../services/yupTransforms';

import {
  createVeiculoRequest,
  updateVeiculoRequest,
} from '../../../../store/modules/transportadora/veiculos/actions';

import { numberTextMaskProps, unmask } from '../../../../services/masks';

import Docto from '../../../../assets/images/document.png';

import { Container, DoctoInput } from './styles';

const schema = Yup.object().shape({
  placa: Yup.string()
    .matches(placa.regex, 'Placa inválida')
    .required('Placa deve ser informada'),
  tipoVeiculoId: Yup.number()
    .transform(comboboxInteger)
    .required('Tipo deve ser informado'),
  doctoId: Yup.number(),
  antt: Yup.string(),
  tara: Yup.number()
    .typeError('Informe um número válido')
    .min(100, 'Tara deve ser no mínimo de 100')
    .transform(localeNumber),
});

export default function FormEdit({ veiculo, onClose }) {
  const dispatch = useDispatch();
  const [editVeiculo, setEditVeiculo] = useState(veiculo);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsEditing(!!(editVeiculo && editVeiculo.id));
  }, [editVeiculo]);

  function handleCancel() {
    setEditVeiculo(null);
    onClose();
  }

  function handleSave(veiculoData) {
    veiculoData.placa = unmask(veiculoData.placa);
    if (!isEditing) {
      dispatch(createVeiculoRequest(veiculoData));
    } else {
      veiculoData.id = editVeiculo.id;
      dispatch(updateVeiculoRequest(veiculoData));
    }
    setEditVeiculo(null);
    onClose();
  }

  return (
    <Modal
      open={!!editVeiculo}
      title="Veículo"
      schema={schema}
      fieldsComponent={
        editVeiculo && (
          <Container>
            <FormTextField
              className="field"
              name="placa"
              type="text"
              label="Placa"
              spellCheck="false"
              textMaskProps={{
                mask: placa.array,
                pipe: conformedValue => conformedValue.toUpperCase(),
              }}
              autoFocus={!isEditing}
              defaultValue={editVeiculo.placa}
            />
            <FormTextField
              className="field"
              name="antt"
              type="text"
              label="Antt"
              spellCheck="false"
              defaultValue={editVeiculo.antt}
            />
            <FormTextField
              className="field"
              name="tara"
              type="text"
              label="tara"
              textMaskProps={numberTextMaskProps(2)}
              defaultValue={editVeiculo.tara}
            />
            <FormSelectWrapper name="tipoVeiculoId" className="field">
              <ComboboxTipoVeiculos
                value={
                  editVeiculo.tipo ? String(editVeiculo.tipo.id) : undefined
                }
              />
            </FormSelectWrapper>
            <DoctoInput
              className="field"
              name="doctoId"
              url={editVeiculo.docto && editVeiculo.docto.url}
              altImage={Docto}
              labelText="Documento do Veículo"
              defaultValue={editVeiculo.docto && editVeiculo.docto.id}
            />
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  veiculo: PropTypes.shape({
    placa: PropTypes.string,
    tipo: PropTypes.shape({ id: PropTypes.number }),
    docto: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
    antt: PropTypes.string,
    tara: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
