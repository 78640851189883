import produce from 'immer';

import { Types as AuthTypes } from '../auth/types';
import { Types } from './types';

const INITIAL_STATE = {
  profile: null,
  loading: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.UPDATE_USUARIO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.UPDATE_USUARIO_SUCCESS:
      case AuthTypes.SIGN_IN_SUCCESS: {
        draft.profile = action.payload.usuario;
        draft.loading = false;
        break;
      }
      case AuthTypes.SIGN_OUT: {
        draft.profile = null;
        break;
      }
      case Types.UPDATE_USUARIO_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
