import { combineReducers } from 'redux';

import pedidos from './pedidos/reducer';
import coletas from './coletas/reducer';
import motoristas from './motoristas/reducer';
import veiculos from './veiculos/reducer';

export default combineReducers({
  pedidos,
  coletas,
  motoristas,
  veiculos,
});
