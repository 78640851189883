import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useSelector } from 'react-redux';

import formatter from '../../services/formatter';

import Medias from '../../consts/medias';

import {
  Container,
  LeftPanel,
  RightPanel,
  Info,
  ButtonContainer,
  StyledButton,
  InfoContainer,
} from './styles';

export default function StatusColeta({
  buttonText,
  handleButtonClick,
  buttonDisabled,
}) {
  const sm = useMediaQuery(Medias.sm);

  const coletaSelecionada = useSelector(
    state => state.transportadora.coletas.selecionada
  );
  const show = useSelector(
    state => state.transportadora.coletas.showStatusColeta
  );

  function renderStatusColeta() {
    return (
      <Container show={show}>
        <InfoContainer>
          <LeftPanel>
            <Info>
              <p>Quantidade</p>
              <span>{formatter(coletaSelecionada.quantidade).toM2()}</span>
            </Info>
          </LeftPanel>
          <RightPanel>
            <Info light>
              <p>Peso Não Carregado</p>
              <span>{formatter(coletaSelecionada.saldo.peso).toKg()}</span>
            </Info>
            <Info>
              <p>Peso Carregado</p>
              <span>{formatter(coletaSelecionada.peso).toKg()}</span>
            </Info>
            {!sm && (
              <ButtonContainer>
                <StyledButton
                  type="button"
                  variant="contained"
                  onClick={handleButtonClick}
                  disabled={buttonDisabled}
                >
                  {buttonText}
                </StyledButton>
              </ButtonContainer>
            )}
          </RightPanel>
        </InfoContainer>
        {sm && (
          <ButtonContainer>
            <StyledButton
              type="button"
              variant="contained"
              onClick={handleButtonClick}
              disabled={buttonDisabled}
            >
              {buttonText}
            </StyledButton>
          </ButtonContainer>
        )}
      </Container>
    );
  }

  return <>{coletaSelecionada && renderStatusColeta()}</>;
}

StatusColeta.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool,
};

StatusColeta.defaultProps = {
  buttonDisabled: false,
};
