import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  tiposVeiculos: [],
  loading: false,
};

export default function tiposVeiculos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_TIPOSVEICULOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_TIPOSVEICULOS_SUCCESS: {
        draft.tiposVeiculos = action.payload.tiposVeiculos;
        draft.loading = false;
        break;
      }
      case Types.GET_TIPOSVEICULOS_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
