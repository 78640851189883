import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import Loader from 'react-loader-spinner';

import ArrowIcon from '../../assets/images/arrow.svg';
import ContentPage from '../../components/ContentPage';
import FormTextField from '../../components/Unform/FormTextField';
import Avatar from '../../assets/images/avatar.png';

import { unmask, phoneMask } from '../../services/masks';
import history from '../../services/history';
import { validaCpf } from '../../services/validators';

import { cpf, telefoneCelular, telefoneFixo } from '../../consts/regex';

import { defaultColors } from '../../styles/colors';

import { updateUsuarioRequest } from '../../store/modules/user/actions';

import {
  Header,
  ContentContainer,
  LoaderContainer,
  UsuarioInfo,
  StyledForm,
  StyledButton,
  AvatarInput,
  Separator,
} from './styles';

const schema = Yup.object().shape({
  avatarId: Yup.number(),
  nome: Yup.string().required('Nome deve ser informado'),
  cpf: Yup.string().test(
    'validaCPF',
    'CPF inválido',
    value => !value || validaCpf(value)
  ),
  telefone: Yup.string().test('Telefone', 'Telefone inválido', telefone => {
    if (!telefone) return true;

    if (unmask(telefone).length > 10) {
      return telefoneCelular.regex.test(telefone);
    }
    return telefoneFixo.regex.test(telefone);
  }),
  senha: Yup.string()
    .transform(value => (!value ? null : value))
    .min(6, 'Senha deve ter no mínimo 6 caracteres')
    .nullable(),
  oldSenha: Yup.string()
    .when('senha', (senha, field) =>
      senha ? field.required('Senha atual deve ser informada') : field
    )
    .nullable(),
  confirmSenha: Yup.string()
    .when('senha', (senha, field) =>
      senha
        ? field
            .oneOf(
              [Yup.ref('senha')],
              'Confirmação da senha difere da senha informada'
            )
            .required('Confirmação da senha deve ser informada')
        : field
    )
    .nullable(),
});

export default function Usuario() {
  const dispatch = useDispatch();

  const usuario = useSelector(state => state.user.profile);
  const loading = useSelector(state => state.user.loading);

  const [oldSenha, setOldSenha] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');

  function handleSave(usuarioData) {
    if (usuarioData.cpf) usuarioData.cpf = unmask(usuarioData.cpf);
    if (usuarioData.telefone)
      usuarioData.telefone = unmask(usuarioData.telefone);

    if (!usuarioData.oldSenha) delete usuarioData.oldSenha;
    if (!usuarioData.senha) delete usuarioData.senha;
    if (!usuarioData.confirmSenha) delete usuarioData.confirmSenha;

    dispatch(updateUsuarioRequest(usuarioData));

    setOldSenha('');
    setSenha('');
    setConfirmSenha('');
  }

  return (
    <ContentPage
      headerComponent={
        <Header>
          <button type="button" onClick={() => history.goBack()}>
            <img src={ArrowIcon} alt="" />
          </button>
          Meu Perfil
        </Header>
      }
      content={
        <ContentContainer>
          <UsuarioInfo>
            <StyledForm
              id="formFinalizar"
              onSubmit={handleSave}
              autoComplete="off"
              schema={schema}
            >
              <AvatarInput
                name="avatarId"
                url={usuario.avatar && usuario.avatar.url}
                altImage={Avatar}
                defaultValue={usuario.avatar && usuario.avatar.id}
              />
              <FormTextField
                className="field"
                name="nome"
                type="text"
                label="Nome"
                spellCheck="false"
                inputProps={{ maxLength: 120 }}
                defaultValue={usuario.nome}
              />
              <FormTextField
                className="field"
                name="cpf"
                type="text"
                label="CPF"
                spellCheck="false"
                textMaskProps={{
                  mask: cpf.array,
                }}
                defaultValue={usuario.cpf}
              />
              <FormTextField
                className="field"
                name="telefone"
                type="text"
                label="Telefone"
                spellCheck="false"
                textMaskProps={{
                  mask: phoneMask,
                }}
                defaultValue={usuario.telefone}
              />
              <Separator />
              <FormTextField
                className="field"
                name="oldSenha"
                type="password"
                label="Senha Atual"
                spellCheck="false"
                value={oldSenha}
                onChange={e => setOldSenha(e.target.value)}
              />
              <FormTextField
                className="field"
                name="senha"
                type="password"
                label="Nova Senha"
                spellCheck="false"
                value={senha}
                onChange={e => setSenha(e.target.value)}
              />
              <FormTextField
                className="field"
                name="confirmSenha"
                type="password"
                label="Confirmação da Nova Senha"
                spellCheck="false"
                value={confirmSenha}
                onChange={e => setConfirmSenha(e.target.value)}
              />
              <StyledButton variant="contained" type="submit">
                {loading ? (
                  <LoaderContainer>
                    <Loader
                      type="TailSpin"
                      color={defaultColors.color6}
                      height={24}
                      width={24}
                    />
                  </LoaderContainer>
                ) : (
                  'Gravar'
                )}
              </StyledButton>
            </StyledForm>
          </UsuarioInfo>
        </ContentContainer>
      }
    />
  );
}
