import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { colors } from '../../styles/colors';

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 15px;
  }

  color: ${colors.field.text};
  &:hover {
    color: ${colors.link.active};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${colors.checkBox.checked};
`;
