import styled, { css } from 'styled-components';
import { Form } from '@rocketseat/unform';
import IconButton from '@material-ui/core/IconButton';

import FormDatePicker from '../../../components/Unform/FormDatePicker';
import Button from '../../../components/Button';

import { defaultColors } from '../../../styles/colors';

export const StyledDatePicker = styled(FormDatePicker)`
  margin: 0;
  .MuiInputBase-root {
    background: rgba(255, 255, 255, 0.6);
  }
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  > * {
    :not(:last-child) {
      margin-right: 24px !important;
    }
  }
`;

export const SearchButton = styled(Button)`
  margin-left: 24px;
  min-height: 42px;
  min-width: 42px;
  border-radius: 50%;
`;

export const FilterButton = styled(IconButton)`
  margin-right: 24px;
  svg {
    ${props =>
      props.active &&
      css`
        color: ${defaultColors.color6};
      `};
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
