import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import history from '../../../../services/history';

import ContentPage from '../../../../components/ContentPage';
import StatusColeta from '../../../../components/StatusColeta';
import ResumoColeta from '../../../../components/ResumoColeta';
import FormTextField from '../../../../components/Unform/FormTextField';
import DatePicker from '../../../../components/Unform/FormDatePicker';
import FormSelectWrapper from '../../../../components/Unform/FormSelectWrapper';

import api from '../../../../services/api';
import ApiEndPoints from '../../../../consts/apiEndpoints';

import { finalizaColetaRequest } from '../../../../store/modules/transportadora/coletas/actions';
import { getDatasRequest } from '../../../../store/modules/comuns/datasDisponiveis/actions';

import {
  Header,
  ContentContainer,
  ColetaInfo,
  StyledForm,
  Datas,
  StyledComboboxMotoristas,
  StyledComboboxVeiculos,
} from './styles';

import PATHS from '../../../../consts/paths';
import formatter from '../../../../services/formatter';
import ArrowIcon from '../../../../assets/images/arrow.svg';

export default function ColetasFinalizar() {
  const dispatch = useDispatch();
  const coletaSelecionada = useSelector(
    (state) => state.transportadora.coletas.selecionada
  );

  const temPonta =
    coletaSelecionada &&
    coletaSelecionada.itens.filter((i) => i.caixas > 0).length > 0;

  const datasDisponiveis = useSelector(
    (state) => state.comuns.datasDisponiveis.datas
  );

  const availableDates = datasDisponiveis.map((d) =>
    formatter(d.data).localeDateOnlyToISO()
  );

  useEffect(() => {
    dispatch(getDatasRequest());
  }, [dispatch]);

  const [dataInicial, setdataInicial] = useState(new Date());
  const [dataFinal, setdataFinal] = useState(new Date());

  const [feriados, setFeriados] = useState([]);

  const [dataColeta, setDataColeta] = useState(
    coletaSelecionada && coletaSelecionada.dataPrevisaoColeta
      ? formatter(coletaSelecionada.dataPrevisaoColeta).dateOnlyToISO()
      : new Date()
  );

  useEffect(() => {
    api
      .get(ApiEndPoints.comuns.feriados)
      .then((response) => {
        const feriadosDatas = response.data.map((feriado) =>
          formatter(feriado.date).dateOnlyToISO()
        );
        setFeriados(feriadosDatas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const { veiculo } = { ...coletaSelecionada };
    const { id } = { ...coletaSelecionada };

    veiculo && setVeiculoId(veiculo.id);

    id &&
      api
        .post(ApiEndPoints.transportadora.datas(id))
        .then((response) => {
          const { datai, dataf } = response.data;
          setDataColeta(formatter(datai).dateOnlyToISO());
          setdataInicial(formatter(datai).dateOnlyToISO());
          setdataFinal(formatter(dataf).dateOnlyToISO());
        })
        .catch((error) => {
          console.log(error);
        });
  }, [coletaSelecionada]);

  const [veiculoId, setVeiculoId] = useState(0);
  function handleComboboxVeiculoChange(value) {
    setVeiculoId(value);
  }

  const [pesoLiquido, setpesoLiquido] = useState(0);
  const [pesoDisponivel, setpesoDisponivel] = useState(0);

  useEffect(() => {
    if (veiculoId > 0) {
      api
        .get(`${ApiEndPoints.transportadora.veiculos}/${veiculoId}`)
        .then((response) => {
          const pesoLiquidoVeiculo = response.data.pesoLiquido || 0;

          setpesoLiquido(pesoLiquidoVeiculo);
          setpesoDisponivel(pesoLiquidoVeiculo - coletaSelecionada.peso);
        })
        .catch((error) => {
          setpesoLiquido(0);
          setpesoDisponivel(0);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veiculoId, dispatch]);

  const handleSubmit = (data) => {
    data.dataPrevisaoColeta = formatter(
      data.dataPrevisaoColeta
    ).localeDateOnlyToISO();
    dispatch(finalizaColetaRequest(coletaSelecionada.id, { ...data }));
  };

  function shouldDisableDate(date) {
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );

    return availableDates.findIndex((item) => +item === +date) !== -1 ||
      (!temPonta &&
        formatter(date).toDayOnlyISO() === formatter(today).toDayOnlyISO())
      ? false
      : date > dataFinal ||
          date < dataInicial ||
          formatter(date).dateOnlyToISO().getDay() === 0 ||
          feriados.findIndex((item) => +item === +date) !== -1;
  }

  const handleKeypress = (e) => {
    e.preventDefault();
    return false;
  };

  return coletaSelecionada ? (
    <>
      <ContentPage
        headerComponent={
          <Header>
            <button
              type="button"
              onClick={() =>
                history.push(PATHS.transportadora.coletasEmAndamento)
              }
            >
              <img src={ArrowIcon} alt="" />
            </button>
            Finalizar Coleta
          </Header>
        }
        content={
          <ContentContainer>
            <ColetaInfo>
              <StyledForm
                id="formFinalizar"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <FormSelectWrapper name="veiculoId">
                  <StyledComboboxVeiculos
                    value={
                      coletaSelecionada.veiculo
                        ? String(coletaSelecionada.veiculo.id)
                        : undefined
                    }
                    handleChange={handleComboboxVeiculoChange}
                  />
                </FormSelectWrapper>
                <FormSelectWrapper name="motoristaId">
                  <StyledComboboxMotoristas
                    value={
                      coletaSelecionada.motorista
                        ? String(coletaSelecionada.motorista.id)
                        : undefined
                    }
                  />
                </FormSelectWrapper>

                <Datas>
                  <DatePicker
                    className="data"
                    name="dataPrevisaoColeta"
                    value={dataColeta}
                    onChange={(date) => setDataColeta(date)}
                    shouldDisableDate={shouldDisableDate}
                    disablePast="true"
                    label="Data Prevista da Coleta"
                    onKeyPress={handleKeypress}
                    autoOk
                  />
                </Datas>
                <FormTextField
                  className="field"
                  name="observacao"
                  type="text"
                  label="Observações"
                  defaultValue={coletaSelecionada.observacao}
                />
                <button type="submit" id="buttonFinalizar" />

                <ResumoColeta
                  valuePC={coletaSelecionada.peso}
                  valuePLC={pesoLiquido}
                  valueD={pesoDisponivel}
                />
              </StyledForm>
            </ColetaInfo>
          </ContentContainer>
        }
      />

      <StatusColeta
        buttonText="Finalizar Coleta"
        handleButtonClick={() =>
          document.getElementById('buttonFinalizar').click()
        }
      />
    </>
  ) : (
    <Redirect to={PATHS.transportadora.coletasEmAndamento} />
  );
}
