import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Container,
  ImageContainer,
  SignUpContainer,
  SignUpForm,
  Group,
  Buttons,
  StyledButton,
  StyledLogo,
} from './styles';

import FormTextField from '../../components/Unform/FormTextField';

import Image from '../../assets/images/login-truck.png';

import Medias from '../../consts/medias';

import { resetSenhaRequest } from '../../store/modules/auth/actions';

const schema = Yup.object().shape({
  senha: Yup.string()
    .min(6, 'Senha deve ter no mínimo 6 caracteres')
    .required('Senha deve ser informada'),
  confirmSenha: Yup.string()
    .oneOf([Yup.ref('senha')], 'Confirmação da senha difere da senha informada')
    .required('Confirmação da senha deve ser informada'),
});

export default function ResetSenhaPage({ match }) {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = data => {
    const { hash } = match.params;
    const { senha, confirmSenha } = data;
    if (data)
      dispatch(
        resetSenhaRequest(senha, confirmSenha, decodeURIComponent(hash))
      );
  };

  return (
    <Container>
      <Group>
        {!sm && (
          <ImageContainer>
            <img src={Image} alt="Imagem de Caminhão" />
          </ImageContainer>
        )}
        <SignUpContainer>
          <StyledLogo />
          {!sm && <p className="subtitle">Informe sua nova senha.</p>}
          <SignUpForm
            schema={schema}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <FormTextField
              className="field"
              name="senha"
              type="password"
              label="Senha"
              required
              spellCheck="false"
            />
            <FormTextField
              className="field"
              name="confirmSenha"
              type="password"
              label="Confirmação da Senha"
              required
              spellCheck="false"
            />
            <Buttons>
              <StyledButton variant="contained" type="submit">
                {loading ? 'Carregando...' : 'Enviar'}
              </StyledButton>
            </Buttons>
          </SignUpForm>
          <p className="copyright">
            Copyright © 2019 Ecosis. Todos os direitos reservados.
          </p>
        </SignUpContainer>
      </Group>
    </Container>
  );
}

ResetSenhaPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      hash: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
