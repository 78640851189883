import styled from 'styled-components';
import { Form } from '@rocketseat/unform';
import Button from '../../components/Button';
import Paper from '../../components/Paper';
import ImageInput from '../../components/ImageInput';

import Medias from '../../consts/medias';

import { defaultColors, colors } from '../../styles/colors';

export const Header = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    padding: 6px 6px 6px 0;
    background: none;
    border: 0;
    img {
      transform: rotate(90deg);
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  padding: 20px 12px;
  width: 100%;
  background: ${colors.pedidos.background};
`;

export const UsuarioInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 12px;
  width: 100%;
  min-height: 50%;

  @media ${Medias.sm} {
    padding: 0;
  }
`;

export const StyledForm = styled(Form)`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .field {
    margin: 15px 0;
  }

  @media ${Medias.sm} {
    min-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  height: 50px;
  width: 100%;
  font-size: 18px;

  @media ${Medias.sm} {
    height: 36px;
    margin: 10px 0;
  }
`;

export const AvatarInput = styled(ImageInput)`
  img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
`;

export const Separator = styled.div`
  margin-top: 15px;
  height: 2px;
  background: ${defaultColors.color6};
`;
