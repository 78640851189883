import React from 'react';
import PeopleIcon from '@material-ui/icons/People';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import MenuItem from '../../MenuItem';
import PATHS from '../../../consts/paths';
import { StyledDivider } from '../styles';

import { ReactComponent as IconPedidos } from '../../../assets/images/menu/chart-bar-32.svg';
import { ReactComponent as IconAndamento } from '../../../assets/images/menu/truck.svg';
import { ReactComponent as IconEmitidas } from '../../../assets/images/menu/email-84.svg';

export default function TransportadoraMenuItems() {
  return (
    <div>
      <MenuItem
        Icon={IconPedidos}
        text="Pedidos"
        path={PATHS.transportadora.pedidos}
      />
      <MenuItem
        Icon={IconAndamento}
        text="Coletas em Andamento"
        path={PATHS.transportadora.coletasEmAndamento}
      />
      <MenuItem
        Icon={IconEmitidas}
        text="Coletas Emitidas"
        path={PATHS.transportadora.coletasEmitidas}
      />
      <StyledDivider />
      <MenuItem
        Icon={PeopleIcon}
        text="Motoristas"
        path={PATHS.transportadora.motoristas}
      />
      <MenuItem
        Icon={LocalShippingIcon}
        text="Veículos"
        path={PATHS.transportadora.veiculos}
      />
      <StyledDivider />
    </div>
  );
}
