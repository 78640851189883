import React from 'react';
import PropTypes from 'prop-types';

import {    
    Container,
    Infos,
    Title,
    Info,
    StyledErrorTwoToneIcon
} from './styles';

import formatter from '../../services/formatter';

export default function ResumoColeta({
  valuePC,
  valuePLC,
  valueD
}) {
  
  function renderResumoColeta() {
    return (
        <>
        <Container>
        <Infos>
          <Title>Peso Carregado</Title>
          <Info id='idxPC' colortype="Normal">{formatter(valuePC).toKg()}</Info>
        </Infos>
        </Container>
        <Container>
        <Infos>
          <Title>Peso Líquido do Caminhão</Title>
          <Info id='idxPLC' colortype="Normal">{formatter(valuePLC).toKg()}</Info>
        </Infos>
        </Container>        
        <Container>
        <Infos>
          <Title>Disponível</Title>
          <Info id='idxD' colortype={ valueD >= 0 ? "Normal" : "Aviso"}>{formatter(valueD).toKg()}
          <StyledErrorTwoToneIcon colortype={ valueD >= 0 ? "Normal" : "Aviso"}></StyledErrorTwoToneIcon>          
          </Info>
        </Infos>
        </Container> 
        </>
    );
  }

  return <> {renderResumoColeta()} </>;
}

ResumoColeta.propTypes = {
  valuePC: PropTypes.number.isRequired,
  valuePLC: PropTypes.number.isRequired,
  valueD: PropTypes.number.isRequired,
};

ResumoColeta.defaultProps = {
  buttonDisabled: false,
};
