import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { isValid, format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getPedidosRequest } from '../../../store/modules/cliente/pedidos/actions';
import formatter, { toPalletECaixa } from '../../../services/formatter';
import { downloadObjectAsJson } from '../../../services/download';

import Table from '../../Table';
import { MTableBodyRow } from 'material-table';
import { Avatar, Tooltip } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Container, InfoColetas, ColetaChip, Footer, StyledButton } from './styles';

function TablePedidos({ dataInicial, dataFinal, filtering }) {

  const dispatch = useDispatch()
  const { loading, pedidos } = useSelector(state => state.cliente.pedidos)

  const [columns, setColumns] = useState([
    { title: 'PEDIDO', field: 'pedido', sortBy: 'documento_numero' },
    { title: 'PRODUTO', field: 'produto', sortBy: 'produto_descricao' },
    { title: 'CLIENTE', field: 'cliente', sortBy: 'cliente_razao_social' },
    { title: 'CIDADE', field: 'cidade', sortBy: 'cliente_cidade' },
    { title: 'UF', field: 'uf', sortBy: 'cliente_uf' },
    { title: 'SALDO', field: 'saldo', filtering: false, sortBy: 'quantidade_total' },
    { title: 'LIBERADO', field: 'liberado', filtering: false, sortBy: 'saldo_quantidade' },
    { title: 'PESO', field: 'peso', filtering: false, sortBy: 'saldo_peso' },
    { title: 'EMBALAGEM', field: 'embalagem', filtering: false, sorting: false },
  ])

  useEffect(() => {
    if (isValid(dataInicial) && isValid(dataFinal))
      dispatch(
        getPedidosRequest(
          dataInicial.toISOString(),
          dataFinal.toISOString(),
        )
      )
  }, [dataInicial, dataFinal, dispatch])

  function handleChangeGroup(groupColumns) {
    const oldColumns = columns.map(c => ({ title: c.title, field: c.field }));
    const newColumns = [...oldColumns];

    groupColumns.forEach((groupColumn, index) => {
      const column = newColumns.find(c => c.field === groupColumn.field);
      if (column) column.defaultGroupOrder = index;
    })

    if (JSON.stringify(oldColumns) !== JSON.stringify(newColumns))
      setColumns(newColumns);
  }

  function handleDownloadOrders() {
    const fileName = 'pedidos-publicados-portal-' + `${format(dataInicial, 'dd/MM/yyyy')}-${format(dataFinal, 'dd/MM/yyyy')}`
    downloadObjectAsJson(pedidos, fileName)
  }

  return (
    <Container>
      <Table
        columns={columns}
        data={pedidos.map(item => ({
          id: item.id,
          uf: item.clienteDestino.uf,
          cidade: item.clienteDestino.cidade,
          cliente: item.clienteDestino.razaoSocial,
          pedido: `${item.documento.numero}/${item.documento.item}`,
          produto: item.produto,
          saldo: formatter(item.quantidadeTotal).toM2(),
          liberado: formatter(item.saldo.quantidade).toM2(),
          peso: formatter(item.saldo.peso).toKg(),
          embalagem: toPalletECaixa(item.saldo.paletes, item.saldo.caixas),
          coletas: item.coletas,
        }))}
        components={{
          Row: rowProps => {
            const { data } = rowProps;
            return (
              <>
                <MTableBodyRow key={String(data.id)} {...rowProps} />
                {data.coletas.length > 0 && (
                  <InfoColetas>
                    <td colSpan="11">
                      {data.coletas.map(coleta => (
                        <Tooltip
                          key={String(coleta.id)}
                          title={
                            coleta.dataFinalizada
                              ? 'Finalizada'
                              : 'Em Andamento'
                          }
                        >
                          <ColetaChip
                            key={String(coleta.id)}
                            label={`${coleta.transportadora.nomeFantasia} - Coleta ${coleta.id}`}
                            avatar={
                              <Avatar>
                                {coleta.dataFinalizada ? 'F' : 'A'}
                              </Avatar>
                            }
                            finalizada={coleta.dataFinalizada ? 1 : 0}
                          />
                        </Tooltip>
                      ))}
                    </td>
                  </InfoColetas>
                )}
              </>
            )
          },
        }}
        options={{
          toolbar: false,
          grouping: true,
          filtering,
          rowStyle: rowData => ({
            backgroundColor: rowData.coletas.length > 0 && 'LightYellow ',
          }),
        }}
        isLoading={loading}
        onChangeGroup={handleChangeGroup}
        autoSizing
      />

      <Footer>
        <Tooltip title="Clique para fazer o download dos pedidos">
          <StyledButton
            variant='outlined'
            type='button'
            endIcon={<ExpandMore />}
            onClick={handleDownloadOrders}
            disabled={loading || pedidos.length === 0}
          >
            JSON
          </StyledButton>
        </Tooltip>
      </Footer>
    </Container>
  );
}

TablePedidos.propTypes = {
  dataInicial: PropTypes.instanceOf(Date).isRequired,
  dataFinal: PropTypes.instanceOf(Date).isRequired,
  filtering: PropTypes.bool,
};

TablePedidos.defaultProps = {
  filtering: false,
};

export default memo(TablePedidos);
