import { rgba, lighten, darken } from 'polished';

export const defaultColors = {
  color1: '#FFFFFF',
  color2: '#43425D',
  color3: '#E9E9F0',
  color4: '#4D4F5C',
  color5: '#808495',
  color6: '#a3a0fb',
  color7: '#a5a4bf',
  color8: '#FF6A6A',
  color9: '#EBEBF2',
  color10: '#F0F0F7',
  color11: '#3B3B53',
  color12: '#A3A6B4',
  color13: '#F5F6FA',
  color14: '#515465',
};

export const colors = {
  background: defaultColors.color1,
  field: {
    text: defaultColors.color2,
    placeholder: defaultColors.color7,
    border: defaultColors.color3,
    active: defaultColors.color6,
    hover: defaultColors.color2,
    label: {
      active: defaultColors.color2,
    },
  },
  checkBox: {
    checked: defaultColors.color6,
    border: defaultColors.color5,
    active: defaultColors.color3,
    text: defaultColors.color2,
  },
  text: defaultColors.color5,
  link: {
    active: defaultColors.color5,
  },
  button: {
    filled: {
      background: defaultColors.color2,
      text: defaultColors.color1,
      hover: lighten(0.12, defaultColors.color2),
      active: lighten(0.3, defaultColors.color2),
    },
    outlined: {
      background: 'inherit',
      border: defaultColors.color2,
      text: defaultColors.color4,
      hover: lighten(0.6, defaultColors.color4),
      active: lighten(0.3, defaultColors.color4),
    },
  },
  copyright: defaultColors.color2,
  menu: {
    top: defaultColors.color2,
    background: defaultColors.color2,
    item: {
      active: defaultColors.color6,
      text: defaultColors.color1,
    },
  },
  notification: {
    badge: defaultColors.color8,
  },
  divider: defaultColors.color9,
  avatar: {
    text: defaultColors.color2,
  },
  page: {
    background: defaultColors.color10,
    header: defaultColors.color2,
  },
  combobox: {
    text: defaultColors.color4,
    info: defaultColors.color2,
    backgroundAlt: defaultColors.color3,
    button: {
      text: defaultColors.color3,
      background: defaultColors.color11,
    },
    border: {
      default: defaultColors.color12,
      focused: defaultColors.color6,
      hover: darken(0.2, defaultColors.color6),
    },
  },
  pedidos: {
    background: defaultColors.color1,
    title: defaultColors.color4,
    subtitle: defaultColors.color4,
    info: {
      background: defaultColors.color3,
      chip: {
        background: defaultColors.color2,
        color: defaultColors.color1,
      },
    },
  },
  table: {
    header: {
      color: defaultColors.color12,
      background: defaultColors.color13,
    },
    row: {
      color: defaultColors.color4,
    },
    chip: {
      background: defaultColors.color2,
      color: defaultColors.color1,
    },
  },
  form: {
    error: defaultColors.color8,
  },
  statusColeta: {
    info: {
      title: rgba(defaultColors.color2, 0.5),
      text: defaultColors.color4,
      lightText: rgba(defaultColors.color4, 0.5),
    },
    background: rgba(defaultColors.color1, 0.95),
  },
  coletas: {
    emitidas: {
      background: defaultColors.color1,
      title: defaultColors.color4,
      subtitle: defaultColors.color4,
    },
    finalizar: {
      clienteText: defaultColors.color14,
      representanteText: defaultColors.color4,
    },
  },
};
