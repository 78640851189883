import styled, { css } from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { colors } from '../../styles/colors';

const DrawerCss = expanded => css`
  width: ${expanded ? '272px' : '72px'};
  overflow-x: hidden;
  transition: width 0.5s ease;
`;

export const StyledDrawer = styled(Drawer)`
  flex-shrink: 0;
  white-space: nowrap;
  z-index: 100;

  ${props => DrawerCss(props.expanded)};

  .MuiPaper-root {
    z-index: 100;
    ${props => DrawerCss(props.expanded)};
    background: ${colors.menu.background};
  }

  .MuiListItemText-root {
    span {
      transition: opacity 0.5s ease;
      opacity: ${props => (props.expanded ? 1 : 0)};
    }
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
`;

export const StyledDivider = styled(Divider)``;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  background: rgba(0, 0, 0, 10%);
  position: relative;
  z-index: 101;

  img {
    display: block;
    max-width: 65%;
    max-height: 65%;
    width: auto;
    height: auto;
  }
`;

export const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  fill: #a5a4bf;
`;

export const StyledChevronRightIcon = styled(ChevronRightIcon)`
  fill: #a5a4bf;
`;
