import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

import Paper from '../../Paper';
import Button from '../../Button';

import { colors } from '../../../styles/colors';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background: ${colors.pedidos.background};
`;

export const InfoColetas = styled.tr`
  td {
    background-color: ${colors.pedidos.info.background};
    padding: 5px;
  }
`;

export const ColetaChip = styled(Chip)`
  background-color: ${props => (props.finalizada ? 'limegreen ' : 'gold')};
  color: ${colors.pedidos.info.chip.color};
  margin-right: 5px;

  .MuiChip-avatar {
    background-color: white;
  }
`;

export const Footer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  height: 40px;
  min-width: 100px;
  font-size: 18px;
  margin-left: 32px;
  padding: 6px;
  border-radius: 4px;
  font-weight: bold;
`;
