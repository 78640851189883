import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormTextField from '../../../../components/Unform/FormTextField';
import Modal from '../../../../components/ModalEdit';

import { createConviteRequest } from '../../../../store/modules/cliente/transportadoras/actions';

import { Container } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email deve ser informado'),
});

export default function ConviteFormEdit({ transportadora, onClose }) {
  const dispatch = useDispatch();
  const [editTransportadora, setEditTransportadora] = useState(transportadora);

  function handleCancel() {
    setEditTransportadora(null);
    onClose();
  }

  function handleSave(transportadoraData) {
    transportadoraData.id = editTransportadora.id;
    dispatch(createConviteRequest(transportadoraData));
    setEditTransportadora(null);
    onClose();
  }

  return (
    <Modal
      open={!!editTransportadora}
      title="Convite"
      schema={schema}
      fieldsComponent={
        editTransportadora && (
          <Container>
            <FormTextField
              className="field"
              name="nome"
              type="text"
              label="Nome"
              spellCheck="false"
              inputProps={{ maxLength: 120 }}
              defaultValue={editTransportadora.nomeFantasia}
              disabled
            />
            <FormTextField
              className="field"
              name="email"
              type="email"
              label="Email"
              spellCheck="false"
              defaultValue={editTransportadora.email}
              autoFocus
            />
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

ConviteFormEdit.propTypes = {
  transportadora: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
