import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContentPage from '../../../components/ContentPage';
import Table from '../../../components/Table';
import EditIcon from '../../../components/CustomIcons/EditIcon';

import formatter from '../../../services/formatter';
import Medias from '../../../consts/medias';

import FormEdit from './FormEdit';

import {
  getMotoristasRequest,
  deleteMotoristaRequest,
} from '../../../store/modules/transportadora/motoristas/actions';

import {
  StyledRemoveCircleIcon,
  TableContainer,
  StyledButton,
  StyledFab,
  StyledAddCircleOutlineIcon,
  StyledAddIcon,
} from './styles';

export default function Motoristas() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const motoristas = useSelector(
    state => state.transportadora.motoristas.motoristas
  );
  const loading = useSelector(state => state.transportadora.motoristas.loading);
  const [selectedMotorista, setSelectedMotorista] = useState(null);

  useEffect(() => {
    dispatch(getMotoristasRequest());
  }, [dispatch]);

  const [table, setTable] = useState({});
  useEffect(() => {
    function edita(motorista) {
      setSelectedMotorista(motorista);
    }

    function deleta(motoristaId) {
      dispatch(deleteMotoristaRequest(motoristaId));
    }

    setTable({
      columns: [
        { title: 'NOME', field: 'nome' },
        { title: 'CPF', field: 'cpfFormatted' },
        { title: 'TELEFONE', field: 'telefone' },
        { title: 'EMAIL', field: 'email' },
      ],
      data: motoristas.map(motorista => ({
        id: motorista.id,
        nome: motorista.nome,
        telefone: motorista.telefone,
        email: motorista.email,
        cpfFormatted: formatter(motorista.cpf).toCpf(),
        cpf: motorista.cpf,
        cnh: motorista.cnh,
      })),
      actions: [
        {
          icon: () => <EditIcon fontSize="large" />,
          tooltip: 'Editar',
          onClick: (event, rowData) => edita(rowData),
        },
        {
          icon: () => <StyledRemoveCircleIcon fontSize="large" />,
          tooltip: 'Excluir',
          onClick: (event, rowData) => deleta(rowData.id),
        },
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
        pageSize: 10,
      },
    });
  }, [dispatch, motoristas]);

  return (
    <>
      <ContentPage
        title="Motoristas"
        controls={
          !sm && (
            <StyledButton
              variant="contained"
              type="button"
              startIcon={<StyledAddCircleOutlineIcon />}
              onClick={() => setSelectedMotorista({})}
            >
              Adicionar
            </StyledButton>
          )
        }
        content={
          <TableContainer>
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              isLoading={loading}
              autoSizing
            />
          </TableContainer>
        }
      />
      {sm && (
        <StyledFab onClick={() => setSelectedMotorista({})}>
          <StyledAddIcon />
        </StyledFab>
      )}
      {selectedMotorista && (
        <FormEdit
          motorista={selectedMotorista}
          onClose={() => setSelectedMotorista(null)}
        />
      )}
    </>
  );
}
