import { format, parse, parseISO, isValid } from 'date-fns';
import { conformToMask } from 'react-text-mask';
import {
  placa,
  cpf,
  cnpj,
  telefoneCelular,
  telefoneFixo,
} from '../consts/regex';

import { unmask } from './masks';

const formats = value => ({
  toM2: () => {
    switch (typeof value) {
      case 'number':
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
      default:
        return value;
    }
  },
  toKg: () => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })} kg`;
      case 'string':
        return `${value} kg`;
      default:
        return value;
    }
  },
  toDecimal: numDigits => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR', {
          minimumFractionDigits: numDigits,
        })}`;
      default:
        return value;
    }
  },
  toPallet: () => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR')} ${
          value > 1 ? 'Pallets' : 'Pallet'
        }`;
      default:
        return value;
    }
  },
  toCaixa: () => {
    switch (typeof value) {
      case 'number':
        return `${value.toLocaleString('pt-BR')} ${
          value > 1 ? 'Caixas' : 'Caixas'
        }`;
      default:
        return value;
    }
  },
  // ISO ou yyyy-mm-dd -> dd/mm/yyyy
  toDayOnly: () => {
    switch (typeof value) {
      case 'string': {
        const parsedDate = parseISO(value);
        if (!isValid(parsedDate)) return value;
        return format(parsedDate, 'dd/MM/yyyy');
      }
      default:
        return value;
    }
  },
  toDayOnlyISO: () => {
    switch (typeof value) {
      case 'object': {
        if (!isValid(value)) return value;
        return format(value, 'yyyy-MM-dd');
      }
      default:
        return value;
    }
  },
  // yyyy-mm-dd -> ISO
  dateOnlyToISO: () => {
    switch (typeof value) {
      case 'string': {
        const parsedDate = parseISO(value);
        return parsedDate;
      }
      default:
        return value;
    }
  },
  // dd/mm/yyyy -> ISO
  localeDateOnlyToISO: () => {
    switch (typeof value) {
      case 'string': {
        const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
        return parsedDate;
      }
      default:
        return value;
    }
  },
  toCpf: () => {
    switch (typeof value) {
      case 'string': {
        return conformToMask(value, cpf.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toCNPJ: () => {
    switch (typeof value) {
      case 'string': {
        return conformToMask(value, cnpj.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toCNPJOrCPF: () => {
    switch (typeof value) {
      case 'string': {
        if (value.length > 11)
          return conformToMask(value, cnpj.array).conformedValue;
        return conformToMask(value, cpf.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toPlaca: () => {
    switch (typeof value) {
      case 'string': {
        return conformToMask(value, placa.array).conformedValue;
      }
      default:
        return value;
    }
  },
  toTelefone: () => {
    switch (typeof value) {
      case 'string': {
        if (unmask(value).length > 10)
          return conformToMask(value, telefoneCelular.array).conformedValue;

        return conformToMask(value, telefoneFixo.array).conformedValue;
      }
      default:
        return value;
    }
  },
});

const formatter = value => formats(value);

export const toPalletECaixa = (pallets, caixas) =>
  `${pallets > 0 ? formatter(pallets).toPallet() : ''}${
    pallets > 0 && caixas > 0 ? ' ' : ''
  }${caixas > 0 ? formatter(caixas).toCaixa() : ''}`;

export default formatter;
