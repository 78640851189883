import styled from 'styled-components';
import Medias from '../../../../consts/medias';
import ImageInput from '../../../../components/ImageInput';

export const Container = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 13px;

  .field {
    margin: 15px 0;
  }

  @media ${Medias.sm} {
    width: 100%;
  }
`;

export const DoctoInput = styled(ImageInput)`
  width: 100%;
  img {
    margin-top: 10px;
    width: 100%;
  }
`;
