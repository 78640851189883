import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  usuarios: [],
  convites: [],
  loading: false,
};

export default function usuarios(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_CONVITES_REQUEST:
      case Types.CREATE_CONVITE_REQUEST:
      case Types.REENVIA_CONVITE_REQUEST:
      case Types.GET_USUARIOS_REQUEST:
      case Types.UPDATE_USUARIO_REQUEST:
      case Types.ATIVA_USUARIO_REQUEST:
      case Types.DESATIVA_USUARIO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_CONVITES_SUCCESS: {
        draft.convites = action.payload.convites;
        draft.loading = false;
        break;
      }
      case Types.CREATE_CONVITE_SUCCESS: {
        draft.convites = [...state.convites, action.payload.convite];
        draft.loading = false;
        break;
      }
      case Types.REENVIA_CONVITE_SUCCESS: {
        const index = draft.convites.findIndex(
          convite => convite.id === action.payload.convite.id
        );
        draft.convites[index] = action.payload.convite;
        draft.loading = false;
        break;
      }
      case Types.GET_USUARIOS_SUCCESS: {
        draft.usuarios = action.payload.usuarios;
        draft.loading = false;
        break;
      }
      case Types.UPDATE_USUARIO_SUCCESS: {
        const index = draft.usuarios.findIndex(
          usuario => usuario.id === action.payload.usuario.id
        );
        draft.usuarios[index] = action.payload.usuario;
        draft.loading = false;
        break;
      }
      case Types.ATIVA_USUARIO_SUCCESS: {
        const index = draft.usuarios.findIndex(
          usuario => usuario.id === action.payload.usuarioId
        );
        draft.usuarios[index].ativo = true;
        draft.loading = false;
        break;
      }
      case Types.DESATIVA_USUARIO_SUCCESS: {
        const index = draft.usuarios.findIndex(
          usuario => usuario.id === action.payload.usuarioId
        );
        draft.usuarios[index].ativo = false;
        draft.loading = false;
        break;
      }
      case Types.GET_CONVITES_FAILURE:
      case Types.CREATE_CONVITE_FAILURE:
      case Types.REENVIA_CONVITE_FAILURE:
      case Types.GET_USUARIOS_FAILURE:
      case Types.UPDATE_USUARIO_FAILURE:
      case Types.ATIVA_USUARIO_FAILURE:
      case Types.DESATIVA_USUARIO_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
