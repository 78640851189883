import React from 'react';

import { StyledSvgIcon } from './styles';

export default function EditIcon(props) {
  return (
    <StyledSvgIcon {...props}>
      <path d="M22.034 11.966c0 5.524-4.476 10-10 10s-10-4.476-10-10 4.476-10 10-10 10 4.476 10 10zm0 0" />
      <path
        d="M15.445 10.105l1.545-1.547a.146.146 0 000-.218l-1.328-1.328a.146.146 0 00-.217 0l-1.548 1.546zm-2-1.095l-5.719 5.72-.686 2.031c-.05.124.078.234.201.202l2.031-.687 5.72-5.719zm0 0"
        fill="#fff"
      />
    </StyledSvgIcon>
  );
}
