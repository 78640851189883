import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  veiculos: [],
  loading: false,
};

export default function veiculos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_VEICULOS_REQUEST:
      case Types.CREATE_VEICULO_REQUEST:
      case Types.UPDATE_VEICULO_REQUEST:
      case Types.DELETE_VEICULO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_VEICULOS_SUCCESS: {
        draft.veiculos = action.payload.veiculos;
        draft.loading = false;
        break;
      }
      case Types.CREATE_VEICULO_SUCCESS: {
        draft.veiculos = [...state.veiculos, action.payload.veiculo];
        draft.loading = false;
        break;
      }
      case Types.UPDATE_VEICULO_SUCCESS: {
        const index = draft.veiculos.findIndex(
          veiculo => veiculo.id === action.payload.veiculo.id
        );
        draft.veiculos[index] = action.payload.veiculo;
        draft.loading = false;
        break;
      }
      case Types.DELETE_VEICULO_SUCCESS: {
        draft.veiculos = state.veiculos.filter(
          veiculo => veiculo.id !== action.payload.veiculoId
        );
        draft.loading = false;
        break;
      }
      case Types.GET_VEICULOS_FAILURE:
      case Types.CREATE_VEICULO_FAILURE:
      case Types.UPDATE_VEICULO_FAILURE:
      case Types.DELETE_VEICULO_FAILURE:
      case Types.DELETE_VEICULO_CANCEL: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
