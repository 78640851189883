import { Types } from './types';

export function getColetasEmAndamentoRequest() {
  return {
    type: Types.GET_COLETAS_EM_ANDAMENTO_REQUEST,
  };
}

export function getColetasEmAndamentoSuccess(coletas) {
  return {
    type: Types.GET_COLETAS_EM_ANDAMENTO_SUCCESS,
    payload: { coletas },
  };
}

export function getColetasEmAndamentoFailure() {
  return {
    type: Types.GET_COLETAS_EM_ANDAMENTO_FAILURE,
  };
}

export function getColetasEmitidasRequest(page, pageSize, confirmadas, carregadas, currentSort) {
  return {
    type: Types.GET_COLETAS_EMITIDAS_REQUEST,
    payload: { page, pageSize, confirmadas, carregadas, sortBy: currentSort.sortBy, sortType: currentSort.sortType },
  };
}

export function getColetasEmitidasSuccess(coletas, count) {
  return {
    type: Types.GET_COLETAS_EMITIDAS_SUCCESS,
    payload: { coletas, count },
  };
}

export function getColetasEmitidasFailure() {
  return {
    type: Types.GET_COLETAS_EMITIDAS_FAILURE,
  };
}

export function createColetaRequest() {
  return {
    type: Types.CREATE_COLETA_REQUEST,
  };
}

export function createColetaSuccess(newColeta, coletas) {
  return {
    type: Types.CREATE_COLETA_SUCCESS,
    payload: { newColeta, coletas },
  };
}

export function createColetaFailure() {
  return {
    type: Types.CREATE_COLETA_FAILURE,
  };
}

export function deleteColetaRequest(coletaId) {
  return {
    type: Types.DELETE_COLETA_REQUEST,
    payload: { coletaId },
  };
}

export function deleteColetaSuccess(coletas) {
  return {
    type: Types.DELETE_COLETA_SUCCESS,
    payload: { coletas },
  };
}

export function deleteColetaFailure() {
  return {
    type: Types.DELETE_COLETA_FAILURE,
  };
}

export function finalizaColetaRequest(coletaId, body) {
  return {
    type: Types.FINALIZA_COLETA_REQUEST,
    payload: { coletaId, body },
  };
}

export function finalizaColetaSuccess(coletas) {
  return {
    type: Types.FINALIZA_COLETA_SUCCESS,
    payload: { coletas },
  };
}

export function finalizaColetaFailure() {
  return {
    type: Types.FINALIZA_COLETA_FAILURE,
  };
}

export function reabreColetaRequest(coletaId) {
  return {
    type: Types.REABRE_COLETA_REQUEST,
    payload: { coletaId },
  };
}

export function reabreColetaSuccess(coletaId, coletas) {
  return {
    type: Types.REABRE_COLETA_SUCCESS,
    payload: { coletaId, coletas },
  };
}

export function reabreColetaFailure() {
  return {
    type: Types.REABRE_COLETA_FAILURE,
  };
}

export function addItemColetaRequest(coletaId, cargaItemId) {
  return {
    type: Types.ADD_ITEM_COLETA_REQUEST,
    payload: { coletaId, cargaItemId },
  };
}

export function addItemColetaSuccess(item, cargaItemId) {
  return {
    type: Types.ADD_ITEM_COLETA_SUCCESS,
    payload: { item, cargaItemId },
  };
}

export function addItemColetaFailure() {
  return {
    type: Types.ADD_ITEM_COLETA_FAILURE,
  };
}

export function updItemColetaRequest(coletaId, coletaItemId, body) {
  return {
    type: Types.UPD_ITEM_COLETA_REQUEST,
    payload: { coletaId, coletaItemId, body },
  };
}

export function updItemColetaSuccess(item) {
  return {
    type: Types.UPD_ITEM_COLETA_SUCCESS,
    payload: { item },
  };
}

export function updItemColetaFailure() {
  return {
    type: Types.UPD_ITEM_COLETA_FAILURE,
  };
}

export function delItemColetaRequest(coletaId, coletaItemId) {
  return {
    type: Types.DEL_ITEM_COLETA_REQUEST,
    payload: { coletaId, coletaItemId },
  };
}

export function delItemColetaSuccess(coletaItemId, coleta) {
  return {
    type: Types.DEL_ITEM_COLETA_SUCCESS,
    payload: { coletaItemId, coleta },
  };
}

export function delItemColetaFailure() {
  return {
    type: Types.DEL_ITEM_COLETA_FAILURE,
  };
}

export function setColetaSelecionada(coletaId) {
  return {
    type: Types.SET_COLETA_SELECIONADA,
    payload: { coletaId },
  };
}

export function showStatusColeta(show) {
  return {
    type: Types.SHOW_STATUS_COLETA,
    payload: { show },
  };
}

export function updatePedidoItemColetaFromSocket(pedido) {
  return {
    type: Types.UPDATE_ITEM_COLETA_PEDIDO_SOCKET,
    payload: { pedido },
  };
}

// SELECTOR

export const selectColetas = state => state.transportadora.coletas