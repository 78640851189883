import styled from 'styled-components';
import PersonIcon from '@material-ui/icons/Person';
import PrintIcon from '@material-ui/icons/Print';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Paper from '../../../../components/Paper';
import { colors, defaultColors } from '../../../../styles/colors';

import TableItens from './TableItens';

export const StyledPrintCircleIcon = styled(PrintIcon)`
  color: ${colors.button.outlined};
`;

export const TableContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  background: ${colors.pedidos.background};
`;

export const InfoColeta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledTableItens = styled(TableItens)`
  flex: 3;
`;

export const MotoristaIcon = styled(PersonIcon)`
  width: 38px;
  height: 38px;
  padding: 5px;
  font-size: 14px;
  border-radius: 50%;
  background: #e8e7ff;
  color: #808aff;
`;

export const CaminhaoIcon = styled(LocalShippingIcon)`
  width: 38px;
  height: 38px;
  padding: 5px;
  font-size: 14px;
  border-radius: 50%;
  background: #daf7e8;
  color: #5adc9a;
`;

export const InfoCards = styled.div`
  background: ${defaultColors.color13};
  display: flex;
  flex-direction: column;
  height: 100%;

  align-items: stretch;
  justify-content: center;

  padding: 12px;
  margin: 12px;

  > * {
    :not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;
