import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { colors } from '../../styles/colors';

import {
  StyledSelect,
  StyledMenuItem,
  TextInfo,
  Text,
  Info,
  ButtonMenuItem,
  Button,
  SearchInput,
} from './styles';

function SelectSearch({ setQuery }) {
  const inputRef = useRef();
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef.current]); // eslint-disable-line

  return (
    <ClickAwayListener onClickAway={() => null}>
      <StyledMenuItem
        style={{
          backgroundColor: '#fff',
        }}
      >
        <SearchInput
          autoComplete="off"
          placeholder="Buscar..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={e => setQuery(e.target.value)}
          onKeyDown={e => e.stopPropagation()}
          inputRef={inputRef}
        />
      </StyledMenuItem>
    </ClickAwayListener>
  );
}

export default function ComboboxInfo({
  options,
  handleChange,
  className,
  button,
  value,
  showSearch,
  ...props
}) {
  const [query, setQuery] = useState('');
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  function handleSelect(e) {
    setCurrentValue(e.target.value);
    if (handleChange) handleChange(e.target.value);
  }

  function matchQuery(option) {
    const { info } = option;
    const re = new RegExp(query, 'gi');
    return info.match(re);
  }

  function highlight(info) {
    if (query === '') return info;
    const re = new RegExp(query, 'gi');
    const result = [];
    let start = 0;
    let match;
    do {
      match = re.exec(info);
      if (match) {
        result.push({
          text: info.substring(start, match.index),
          hightlight: false,
        });
        result.push({
          text: info.substring(match.index, re.lastIndex),
          hightlight: true,
        });
        start = re.lastIndex;
      }
    } while (match);
    result.push({
      text: info.substring(start, info.length),
      hightlight: false,
    });
    return result.map(({ text, hightlight }) =>
      hightlight ? <b>{text}</b> : <>{text}</>
    );
  }

  function getBackgroundColor(color, index) {
    if (color) return color;
    if (index % 2 === 0) return null;
    return colors.combobox.backgroundAlt;
  }

  return (
    <StyledSelect
      value={currentValue}
      className={className}
      onChange={handleSelect}
      variant="outlined"
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        MenuListProps: { style: { padding: 0 } },
        onEnter: () => {
          setQuery('');
        },
        onExit: () => {
          setQuery('');
        },
        disableAutoFocusItem: true,
      }}
      {...props}
    >
      {showSearch && options.length > 1 && <SelectSearch setQuery={setQuery} />}
      {options
        .filter(option => {
          const { additionalInfo } = option;
          return (
            matchQuery(option) ||
            (additionalInfo &&
              additionalInfo.filter(a => matchQuery(a)).length > 0)
          );
        })
        .map((option, index) => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            style={{
              backgroundColor: `${getBackgroundColor(option.color, index)}`,
            }}
          >
            <TextInfo key={`TextInfo${option.value}`}>
              {option.silhueta && <img src={option.silhueta} alt="Silhueta" />}
              <Text>{option.text}</Text>
              <Info>{highlight(option.info)}</Info>
            </TextInfo>
            {option.additionalInfo &&
              option.additionalInfo.map(additionalInfo => (
                <TextInfo
                  key={`TextAdditionalInfo${option.value}${additionalInfo.info}`}
                >
                  <Text>{additionalInfo.text}</Text>
                  <Info>{highlight(additionalInfo.info)}</Info>
                </TextInfo>
              ))}
          </StyledMenuItem>
        ))}
      {button && (
        <ButtonMenuItem>
          <Button type="button" onClick={button.handleClick}>
            {button.text}
          </Button>
        </ButtonMenuItem>
      )}
    </StyledSelect>
  );
}
SelectSearch.propTypes = {
  setQuery: PropTypes.func.isRequired,
};

ComboboxInfo.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func,
  className: PropTypes.string.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSearch: PropTypes.bool,
};

ComboboxInfo.defaultProps = {
  value: '',
  button: null,
  handleChange: null,
  showSearch: true,
};
