import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import { defaultColors } from '../../../styles/colors';
import Medias from '../../../consts/medias';

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${Medias.sm} {
    width: 100%;
  }
`;

export const FilterButton = styled(IconButton)`
  margin-right: 24px;
  svg {
    ${props =>
      props.active &&
      css`
        color: ${defaultColors.color6};
      `};
  }
`;
