import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { localeNumber } from '../../../../services/yupTransforms';

import FormTextField from '../../../../components/Unform/FormTextField';
import Modal from '../../../../components/ModalEdit';

import { numberTextMaskProps } from '../../../../services/masks';

import Truck from '../../../../assets/images/truck.png';

import {
  createTipoVeiculoRequest,
  updateTipoVeiculoRequest,
} from '../../../../store/modules/ecosis/tiposVeiculos/actions';

import { Container, SilhuetaInput } from './styles';

const schema = Yup.object().shape({
  descricao: Yup.string()
    .trim()
    .min(4, 'Descrição deve possuir mais que 3 caracteres')
    .required('Descrição deve ser informada'),
  classe: Yup.string().max(4, 'Descrição deve possuir mais que 3 caracteres'),
  pesoBruto: Yup.number()
    .typeError('Informe um número válido')
    .transform(localeNumber),
  eixos: Yup.number()
    .typeError('Informe um número válido')
    .transform(localeNumber),
  codigo: Yup.number()
    .typeError('Informe um número válido')
    .transform(localeNumber),
  silhuetaId: Yup.number(),
});

export default function FormEdit({ tipoVeiculo, onClose }) {
  const dispatch = useDispatch();
  const [editTipoVeiculo, setEditTipoVeiculo] = useState(tipoVeiculo);
  const [classe, setClasse] = useState(
    editTipoVeiculo && editTipoVeiculo.classe
  );
  const [codigo, setCodigo] = useState(
    editTipoVeiculo && editTipoVeiculo.codigo
  );
  useEffect(() => {
    if (editTipoVeiculo) {
      setClasse(editTipoVeiculo.classe);
      setCodigo(editTipoVeiculo.codigo);
    }
  }, [editTipoVeiculo]);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsEditing(!!(editTipoVeiculo && editTipoVeiculo.id));
  }, [editTipoVeiculo]);

  function handleCancel() {
    setEditTipoVeiculo(null);
    onClose();
  }

  function handleSave(tipoVeiculoData) {
    if (!isEditing) {
      dispatch(createTipoVeiculoRequest(tipoVeiculoData));
    } else {
      tipoVeiculoData.id = editTipoVeiculo.id;
      dispatch(updateTipoVeiculoRequest(tipoVeiculoData));
    }
    setEditTipoVeiculo(null);
    onClose();
  }

  return (
    <Modal
      open={!!editTipoVeiculo}
      title="Tipo de Veículo"
      schema={schema}
      fieldsComponent={
        editTipoVeiculo && (
          <Container>
            <FormTextField
              className="field"
              name="descricao"
              type="text"
              label="Descrição"
              spellCheck="false"
              autoFocus={!isEditing}
              defaultValue={editTipoVeiculo.descricao}
            />
            <FormTextField
              className="field"
              name="eixos"
              type="number"
              label="Eixos"
              inputProps={{ min: 1 }}
              defaultValue={editTipoVeiculo.eixos}
            />
            <FormTextField
              className="field"
              name="classe"
              type="text"
              label="Classe"
              spellCheck="false"
              inputProps={{ maxLength: 3 }}
              value={classe}
              onChange={e =>
                setClasse(
                  e.target.value.toUpperCase().replace(/[^A-Z\d]/gi, '')
                )
              }
            />
            <FormTextField
              className="field"
              name="pesoBruto"
              type="text"
              label="Peso Bruto Total - PBT (Ton)"
              textMaskProps={numberTextMaskProps(1)}
              defaultValue={editTipoVeiculo.pesoBruto}
            />
            <FormTextField
              className="field"
              name="codigo"
              type="text"
              label="Código"
              value={codigo}
              onChange={e => setCodigo(e.target.value.replace(/[^\d]/gi, ''))}
            />
            <SilhuetaInput
              className="field"
              name="silhuetaId"
              url={editTipoVeiculo.silhueta && editTipoVeiculo.silhueta.url}
              altImage={Truck}
              labelText="Silhueta"
              defaultValue={
                editTipoVeiculo.silhueta && editTipoVeiculo.silhueta.id
              }
            />
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  tipoVeiculo: PropTypes.shape({
    descricao: PropTypes.string,
    eixos: PropTypes.number,
    classe: PropTypes.string,
    pesoBruto: PropTypes.number,
    codigo: PropTypes.number,
    silhueta: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
