export function localeNumber(value, originalValue) {
  if (this.isType(value)) return value;

  return parseFloat(originalValue.replace('.', '').replace(',', '.'));
}

export function comboboxInteger(value) {
  if (this.isType(value)) return value;

  return Number.isNaN(value) ? undefined : value;
}
