import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const Container = styled.div`
  .MuiPaper-root {
    box-shadow: none;
  }

  /* Group Chips */
  .MuiToolbar-root {
    .MuiChip-root {
      background-color: ${colors.table.chip.background};
      color: ${colors.table.chip.color};

      .MuiSvgIcon-root {
        color: white;
      }
    }
  }

  .MuiTableCell-head {
    background: ${colors.table.header.background};
    color: ${colors.table.header.color};
    font-size: 11px;
    font-weight: bold;
  }

  .MuiTableCell-body {
    color: ${colors.table.row.color};
    font-size: 13px;
  }

  .MuiInputBase-root {
    font-size: 15px;
    &::placeholder {
      color: ${colors.field.placeholder};
    }

    &:before {
      border-bottom-color: ${colors.field.border};
    }

    &:after {
      border-bottom-color: ${colors.field.active};
    }

    &.Mui-error:after {
      border-bottom-color: ${colors.form.error};
    }

    &:hover {
      &:before {
        border-bottom-color: ${colors.field.hover};
      }
    }
  }
`;
