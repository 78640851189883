export const empresa = {
  CLIENTE: 'CLIENTE',
  TRANSPORTADORA: 'TRANSPORTADORA',
  ECOSIS: 'ECOSIS',
};

export const empresaString = {
  CLIENTE: 'Cliente',
  TRANSPORTADORA: 'Transportadora',
  ECOSIS: 'ECOSIS',
};

export const usuario = {
  ADMIN: 'ADMIN',
  USUARIO: 'USUARIO',
};

export const usuarioString = {
  ADMIN: 'Administrador',
  USUARIO: 'Usuário',
};

export const statusConvite = {
  ACEITO: 'ACEITO',
  PENDENTE: 'PENDENTE',
};

export const statusConviteString = {
  ACEITO: 'Aceito',
  PENDENTE: 'Pendente',
  INEXISTENTE: 'Expirado/Não enviado',
};
