import React, { useState } from 'react';
import { isValid, subWeeks, startOfDay } from 'date-fns';
import SearchIcon from '@material-ui/icons/Search';
import ToolTip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';

import ContentPage from '../../../components/ContentPage';
import TablePedidos from '../../../components/Cliente/TablePedidos';

import formatter from '../../../services/formatter';

import {
  Controls,
  StyledDatePicker,
  SearchButton,
  FilterButton,
  StyledForm,
} from './styles';

const DT_INI = startOfDay(subWeeks(new Date(), 1));
const DT_FIM = startOfDay(new Date());

export default function PedidosPage() {
  const [search, setSearch] = useState({
    dataInicial: DT_INI,
    dataFinal: DT_FIM,
  });
  const [filtering, setFiltering] = useState(false);

  const [dataInicial, setDataInicial] = useState(DT_INI);
  const [dataFinal, setDataFinal] = useState(DT_FIM);

  const handleSubmit = data => {
    setSearch({
      dataInicial: formatter(data.dataInicial).localeDateOnlyToISO(),
      dataFinal: formatter(data.dataFinal).localeDateOnlyToISO(),
    });
  };

  return (
    <ContentPage
      title="Pedidos"
      controls={
        <StyledForm onSubmit={handleSubmit}>
          <Controls>
            <ToolTip title="Filtrar itens">
              <FilterButton
                onClick={() => setFiltering(!filtering)}
                active={filtering ? 1 : 0}
              >
                <FilterListIcon />
              </FilterButton>
            </ToolTip>
            <StyledDatePicker
              name="dataInicial"
              label="Data de Envio Inicial"
              invalidDateMessage=""
              error={!isValid(dataInicial)}
              value={dataInicial}
              onChange={setDataInicial}
            />
            <StyledDatePicker
              name="dataFinal"
              label="Data de Envio Final"
              invalidDateMessage=""
              error={!isValid(dataFinal)}
              value={dataFinal}
              onChange={setDataFinal}
            />
          </Controls>
          <SearchButton type="submit" variant="contained">
            <SearchIcon />
          </SearchButton>
        </StyledForm>
      }
      content={
        <TablePedidos
          dataInicial={search.dataInicial}
          dataFinal={search.dataFinal}
          filtering={filtering}
        />
      }
    />
  );
}
