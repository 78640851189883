import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';
import { confirmationDialog } from '../../../../components/ConfirmationDialog';

import {
  getVeiculosSuccess,
  getVeiculosFailure,
  createVeiculoSuccess,
  createVeiculoFailure,
  updateVeiculoSuccess,
  updateVeiculoFailure,
  deleteVeiculoSuccess,
  deleteVeiculoFailure,
  deleteVeiculoCancel,
} from './actions';
import { Types } from './types';

export function* getVeiculos() {
  try {
    const response = yield call(api.get, ApiEndPoints.transportadora.veiculos);

    const veiculos = response.data;

    yield put(getVeiculosSuccess(veiculos));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os veículos');
    yield put(getVeiculosFailure());
  }
}

export function* createVeiculo({ payload }) {
  try {
    const { veiculo } = payload;

    const responseTipoVeiculo = yield call(
      api.get,
      `${ApiEndPoints.transportadora.tipoVeiculos}/${veiculo.tipoVeiculoId}`
    );
    const tipoVeiculo = responseTipoVeiculo.data;

    if (veiculo.tara >= tipoVeiculo.pesoBruto * 1000) {
      toast.error('Tara deve ser menor que o peso bruto do tipo de veículo');
      yield put(updateVeiculoFailure());
    } else {
      const response = yield call(
        api.post,
        ApiEndPoints.transportadora.veiculos,
        veiculo
      );

      const newVeiculo = response.data;

      yield put(createVeiculoSuccess(newVeiculo));
      toast.success('Veículo criado com sucesso');
    }
  } catch (err) {
    trataErros(err, 'Não foi possível criar o veículo');
    yield put(createVeiculoFailure());
  }
}

export function* updateVeiculo({ payload }) {
  try {
    const { veiculo } = payload;

    const responseTipoVeiculo = yield call(
      api.get,
      `${ApiEndPoints.transportadora.tipoVeiculos}/${veiculo.tipoVeiculoId}`
    );
    const tipoVeiculo = responseTipoVeiculo.data;

    if (veiculo.tara >= tipoVeiculo.pesoBruto * 1000) {
      toast.error('Tara deve ser menor que o peso bruto do tipo de veículo');
      yield put(updateVeiculoFailure());
    } else {
      const response = yield call(
        api.put,
        `${ApiEndPoints.transportadora.veiculos}/${veiculo.id}`,
        veiculo
      );

      const updatedVeiculo = response.data;

      yield put(updateVeiculoSuccess(updatedVeiculo));
      toast.success('Veículo atualizado com sucesso');
    }
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar o veículo');
    yield put(updateVeiculoFailure());
  }
}

export function* deleteVeiculo({ payload }) {
  try {
    const { veiculoId } = payload;
    const confirm = yield call(
      confirmationDialog.open,
      'Veículos',
      'Confirma exclusão?'
    );

    if (!confirm) {
      yield put(deleteVeiculoCancel());
      confirmationDialog.close();
      return;
    }

    yield call(
      api.delete,
      `${ApiEndPoints.transportadora.veiculos}/${veiculoId}`
    );

    confirmationDialog.close();
    yield put(deleteVeiculoSuccess(veiculoId));
    toast.success('Veículo excluído com sucesso');
  } catch (err) {
    confirmationDialog.close();
    trataErros(err, 'Não foi possível excluir o veículo');
    yield put(deleteVeiculoFailure());
  }
}

export default all([
  takeLatest(Types.GET_VEICULOS_REQUEST, getVeiculos),
  takeLatest(Types.CREATE_VEICULO_REQUEST, createVeiculo),
  takeLatest(Types.UPDATE_VEICULO_REQUEST, updateVeiculo),
  takeLatest(Types.DELETE_VEICULO_REQUEST, deleteVeiculo),
]);
