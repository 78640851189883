import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Container,
  ImageContainer,
  SignUpContainer,
  SignUpForm,
  Group,
  Buttons,
  StyledButton,
  StyledLogo,
} from './styles';

import FormTextField from '../../components/Unform/FormTextField';

import Image from '../../assets/images/login-truck.png';

import Medias from '../../consts/medias';
import Paths from '../../consts/paths';

import { novaSenhaRequest } from '../../store/modules/auth/actions';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Email deve ser informado'),
});

export default function NovaSenhaPage() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = data => {
    dispatch(novaSenhaRequest(data.email));
  };

  return (
    <Container>
      <Group>
        {!sm && (
          <ImageContainer>
            <img src={Image} alt="Imagem de Caminhão" />
          </ImageContainer>
        )}
        <SignUpContainer>
          <Link to={Paths.signin}>
            <StyledLogo />
          </Link>
          {!sm && (
            <p className="subtitle">
              Informe o usuário para solicitar uma nova senha.
            </p>
          )}
          <SignUpForm
            schema={schema}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <FormTextField
              className="field"
              name="email"
              type="email"
              placeholder="Usuário"
            />
            <Buttons>
              <StyledButton variant="contained" type="submit">
                {loading ? 'Carregando...' : 'Enviar'}
              </StyledButton>
            </Buttons>
          </SignUpForm>
          <p className="copyright">
            Copyright © 2019 Ecosis. Todos os direitos reservados.
          </p>
        </SignUpContainer>
      </Group>
    </Container>
  );
}
