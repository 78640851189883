import React from 'react'
import formatter from '../../../services/formatter';
import { ReportHeader, ReportRow, ReportData, Bold } from '../styles'
import {
  CollectionContainer,
  StyledTable,
  StyledTableHead,
  StyledTableBody,
  StyledTableRow,
  StyledTableHeadCell,
  StyledTableCell
} from './styles'

export default props => {

  const coleta = props.coletaDados
  const collectionItens = coleta.itens
  const tableHeaders = ["Representante", "Pedido", "Emissão", "Nome produto", "Quantidade", "Peso/Kg"]

  const AllCustomersIds = collectionItens.map(item => (
    item.cargaItem.clienteDestino.cnpj
  ))

  const uniqueCustomersIds = AllCustomersIds.filter((elem, index, self) => {
    return index === self.indexOf(elem)
  })

  const collectionTablesByCustomer = uniqueCustomersIds.map(customerId => {

    const itemsPerCustomer = collectionItens.filter(item => {
      return item.cargaItem.clienteDestino.cnpj === customerId
    })

    const targetCustomer = itemsPerCustomer[0].cargaItem.clienteDestino

    const collectionRows = itemsPerCustomer.map(item => {

      const { cargaItem, cargaItem: { documento } } = item

      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{cargaItem.representante || ' - '}</StyledTableCell>
          <StyledTableCell align='left'>{documento.numero}</StyledTableCell>
          <StyledTableCell align='left'>{formatter(cargaItem.data).toDayOnly()}</StyledTableCell>
          <StyledTableCell align='left'>{cargaItem.produto}</StyledTableCell>
          <StyledTableCell align='right'>{formatter(item.quantidade).toDecimal(2)}</StyledTableCell>
          <StyledTableCell align='right'>{formatter(item.peso).toDecimal(2)}</StyledTableCell>
        </StyledTableRow>
      )
    })
    return (
      <React.Fragment key={customerId}>
        <ReportHeader>
          <ReportRow>
            <Bold children={`Cliente: ${targetCustomer.razaoSocial}`} />
            <ReportData>
              <Bold children="CNPJ:" />
              {formatter(targetCustomer.cnpj).toCNPJ()}
            </ReportData>
          </ReportRow>
          <ReportRow>
            <ReportData>
              <Bold children="Entrega:" />
              {[targetCustomer.cidade, targetCustomer.uf].join(' / ')}
            </ReportData>
          </ReportRow>
        </ReportHeader>
        <StyledTable size="small">
          <StyledTableHead>
            <StyledTableRow>
              {
                tableHeaders.map(header => (
                  <StyledTableHeadCell key={`header-${header}`} align={
                    header === 'Quantidade' ||
                      header === 'Peso/Kg'
                      ? 'right' : 'left'
                  }>{header}
                  </StyledTableHeadCell>
                ))
              }
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {collectionRows}
          </StyledTableBody>
        </StyledTable>
      </React.Fragment>
    )
  })

  return (
    <CollectionContainer>
      {collectionTablesByCustomer}
      <ReportRow padding={0.5}>
        <Bold>Total:</Bold>
        <Bold>{coleta.peso}</Bold>
      </ReportRow>
    </CollectionContainer>
  )
}
