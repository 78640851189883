import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import { lighten } from 'polished';
import Paper from '../Paper';

import { colors } from '../../styles/colors';

export const StyledModal = styled(Modal)``;

export const Title = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px;
  background: ${colors.page.background};
  height: 72px;

  > p {
    color: ${colors.page.header};
    font-size: 28px;
    text-align: left;
    line-height: 40px;
  }
`;

export const Container = styled(Paper)`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  padding: 6px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Message = styled.div`
  padding: 30px 13px;

  p {
    font-size: 18px;
  }
`;

export const StyledButton = styled(Button)`
  height: 46px;
  width: 100px;
  font-size: 18px;
  margin-left: 32px;
  padding: 6px;
  border-radius: 4px;
  line-height: 23px;
  text-transform: none;

  &.confirmButton {
    background: #4ad991;
    color: white;

    &:hover {
      background: ${lighten(0.12, '#4ad991')};
    }
  }

  &.cancelButton {
    background: inherit;
    color: #4d4f5c;

    &:hover {
      background: ${lighten(0.6, '#4d4f5c')};
    }
  }
`;

export const Footer = styled(Paper)`
  background: ${colors.page.background};
  padding: 6px;
`;
