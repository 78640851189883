import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt';

import { ConfirmationDialog } from './components/ConfirmationDialog';
import SignOutChecker from './components/SignOutChecker';
import { ToggledFeaturesProvider } from './components/ToggledFeatures';
import './config/ReactotronConfig';
import features from './consts/features';
import { store, persistor } from './store';

import GlobalStyle from './styles/global';
import MateriaUITheme from './styles/materialui';

import { Routes } from './routes/routes';
import history from './services/history';

function App() {
  return (
    <ToggledFeaturesProvider features={features}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <GlobalStyle />
          <ThemeProvider theme={MateriaUITheme}>
            <StylesProvider injectFirst>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                <ConfirmationDialog />
                <SignOutChecker />
                <Router history={history}>
                  <Routes />
                </Router>
                <ToastContainer
                  autoClose={10000}
                  transition={Slide}
                  position="top-right"
                />
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ToggledFeaturesProvider>
  );
}

export default App;
