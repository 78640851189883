import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';
import { confirmationDialog } from '../../../../components/ConfirmationDialog';

import {
  getUsuariosSuccess,
  getUsuariosFailure,
  createUsuarioSuccess,
  createUsuarioFailure,
  updateUsuarioSuccess,
  updateUsuarioFailure,
  deleteUsuarioSuccess,
  deleteUsuarioFailure,
  deleteUsuarioCancel,
  ativaUsuarioSuccess,
  ativaUsuarioFailure,
  desativaUsuarioSuccess,
  desativaUsuarioFailure,
} from './actions';
import { Types } from './types';

export function* getUsuarios({ payload }) {
  try {
    const { empresaId } = payload;

    const response = yield call(
      api.get,
      ApiEndPoints.ecosis.usuarios(empresaId)
    );

    const usuarios = response.data;

    yield put(getUsuariosSuccess(usuarios));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar os usuários');
    yield put(getUsuariosFailure());
  }
}

export function* createUsuario({ payload }) {
  try {
    const { empresaId, usuario } = payload;

    const response = yield call(
      api.post,
      ApiEndPoints.ecosis.usuarios(empresaId),
      usuario
    );

    const newUsuario = response.data;

    yield put(createUsuarioSuccess(newUsuario));
    toast.success('Usuário criado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível criar o usuário');
    yield put(createUsuarioFailure());
  }
}

export function* updateUsuario({ payload }) {
  try {
    const { empresaId, usuario } = payload;

    const response = yield call(
      api.put,
      `${ApiEndPoints.ecosis.usuarios(empresaId)}/${usuario.id}`,
      usuario
    );

    const updatedUsuario = response.data;

    yield put(updateUsuarioSuccess(updatedUsuario));
    toast.success('Usuário atualizado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar o usuário');
    yield put(updateUsuarioFailure());
  }
}

export function* ativaUsuario({ payload }) {
  try {
    const { empresaId, usuarioId } = payload;

    const response = yield call(
      api.post,
      `${ApiEndPoints.ecosis.usuarios(empresaId)}/${usuarioId}/ativar`
    );

    const updatedUsuario = response.data;

    yield put(ativaUsuarioSuccess(updatedUsuario.id));
    toast.success('Usuário ativado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível ativar o usuário');
    yield put(ativaUsuarioFailure());
  }
}

export function* desativaUsuario({ payload }) {
  try {
    const { empresaId, usuarioId } = payload;

    const response = yield call(
      api.post,
      `${ApiEndPoints.ecosis.usuarios(empresaId)}/${usuarioId}/desativar`
    );

    const updatedUsuario = response.data;

    yield put(desativaUsuarioSuccess(updatedUsuario.id));
    toast.success('Usuário desativado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível desativar o usuário');
    yield put(desativaUsuarioFailure());
  }
}

export function* deleteUsuario({ payload }) {
  try {
    const { empresaId, usuarioId } = payload;
    const confirm = yield call(
      confirmationDialog.open,
      'Usuários',
      'Confirma exclusão?'
    );

    if (!confirm) {
      yield put(deleteUsuarioCancel());
      confirmationDialog.close();
      return;
    }

    yield call(
      api.delete,
      `${ApiEndPoints.ecosis.usuarios(empresaId)}/${usuarioId}`
    );

    confirmationDialog.close();
    yield put(deleteUsuarioSuccess(usuarioId));
    toast.success('Usuário excluído com sucesso');
  } catch (err) {
    confirmationDialog.close();
    trataErros(err, 'Não foi possível excluir o usuário');
    yield put(deleteUsuarioFailure());
  }
}

export default all([
  takeLatest(Types.GET_USUARIOS_REQUEST, getUsuarios),
  takeLatest(Types.CREATE_USUARIO_REQUEST, createUsuario),
  takeLatest(Types.UPDATE_USUARIO_REQUEST, updateUsuario),
  takeLatest(Types.ATIVA_USUARIO_REQUEST, ativaUsuario),
  takeLatest(Types.DESATIVA_USUARIO_REQUEST, desativaUsuario),
  takeLatest(Types.DELETE_USUARIO_REQUEST, deleteUsuario),
]);
