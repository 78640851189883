import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import api from '../../services/api';
import ApiEndpoints from '../../consts/apiEndpoints';

import { Container, StyledInputLabel } from './styles';

export default function ImageInput({
  className,
  name,
  url,
  altImage,
  labelText,
  defaultValue,
}) {
  const { registerField } = useField(name);

  const [file, setFile] = useState(defaultValue);
  const [preview, setPreview] = useState(url);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name,
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [ref.current]); // eslint-disable-line

  async function handleChange(e) {
    const data = new FormData();

    data.append('arquivo', e.target.files[0]);

    const response = await api.post(ApiEndpoints.comuns.arquivos, data);

    const { id, url: previewUrl } = response.data;

    setFile(id);
    setPreview(previewUrl);
  }

  return (
    <Container className={className}>
      <StyledInputLabel htmlFor={name}>
        {labelText}
        <img src={preview || altImage} alt="" />
        <input
          type="file"
          id={name}
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </StyledInputLabel>
    </Container>
  );
}

ImageInput.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  altImage: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ImageInput.defaultProps = {
  url: null,
  labelText: null,
  defaultValue: undefined,
};
