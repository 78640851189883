import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';
import trataErros from '../../../services/trataErros';
import PATHS from '../../../consts/paths';
import ApiEndPoints from '../../../consts/apiEndpoints';

import {
  signInSuccess,
  signUpSuccess,
  signFailure,
  novaSenhaSuccess,
  resetSenhaSuccess,
} from './actions';
import { Types } from './types';

export function* signIn({ payload }) {
  try {
    const { email, senha } = payload;

    const responseAuth = yield call(api.post, ApiEndPoints.sessoes, {
      email,
      senha,
    });

    const { usuario, token } = responseAuth.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, usuario));

    history.push(PATHS.firstPath(usuario.empresa.tipo));
  } catch (err) {
    trataErros(err, 'Falha na autenticação, verifique seus dados');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { usuario, hash } = payload;

    yield call(api.post, ApiEndPoints.usuarios.root, {
      usuario,
      hash,
    });

    yield put(signUpSuccess());
    toast.success('Usuário criado com sucesso');
    history.push(PATHS.signin);
  } catch (err) {
    trataErros(err, 'Falha no registro, verifique seus dados');
    yield put(signFailure());
  }
}

export function* novaSenha({ payload }) {
  try {
    const { email } = payload;

    yield call(api.post, ApiEndPoints.novaSenha, {
      email,
    });

    yield put(novaSenhaSuccess());
    toast.success(
      'Utilize o link enviado para seu email para criar uma nova senha'
    );
    history.push(PATHS.signin);
  } catch (err) {
    trataErros(err, 'Não foi possível solicitar uma nova senha');
    yield put(signFailure());
  }
}

export function* resetSenha({ payload }) {
  try {
    const { senha, confirmSenha, hash } = payload;

    yield call(api.patch, ApiEndPoints.usuarios.resetsenha, {
      senha,
      confirmSenha,
      hash,
    });

    yield put(resetSenhaSuccess());
    toast.success('Senha alterada com sucesso');
    history.push(PATHS.signin);
  } catch (err) {
    trataErros(err, 'Não foi possível alterar a senha');
    yield put(signFailure());
  }
}

export function signOut() {
  history.push(PATHS.signin);
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
  takeLatest(Types.SIGN_UP_REQUEST, signUp),
  takeLatest(Types.NOVA_SENHA_REQUEST, novaSenha),
  takeLatest(Types.RESET_SENHA_REQUEST, resetSenha),
  takeLatest(Types.SIGN_OUT, signOut),
]);
