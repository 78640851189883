import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Header,
  ControlsContainer,
  ContentContainer,
} from './styles';

export default function ContentPage({
  className,
  title,
  controls,
  content,
  headerComponent,
}) {
  const [isHeaderOver, setIsHeaderOver] = useState(false);
  const contentRef = useRef();

  function handleScroll() {
    if (contentRef.current) {
      const { top } = contentRef.current.getBoundingClientRect();
      setIsHeaderOver(top < 0);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <Container className={className}>
      <Header className="header" isOver={isHeaderOver}>
        {headerComponent || <p>{title}</p>}
        {controls && (
          <ControlsContainer className="controls">{controls}</ControlsContainer>
        )}
      </Header>
      <ContentContainer className="content" ref={contentRef}>
        {content}
      </ContentContainer>
    </Container>
  );
}

ContentPage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  controls: PropTypes.element,
  content: PropTypes.element.isRequired,
  headerComponent: PropTypes.element,
};

ContentPage.defaultProps = {
  className: '',
  title: '',
  controls: null,
  headerComponent: null,
};
