import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContentPage from '../../../components/ContentPage';
import Table from '../../../components/Table';
import EditIcon from '../../../components/CustomIcons/EditIcon';

import FormEdit from './FormEdit';
import ConviteFormEdit from './ConviteFormEdit';

import Medias from '../../../consts/medias';

import {
  statusConvite as TiposStatusConvite,
  statusConviteString as TiposStatusConviteString,
} from '../../../consts/types';

import formatter from '../../../services/formatter';

import { getTransportadorasRequest } from '../../../store/modules/cliente/transportadoras/actions';

import {
  TableContainer,
  StyledButton,
  StyledFab,
  StyledAddCircleOutlineIcon,
  StyledAddIcon,
  StyledMailOutlineIcon,
} from './styles';

export default function Transportadoras() {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const transportadoras = useSelector(
    (state) => state.cliente.transportadoras.transportadoras
  );
  const loading = useSelector((state) => state.cliente.transportadoras.loading);
  const [selectedTransportadora, setSelectedTransportadora] = useState(null);
  const [convite, setConvite] = useState(null);

  useEffect(() => {
    dispatch(getTransportadorasRequest());
  }, [dispatch]);

  const [table, setTable] = useState({});
  useEffect(() => {
    function edita(transportadora) {
      setSelectedTransportadora(transportadora);
    }

    function renderEditarButton(transportadora) {
      // if (transportadora.statusConvite === TiposStatusConvite.ACEITO)
      //   return null;

      return {
        icon: () => <EditIcon fontSize="large" />,
        tooltip: 'Editar',
        onClick: (event, data) => edita(data),
      };
    }

    function renderEnviarConvite(transportadora) {
      if (transportadora.statusConvite === TiposStatusConvite.ACEITO)
        return null;
      return {
        icon: () => <StyledMailOutlineIcon fontSize="large" />,
        tooltip:
          transportadora.statusConvite === TiposStatusConvite.PENDENTE
            ? 'Reenviar convite'
            : 'Enviar convite',
        onClick: (event, data) => setConvite(data),
      };
    }

    setTable({
      columns: [
        { title: 'EMAIL', field: 'email' },
        { title: 'CNPJ/CPF', field: 'formattedCnpjCpf' },
        { title: 'NOME', field: 'nomeFantasia' },
        { title: 'RAZÃO SOCIAL', field: 'razaoSocial' },
        { title: 'TELEFONE', field: 'telefone' },
        { title: 'CONVITE', field: 'statusConviteFormatted' },
      ],
      data: transportadoras.map((transportadora) => ({
        id: transportadora.id,
        email: transportadora.email,
        cnpj: transportadora.cnpj,
        formattedCnpjCpf: formatter(transportadora.cnpj).toCNPJOrCPF(),
        nomeFantasia: transportadora.nomeFantasia,
        razaoSocial: transportadora.razaoSocial,
        telefone: formatter(transportadora.telefone).toTelefone(),
        statusConvite: transportadora.statusConvite,
        statusConviteFormatted:
          TiposStatusConviteString[transportadora.statusConvite],
      })),
      actions: [
        (rowData) => renderEditarButton(rowData),
        (rowData) => renderEnviarConvite(rowData),
      ],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
      },
    });
  }, [dispatch, transportadoras]);

  return (
    <>
      <ContentPage
        title="Transportadoras"
        controls={
          !sm && (
            <StyledButton
              variant="contained"
              type="button"
              startIcon={<StyledAddCircleOutlineIcon />}
              onClick={() => setSelectedTransportadora({})}
            >
              Adicionar
            </StyledButton>
          )
        }
        content={
          <TableContainer>
            <Table
              columns={table.columns}
              data={table.data}
              actions={table.actions}
              options={table.options}
              isLoading={loading}
              autoSizing
            />
          </TableContainer>
        }
      />
      {sm && (
        <StyledFab onClick={() => setSelectedTransportadora({})}>
          <StyledAddIcon />
        </StyledFab>
      )}
      {selectedTransportadora && (
        <FormEdit
          transportadora={selectedTransportadora}
          onClose={() => setSelectedTransportadora(null)}
        />
      )}
      {convite && (
        <ConviteFormEdit
          transportadora={convite}
          onClose={() => setConvite(null)}
        />
      )}
    </>
  );
}

