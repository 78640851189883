import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';
import PATHS from '../consts/paths';
import {
  empresa as TiposEmpresa,
  usuario as TiposUsuario,
} from '../consts/types';

import TransportadoraPedidosPage from '../pages/Transportadora/Pedidos';
import TransportadoraColetasAndamento from '../pages/Transportadora/Coletas/EmAndamento';
import TransportadoraColetasFinalizar from '../pages/Transportadora/Coletas/Finalizar';
import TransportadoraColetasEmitidas from '../pages/Transportadora/Coletas/Emitidas';
import TransportadoraMotoristas from '../pages/Transportadora/Motoristas';
import TransportadoraVeiculos from '../pages/Transportadora/Veiculos';

import ClientePedidosPage from '../pages/Cliente/Pedidos';
import ClienteColetasEmitidas from '../pages/Cliente/Coletas/Emitidas';
import ClienteTransportadoras from '../pages/Cliente/Transportadoras';

import EcosisEmpresasPage from '../pages/Ecosis/Empresas';
import EcosisUsuariosPage from '../pages/Ecosis/Usuarios';
import EcosisTiposVeiculos from '../pages/Ecosis/TiposVeiculos';

import AdminPage from '../pages/Admin';

import UsuarioPage from '../pages/Usuario';

export function ContentRoutes() {
  const profile = useSelector(state => state.user.profile);

  function AdminRoutes() {
    return <Route path={PATHS.admin.home} component={AdminPage} isPrivate />;
  }

  function ComumRoutes() {
    return (
      <Route path={PATHS.comum.perfil} component={UsuarioPage} isPrivate />
    );
  }

  function TransportadoraRoutes() {
    return (
      <>
        <Route
          path={PATHS.transportadora.pedidos}
          component={TransportadoraPedidosPage}
          isPrivate
        />
        <Route
          path={PATHS.transportadora.coletasEmAndamento}
          component={TransportadoraColetasAndamento}
          isPrivate
        />
        <Route
          path={PATHS.transportadora.coletasFinalizar}
          component={TransportadoraColetasFinalizar}
          isPrivate
        />
        <Route
          path={PATHS.transportadora.coletasEmitidas}
          component={TransportadoraColetasEmitidas}
          isPrivate
        />
        <Route
          path={PATHS.transportadora.motoristas}
          component={TransportadoraMotoristas}
          isPrivate
        />
        <Route
          path={PATHS.transportadora.veiculos}
          component={TransportadoraVeiculos}
          isPrivate
        />
        {profile.tipo === TiposUsuario.ADMIN && AdminRoutes()}
        {ComumRoutes()}
      </>
    );
  }

  function ClienteRoutes() {
    return (
      <>
        <Route
          path={PATHS.cliente.pedidos}
          component={ClientePedidosPage}
          isPrivate
        />
        <Route
          path={PATHS.cliente.coletasEmitidas}
          component={ClienteColetasEmitidas}
          isPrivate
        />
        <Route
          path={PATHS.cliente.transportadoras}
          component={ClienteTransportadoras}
          isPrivate
        />
        {profile.tipo === TiposUsuario.ADMIN && AdminRoutes()}
        {ComumRoutes()}
      </>
    );
  }

  function EcosisRoutes() {
    return (
      <>
        <Route
          path={PATHS.ecosis.empresas}
          component={EcosisEmpresasPage}
          isPrivate
        />
        <Route
          path={PATHS.ecosis.usuarios}
          component={EcosisUsuariosPage}
          isPrivate
        />
        <Route
          path={PATHS.ecosis.tiposVeiculos}
          component={EcosisTiposVeiculos}
          isPrivate
        />
        {profile.tipo === TiposUsuario.ADMIN && AdminRoutes()}
        {ComumRoutes()}
      </>
    );
  }

  function renderRoutes() {
    switch (profile.empresa.tipo) {
      case TiposEmpresa.CLIENTE:
        return ClienteRoutes();
      case TiposEmpresa.TRANSPORTADORA:
        return TransportadoraRoutes();
      case TiposEmpresa.ECOSIS:
        return EcosisRoutes();
      default:
        return null;
    }
  }

  return (
    profile && (
      <Switch>
        <Route
          path={PATHS.root || '/'}
          exact
          component={() => (
            <Redirect to={PATHS.firstPath(profile.empresa.tipo)} />
          )}
          isPrivate
        />
        {renderRoutes()}
      </Switch>
    )
  );
}
