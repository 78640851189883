import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';

import { colors } from '../../styles/colors';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 30px;

  label {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    img {
      border: 3px solid rgba(255, 255, 255, 0.3);
      background: #eee;
    }

    input {
      display: none;
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  color: ${colors.field.placeholder};
  &.Mui-focused {
    color: ${colors.field.label.active};
  }
`;
