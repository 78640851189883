import React, { useState, useEffect } from 'react';
import Client from '../../services/eventEmitter';

import {
  StyledModal,
  Container,
  Title,
  Message,
  Buttons,
  StyledButton,
  Footer,
} from './styles';

const client = Client;

const events = {
  open: '@portalCargas/CONFIRMATIONDIALOG/open',
  close: '@portalCargas/CONFIRMATIONDIALOG/close',
  confirm: '@portalCargas/CONFIRMATIONDIALOG/confirm',
  cancel: '@portalCargas/cancelATIONDIALOG/cancel',
};

export const confirmationDialog = {
  async open(title, message) {
    client.emit(events.open, { title, message });

    return new Promise(resolve => {
      client.once(events.confirm, () => resolve(true));
      client.once(events.cancel, () => resolve(false));
    });
  },
  close() {
    client.emit(events.close);
  },
};

export function ConfirmationDialog() {
  const [dialog, setDialog] = useState({
    open: false,
  });

  function handleOpen(options) {
    setDialog({ open: true, title: options.title, message: options.message });
  }

  function handleClose() {
    setDialog({ open: false });
  }

  function handleConfirm() {
    client.emit(events.confirm);
  }

  function handleCancel() {
    client.emit(events.cancel);
  }

  useEffect(() => {
    client.on(events.open, handleOpen);
    client.on(events.close, handleClose);

    return () => {
      client.removeEventListener(events.open, handleOpen);
      client.removeEventListener(events.close, handleClose);
    };
  }, []);

  return (
    <StyledModal open={dialog.open}>
      <Container>
        <Title>
          <p>{dialog.title}</p>
        </Title>
        <Message>
          <p>{dialog.message}</p>
        </Message>
        <Footer>
          <Buttons>
            <StyledButton
              className="cancelButton"
              variant="outlined"
              type="button"
              onClick={handleCancel}
            >
              Cancelar
            </StyledButton>
            <StyledButton
              className="confirmButton"
              variant="contained"
              type="button"
              onClick={handleConfirm}
            >
              Sim
            </StyledButton>
          </Buttons>
        </Footer>
      </Container>
    </StyledModal>
  );
}
