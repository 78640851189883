import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getVeiculosRequest } from '../../../store/modules/transportadora/veiculos/actions';

import { StyledComboboxInfo } from './styles';

import formatter from '../../../services/formatter';

export default function ComboboxVeiculos({ value, ...rest }) {
  const dispatch = useDispatch();

  const { veiculos, loading } = useSelector(
    state => state.transportadora.veiculos
  );
  useEffect(() => {
    dispatch(getVeiculosRequest());
  }, [dispatch]);

  const [loaded, setLoaded] = useState(false);
  const [beforeLoading, setBeforeLoading] = useState(false);
  useEffect(() => {
    if (beforeLoading && !loading) {
      setLoaded(true);
    } else {
      setBeforeLoading(loading);
    }
  }, [beforeLoading, loading]);

  function veiculoToComboOption(veiculo) {
    return {
      text: `Veículo`,
      info: formatter(veiculo.placa).toPlaca(),
      value: veiculo.id,
      silhueta:
        veiculo.tipo.silhueta && veiculo.tipo.silhueta.url.length > 1
          ? veiculo.tipo.silhueta.url
          : '',
      additionalInfo: [{ text: 'Tipo', info: veiculo.tipo.descricao }],
    };
  }

  const [optionsCombobox, setOptionsCombobox] = useState([]);
  useEffect(() => {
    const options = veiculos.map(veiculo => veiculoToComboOption(veiculo));
    setOptionsCombobox(options);
  }, [veiculos]);

  return (
    <StyledComboboxInfo
      displayEmpty
      options={[
        { text: 'Veículo', info: 'Nenhum', value: '' },
        ...optionsCombobox,
      ]}
      value={loaded ? value : ''}
      {...rest}
    />
  );
}

ComboboxVeiculos.propTypes = {
  value: PropTypes.string,
};

ComboboxVeiculos.defaultProps = {
  value: '',
};
