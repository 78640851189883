import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { signOut } from '../../store/modules/auth/actions';
import Client from '../../services/eventEmitter';

const client = Client;

export const events = {
  signout: '@portalCargas/SIGNOUTCHECKER/signout',
};

export default function SignOutChecker() {
  const dispatch = useDispatch();

  useEffect(() => {
    function handleSignOut() {
      dispatch(signOut());
    }

    client.on(events.signout, handleSignOut);

    return () => {
      client.removeEventListener(events.signout, handleSignOut);
    };
  }, [dispatch]);

  return null;
}
