import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  empresas: [],
  loading: false,
};

export default function empresas(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_EMPRESAS_REQUEST:
      case Types.CREATE_EMPRESA_REQUEST:
      case Types.UPDATE_EMPRESA_REQUEST:
      case Types.ATIVA_EMPRESA_REQUEST:
      case Types.DESATIVA_EMPRESA_REQUEST:
      case Types.DELETE_EMPRESA_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_EMPRESAS_SUCCESS: {
        draft.empresas = action.payload.empresas;
        draft.loading = false;
        break;
      }
      case Types.CREATE_EMPRESA_SUCCESS: {
        draft.empresas = [...state.empresas, action.payload.empresa];
        draft.loading = false;
        break;
      }
      case Types.UPDATE_EMPRESA_SUCCESS: {
        const index = draft.empresas.findIndex(
          empresa => empresa.id === action.payload.empresa.id
        );
        draft.empresas[index] = action.payload.empresa;
        draft.loading = false;
        break;
      }
      case Types.ATIVA_EMPRESA_SUCCESS: {
        const index = draft.empresas.findIndex(
          empresa => empresa.id === action.payload.empresaId
        );
        draft.empresas[index].ativo = true;
        draft.loading = false;
        break;
      }
      case Types.DESATIVA_EMPRESA_SUCCESS: {
        const index = draft.empresas.findIndex(
          empresa => empresa.id === action.payload.empresaId
        );
        draft.empresas[index].ativo = false;
        draft.loading = false;
        break;
      }
      case Types.DELETE_EMPRESA_SUCCESS: {
        draft.empresas = state.empresas.filter(
          empresa => empresa.id !== action.payload.empresaId
        );
        draft.loading = false;
        break;
      }
      case Types.GET_EMPRESAS_FAILURE:
      case Types.CREATE_EMPRESA_FAILURE:
      case Types.UPDATE_EMPRESA_FAILURE:
      case Types.ATIVA_EMPRESA_FAILURE:
      case Types.DESATIVA_EMPRESA_FAILURE:
      case Types.DELETE_EMPRESA_FAILURE:
      case Types.DELETE_EMPRESA_CANCEL: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
