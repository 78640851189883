import { Types } from './types';

export function getUsuariosRequest(empresaId) {
  return {
    type: Types.GET_USUARIOS_REQUEST,
    payload: { empresaId },
  };
}

export function getUsuariosSuccess(usuarios) {
  return {
    type: Types.GET_USUARIOS_SUCCESS,
    payload: { usuarios },
  };
}

export function getUsuariosFailure() {
  return {
    type: Types.GET_USUARIOS_FAILURE,
  };
}

export function createUsuarioRequest(empresaId, usuario) {
  return {
    type: Types.CREATE_USUARIO_REQUEST,
    payload: { empresaId, usuario },
  };
}

export function createUsuarioSuccess(usuario) {
  return {
    type: Types.CREATE_USUARIO_SUCCESS,
    payload: { usuario },
  };
}

export function createUsuarioFailure() {
  return {
    type: Types.CREATE_USUARIO_FAILURE,
  };
}

export function updateUsuarioRequest(empresaId, usuario) {
  return {
    type: Types.UPDATE_USUARIO_REQUEST,
    payload: { empresaId, usuario },
  };
}

export function updateUsuarioSuccess(usuario) {
  return {
    type: Types.UPDATE_USUARIO_SUCCESS,
    payload: { usuario },
  };
}

export function updateUsuarioFailure() {
  return {
    type: Types.UPDATE_USUARIO_FAILURE,
  };
}

export function ativaUsuarioRequest(empresaId, usuarioId) {
  return {
    type: Types.ATIVA_USUARIO_REQUEST,
    payload: { empresaId, usuarioId },
  };
}

export function ativaUsuarioSuccess(usuarioId) {
  return {
    type: Types.ATIVA_USUARIO_SUCCESS,
    payload: { usuarioId },
  };
}

export function ativaUsuarioFailure() {
  return {
    type: Types.ATIVA_USUARIO_FAILURE,
  };
}

export function desativaUsuarioRequest(empresaId, usuarioId) {
  return {
    type: Types.DESATIVA_USUARIO_REQUEST,
    payload: { empresaId, usuarioId },
  };
}

export function desativaUsuarioSuccess(usuarioId) {
  return {
    type: Types.DESATIVA_USUARIO_SUCCESS,
    payload: { usuarioId },
  };
}

export function desativaUsuarioFailure() {
  return {
    type: Types.DESATIVA_USUARIO_FAILURE,
  };
}

export function deleteUsuarioRequest(empresaId, usuarioId) {
  return {
    type: Types.DELETE_USUARIO_REQUEST,
    payload: { empresaId, usuarioId },
  };
}

export function deleteUsuarioSuccess(usuarioId) {
  return {
    type: Types.DELETE_USUARIO_SUCCESS,
    payload: { usuarioId },
  };
}

export function deleteUsuarioFailure() {
  return {
    type: Types.DELETE_USUARIO_FAILURE,
  };
}

export function deleteUsuarioCancel() {
  return {
    type: Types.DELETE_USUARIO_CANCEL,
  };
}
