export const Types = {
  GET_USUARIOS_REQUEST: '@admin/usuarios/GET_USUARIOS_REQUEST',
  GET_USUARIOS_SUCCESS: '@admin/usuarios/GET_USUARIOS_SUCCESS',
  GET_USUARIOS_FAILURE: '@admin/usuarios/GET_USUARIOS_FAILURE',

  UPDATE_USUARIO_REQUEST: '@admin/usuarios/UPDATE_USUARIO_REQUEST',
  UPDATE_USUARIO_SUCCESS: '@admin/usuarios/UPDATE_USUARIO_SUCCESS',
  UPDATE_USUARIO_FAILURE: '@admin/usuarios/UPDATE_USUARIO_FAILURE',

  ATIVA_USUARIO_REQUEST: '@admin/usuarios/ATIVA_USUARIO_REQUEST',
  ATIVA_USUARIO_SUCCESS: '@admin/usuarios/ATIVA_USUARIO_SUCCESS',
  ATIVA_USUARIO_FAILURE: '@admin/usuarios/ATIVA_USUARIO_FAILURE',

  DESATIVA_USUARIO_REQUEST: '@admin/usuarios/DESATIVA_USUARIO_REQUEST',
  DESATIVA_USUARIO_SUCCESS: '@admin/usuarios/DESATIVA_USUARIO_SUCCESS',
  DESATIVA_USUARIO_FAILURE: '@admin/usuarios/DESATIVA_USUARIO_FAILURE',

  GET_CONVITES_REQUEST: '@admin/usuarios/GET_CONVITES_REQUEST',
  GET_CONVITES_SUCCESS: '@admin/usuarios/GET_CONVITES_SUCCESS',
  GET_CONVITES_FAILURE: '@admin/usuarios/GET_CONVITES_FAILURE',

  CREATE_CONVITE_REQUEST: '@admin/usuarios/CREATE_CONVITE_REQUEST',
  CREATE_CONVITE_SUCCESS: '@admin/usuarios/CREATE_CONVITE_SUCCESS',
  CREATE_CONVITE_FAILURE: '@admin/usuarios/CREATE_CONVITE_FAILURE',

  REENVIA_CONVITE_REQUEST: '@admin/usuarios/REENVIA_CONVITE_REQUEST',
  REENVIA_CONVITE_SUCCESS: '@admin/usuarios/REENVIA_CONVITE_SUCCESS',
  REENVIA_CONVITE_FAILURE: '@admin/usuarios/REENVIA_CONVITE_FAILURE',
};
