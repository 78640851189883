import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

import AvatarImage from '../../assets/images/avatar.png';

import { Container, ChipLabel } from './styles';

export default function ColetaChip({ coletaId, nome, avatarUrl }) {
  return (
    <Container
      label={
        <ChipLabel>
          <p>{nome}</p>
          <span>{`Coleta ${coletaId}`}</span>
        </ChipLabel>
      }
      avatar={<Avatar alt="Foto do Usuário" src={avatarUrl || AvatarImage} />}
    />
  );
}

ColetaChip.propTypes = {
  coletaId: PropTypes.number.isRequired,
  nome: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
};
