import { Types } from './types';

export function getColetasEmitidasRequest(page, pageSize) {
  return {
    type: Types.GET_COLETAS_EMITIDAS_REQUEST,
    payload: { page, pageSize },
  };
}

export function getColetasEmitidasSuccess(coletas, count) {
  return {
    type: Types.GET_COLETAS_EMITIDAS_SUCCESS,
    payload: { coletas, count },
  };
}

export function getColetasEmitidasFailure() {
  return {
    type: Types.GET_COLETAS_EMITIDAS_FAILURE,
  };
}
