import styled from 'styled-components';
import { defaultColors } from '../../styles/colors';

export const ReportContainer = styled.div`
  font-size: 13px;
  background: ${defaultColors.color1};
  padding:20px;

  display:flex;
  justify-content:center;
  flex-direction: column;
`;
export const ReportHeader = styled.div`
  padding: 10px 0;
  width: 100%;
  min-width: 620px;
`;
export const ReportRow = styled.div`
  padding: ${props => props.padding * 10}px 0px;
  line-height:15px;
  display:flex;
  justify-content:space-between;
`;
export const Bold = styled.span`
  font-weight:600;
  padding-right: 3px;
  font-size: ${props => props.size === 'medium' ? '14px' : '12px'};
`;
export const ReportData = styled.span`
`;
