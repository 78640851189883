import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  emitidas: [],
  loading: false,
  count: 0,
};

export default function coletas(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_COLETAS_EMITIDAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_COLETAS_EMITIDAS_SUCCESS: {
        draft.emitidas = action.payload.coletas;
        draft.count = action.payload.count;
        draft.loading = false;
        break;
      }

      case Types.GET_COLETAS_EMITIDAS_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
