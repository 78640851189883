import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuItem from '../MenuItem';

import TransportadoraMenuItems from './Transportadora';
import ClienteMenuItems from './Cliente';
import EcosisMenuItems from './Ecosis';

import {
  empresa as TiposEmpresa,
  usuario as TiposUsuario,
} from '../../consts/types';
import Medias from '../../consts/medias';

import PATHS from '../../consts/paths';

import {
  StyledDrawer,
  Button,
  Top,
  StyledDivider,
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
} from './styles';

import Logo from '../../assets/images/menu-truck-sm.svg';
import { ReactComponent as IconAdmin } from '../../assets/images/menu/settings-gear-64.svg';

const Sidebar = forwardRef(({ open, onClose, onSizeChange, ...rest }, ref) => {
  const sm = useMediaQuery(Medias.sm);

  const [expanded, setExpanded] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(open);
  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    if (onSizeChange) {
      if (sm) {
        onSizeChange(0);
      } else {
        onSizeChange(expanded ? 272 : 72);
      }
    }
  }, [expanded, sm, onSizeChange]);

  function renderItems() {
    switch (profile.empresa.tipo) {
      case TiposEmpresa.CLIENTE:
        if (sm)
          return (
            <div
              onClick={onClose}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            >
              <ClienteMenuItems />
            </div>
          );
        return <ClienteMenuItems />;
      case TiposEmpresa.TRANSPORTADORA:
        if (sm)
          return (
            <div
              onClick={onClose}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            >
              <TransportadoraMenuItems />
            </div>
          );
        return <TransportadoraMenuItems />;
      case TiposEmpresa.ECOSIS:
        if (sm)
          return (
            <div
              onClick={onClose}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            >
              <EcosisMenuItems />
            </div>
          );
        return <EcosisMenuItems />;
      default:
        return null;
    }
  }

  function renderChevron() {
    if (expanded) {
      return sm ? <StyledChevronRightIcon /> : <StyledChevronLeftIcon />;
    }
    return sm ? <StyledChevronLeftIcon /> : <StyledChevronRightIcon />;
  }

  return (
    <StyledDrawer
      variant={sm ? 'temporary' : 'permanent'}
      anchor={sm ? 'right' : 'left'}
      expanded={expanded ? 1 : 0}
      open={drawerOpen}
      onClose={onClose}
      ref={ref}
      {...rest}
    >
      <Top>
        <img src={Logo} alt="Imagem de Caminhão" />
      </Top>
      <Button>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {renderChevron()}
        </IconButton>
      </Button>
      <StyledDivider />
      {profile && renderItems()}
      {profile && profile.tipo === TiposUsuario.ADMIN && (
        <div onClick={onClose} onKeyPress={() => {}} role="button" tabIndex={0}>
          <MenuItem
            Icon={IconAdmin}
            text="Dados da Empresa"
            path={PATHS.admin.home}
          />
        </div>
      )}
    </StyledDrawer>
  );
});

Sidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSizeChange: PropTypes.func,
};

Sidebar.defaultProps = {
  open: false,
  onClose: undefined,
  onSizeChange: undefined,
};

export default Sidebar;
