export const Types = {
  GET_TIPOSVEICULOS_REQUEST: '@ecosis/tiposVeiculos/GET_TIPOSVEICULOS_REQUEST',
  GET_TIPOSVEICULOS_SUCCESS: '@ecosis/tiposVeiculos/GET_TIPOSVEICULOS_SUCCESS',
  GET_TIPOSVEICULOS_FAILURE: '@ecosis/tiposVeiculos/GET_TIPOSVEICULOS_FAILURE',

  CREATE_TIPOVEICULO_REQUEST:
    '@ecosis/tiposVeiculos/CREATE_TIPOVEICULO_REQUEST',
  CREATE_TIPOVEICULO_SUCCESS:
    '@ecosis/tiposVeiculos/CREATE_TIPOVEICULO_SUCCESS',
  CREATE_TIPOVEICULO_FAILURE:
    '@ecosis/tiposVeiculos/CREATE_TIPOVEICULO_FAILURE',

  UPDATE_TIPOVEICULO_REQUEST:
    '@ecosis/tiposVeiculos/UPDATE_TIPOVEICULO_REQUEST',
  UPDATE_TIPOVEICULO_SUCCESS:
    '@ecosis/tiposVeiculos/UPDATE_TIPOVEICULO_SUCCESS',
  UPDATE_TIPOVEICULO_FAILURE:
    '@ecosis/tiposVeiculos/UPDATE_TIPOVEICULO_FAILURE',

  DELETE_TIPOVEICULO_REQUEST:
    '@ecosis/tiposVeiculos/DELETE_TIPOVEICULO_REQUEST',
  DELETE_TIPOVEICULO_SUCCESS:
    '@ecosis/tiposVeiculos/DELETE_TIPOVEICULO_SUCCESS',
  DELETE_TIPOVEICULO_FAILURE:
    '@ecosis/tiposVeiculos/DELETE_TIPOVEICULO_FAILURE',
  DELETE_TIPOVEICULO_CANCEL: '@ecosis/tiposVeiculos/DELETE_TIPOVEICULO_CANCEL',
};
