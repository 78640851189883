import React from 'react';

import { StyledKeyboardDatePicker } from './styles';

export default function DatePickerBase(props) {
  return (
    <StyledKeyboardDatePicker
      disableToolbar
      variant="inline"
      format="dd/MM/yyyy"
      margin="normal"
      invalidDateMessage="Data inválida"
      maxDateMessage="Data maior que a data máxima permitida"
      minDateMessage="Data menor que a data mínima permitida"
      {...props}
    />
  );
}
