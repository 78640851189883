import styled, { css } from 'styled-components';
import { colors } from '../../styles/colors';

const active = css`
  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: -3px;
    font-size: 0.7em;
    background: ${props =>
      props.badgeColor ? props.badgeColor : colors.notification.badge};
    color: white;
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    box-shadow: 0 0 1px #333;
  }
`;

export const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  opacity: ${props => (props.active ? 1 : 0.5)};
  ${props => (props.active ? active : null)}
`;

export const Icon = styled.img``;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
