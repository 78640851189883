import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Input } from '@rocketseat/unform';
import FormTextField from '../../../../components/Unform/FormTextField';
import FormSelectWrapper from '../../../../components/Unform/FormSelectWrapper';
import Modal from '../../../../components/ModalEdit';

import {
  usuario as TiposUsuario,
  usuarioString as TiposUsuarioString,
} from '../../../../consts/types';
import { cpf, telefoneCelular, telefoneFixo } from '../../../../consts/regex';
import { unmask, phoneMask } from '../../../../services/masks';
import { validaCpf } from '../../../../services/validators';

import {
  createUsuarioRequest,
  updateUsuarioRequest,
} from '../../../../store/modules/ecosis/usuarios/actions';

import { Container, TipoUsuario } from './styles';

const schema = Yup.object().shape({
  isEditing: Yup.boolean(),
  email: Yup.string()
    .email()
    .required('Email deve ser informado'),
  nome: Yup.string().required('Nome deve ser informado'),
  cpf: Yup.string().test(
    'validaCPF',
    'CPF inválido',
    value => !value || validaCpf(value)
  ),
  telefone: Yup.string().test('Telefone', 'Telefone inválido', telefone => {
    if (!telefone) return true;

    if (unmask(telefone).length > 10) {
      return telefoneCelular.regex.test(telefone);
    }
    return telefoneFixo.regex.test(telefone);
  }),
  tipo: Yup.string().required('Informe o tipo'),
  senha: Yup.string().when('isEditing', (isEditing, field) => {
    return !isEditing
      ? field
          .min(6, 'Senha deve ter no mínimo 6 caracteres')
          .required('Senha deve ser informada')
      : field;
  }),
  confirmSenha: Yup.string().when('senha', (senha, field) =>
    senha
      ? field
          .oneOf(
            [Yup.ref('senha')],
            'Confirmação da senha difere da senha informada'
          )
          .required('Confirmação da senha deve ser informada')
      : field
  ),
});

export default function FormEdit({ empresaId, usuario, onClose }) {
  const dispatch = useDispatch();
  const [editUsuario, setEditUsuario] = useState(usuario);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsEditing(!!(editUsuario && editUsuario.id));
  }, [editUsuario]);

  function handleCancel() {
    setEditUsuario(null);
    onClose();
  }

  function handleSave(usuarioData) {
    delete usuarioData.isEditing;
    if (usuarioData.cpf) usuarioData.cpf = unmask(usuarioData.cpf);
    if (usuarioData.telefone)
      usuarioData.telefone = unmask(usuarioData.telefone);
    if (!isEditing) {
      dispatch(createUsuarioRequest(empresaId, usuarioData));
    } else {
      usuarioData.id = editUsuario.id;
      if (!usuarioData.senha) delete usuarioData.senha;
      if (!usuarioData.confirmSenha) delete usuarioData.confirmSenha;
      dispatch(updateUsuarioRequest(empresaId, usuarioData));
    }
    setEditUsuario(null);
    onClose();
  }

  return (
    <Modal
      open={!!editUsuario}
      title="Usuário"
      schema={schema}
      fieldsComponent={
        editUsuario && (
          <Container>
            <Input name="isEditing" type="hidden" value={isEditing} />
            <FormTextField
              className="field"
              name="email"
              type="email"
              label="Email"
              spellCheck="false"
              defaultValue={editUsuario.email}
              autoFocus={!isEditing}
            />
            <FormTextField
              className="field"
              name="nome"
              type="text"
              label="Nome"
              spellCheck="false"
              inputProps={{ maxLength: 120 }}
              defaultValue={editUsuario.nome}
            />
            <FormTextField
              className="field"
              name="cpf"
              type="text"
              label="CPF"
              spellCheck="false"
              defaultValue={editUsuario.cpf}
              textMaskProps={{
                mask: cpf.array,
              }}
            />
            <FormTextField
              className="field"
              name="telefone"
              type="text"
              label="Telefone"
              spellCheck="false"
              defaultValue={editUsuario.telefone}
              textMaskProps={{
                mask: phoneMask,
              }}
            />
            <FormSelectWrapper name="tipo" className="field">
              <TipoUsuario
                showSearch={false}
                options={Object.keys(TiposUsuario).map(tipo => ({
                  text: 'Tipo',
                  info: TiposUsuarioString[tipo],
                  value: TiposUsuario[tipo],
                }))}
                value={editUsuario.tipo || TiposUsuario.USUARIO}
              />
            </FormSelectWrapper>
            <FormTextField
              className="field"
              name="senha"
              type="password"
              label="Senha"
              spellCheck="false"
            />
            <FormTextField
              className="field"
              name="confirmSenha"
              type="password"
              label="Confirmação da Senha"
              spellCheck="false"
            />
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  empresaId: PropTypes.number.isRequired,
  usuario: PropTypes.shape({
    cpf: PropTypes.string,
    nome: PropTypes.string,
    telefone: PropTypes.string,
    tipo: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
