import { Types } from './types';

export function getMotoristasRequest() {
  return {
    type: Types.GET_MOTORISTAS_REQUEST,
  };
}

export function getMotoristasSuccess(motoristas) {
  return {
    type: Types.GET_MOTORISTAS_SUCCESS,
    payload: { motoristas },
  };
}

export function getMotoristasFailure() {
  return {
    type: Types.GET_MOTORISTAS_FAILURE,
  };
}

export function createMotoristaRequest(motorista) {
  return {
    type: Types.CREATE_MOTORISTA_REQUEST,
    payload: { motorista },
  };
}

export function createMotoristaSuccess(motorista) {
  return {
    type: Types.CREATE_MOTORISTA_SUCCESS,
    payload: { motorista },
  };
}

export function createMotoristaFailure() {
  return {
    type: Types.CREATE_MOTORISTA_FAILURE,
  };
}

export function updateMotoristaRequest(motorista) {
  return {
    type: Types.UPDATE_MOTORISTA_REQUEST,
    payload: { motorista },
  };
}

export function updateMotoristaSuccess(motorista) {
  return {
    type: Types.UPDATE_MOTORISTA_SUCCESS,
    payload: { motorista },
  };
}

export function updateMotoristaFailure() {
  return {
    type: Types.UPDATE_MOTORISTA_FAILURE,
  };
}

export function deleteMotoristaRequest(motoristaId) {
  return {
    type: Types.DELETE_MOTORISTA_REQUEST,
    payload: { motoristaId },
  };
}

export function deleteMotoristaSuccess(motoristaId) {
  return {
    type: Types.DELETE_MOTORISTA_SUCCESS,
    payload: { motoristaId },
  };
}

export function deleteMotoristaFailure() {
  return {
    type: Types.DELETE_MOTORISTA_FAILURE,
  };
}

export function deleteMotoristaCancel() {
  return {
    type: Types.DELETE_MOTORISTA_CANCEL,
  };
}
