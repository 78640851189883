import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableGroupbar } from 'material-table';

import tableLocalization from '../../consts/tableLocalization';

import { Container } from './styles';

const sizeOptions = [5, 10, 20, 50];

function minPageSize(dataSize) {
  if (dataSize > Math.max(...sizeOptions)) return Math.max(...sizeOptions);
  if (dataSize < Math.min(...sizeOptions)) return Math.min(...sizeOptions);

  const minValues = sizeOptions.filter((s) => s >= dataSize);
  return minValues.length === 0
    ? Math.min(...sizeOptions)
    : Math.min(...minValues);
}

export default function Table({
  components,
  onChangeGroup,
  autoSizing,
  data,
  options,
  ...props
}) {
  return (
    <Container>
      <MaterialTable
        localization={tableLocalization}
        components={{
          Groupbar: (groupBarProps) => {
            if (onChangeGroup) {
              onChangeGroup(groupBarProps.groupColumns);
            }
            return <MTableGroupbar {...groupBarProps} />;
          },
          ...components,
        }}
        options={
          autoSizing
            ? {
                ...options,
                pageSizeOptions: sizeOptions,
                pageSize: minPageSize(Array.isArray(data) ? data.length : 10),
              }
            : { ...options }
        }
        data={data}
        {...props}
      />
    </Container>
  );
}

const RefComponent = PropTypes.shape({ current: PropTypes.element });
const StyledComponent = PropTypes.shape({
  classes: PropTypes.object,
  innerRef: RefComponent,
});

Table.propTypes = {
  components: PropTypes.shape({
    Action: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Actions: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Body: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Cell: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Container: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    EditField: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    EditRow: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    FilterRow: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Groupbar: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    GroupRow: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Header: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    OverlayLoading: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Pagination: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Row: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
    Toolbar: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
      StyledComponent,
    ]),
  }),
  onChangeGroup: PropTypes.func,
  autoSizing: PropTypes.bool,
  options: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()),
};

Table.defaultProps = {
  components: null,
  onChangeGroup: null,
  autoSizing: false,
  options: null,
  data: undefined,
};
