import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormTextField from '../../../../components/Unform/FormTextField';
import FormSelectWrapper from '../../../../components/Unform/FormSelectWrapper';
import Modal from '../../../../components/ModalEdit';

import {
  usuario as TiposUsuario,
  usuarioString as TiposUsuarioString,
} from '../../../../consts/types';

import {
  createConviteRequest,
  updateUsuarioRequest,
} from '../../../../store/modules/admin/usuarios/actions';

import { Container, TipoUsuario } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email deve ser informado'),
  tipo: Yup.string().required('Informe o tipo'),
});

export default function FormEdit({ usuario, onClose }) {
  const dispatch = useDispatch();
  const [editUsuario, setEditUsuario] = useState(usuario);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsEditing(!!(editUsuario && editUsuario.id));
  }, [editUsuario]);

  function handleCancel() {
    setEditUsuario(null);
    onClose();
  }

  function handleSave(usuarioData) {
    if (!isEditing) {
      dispatch(createConviteRequest(usuarioData));
    } else {
      usuarioData.id = editUsuario.id;
      dispatch(updateUsuarioRequest(usuarioData));
    }
    setEditUsuario(null);
    onClose();
  }

  return (
    <Modal
      open={!!editUsuario}
      title="Usuário"
      schema={schema}
      fieldsComponent={
        editUsuario && (
          <Container>
            <FormTextField
              className="field"
              name="email"
              type="email"
              label="Email"
              spellCheck="false"
              defaultValue={editUsuario.email}
              autoFocus={!isEditing}
              disabled={isEditing}
            />
            {isEditing && (
              <FormTextField
                className="field"
                name="nome"
                type="text"
                label="Nome"
                spellCheck="false"
                inputProps={{ maxLength: 120 }}
                defaultValue={editUsuario.nome}
                disabled
              />
            )}
            <FormSelectWrapper name="tipo" className="field">
              <TipoUsuario
                showSearch={false}
                options={Object.keys(TiposUsuario).map(tipo => ({
                  text: 'Tipo',
                  info: TiposUsuarioString[tipo],
                  value: TiposUsuario[tipo],
                }))}
                value={editUsuario.tipo || TiposUsuario.USUARIO}
              />
            </FormSelectWrapper>
          </Container>
        )
      }
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

FormEdit.propTypes = {
  usuario: PropTypes.shape({
    cpf: PropTypes.string,
    nome: PropTypes.string,
    telefone: PropTypes.string,
    tipo: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
