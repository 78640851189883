import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

const toggledFeaturesContext = createContext();

const { Provider } = toggledFeaturesContext;

export function ToggledFeaturesProvider({ children, features }) {
  return <Provider value={features}>{children}</Provider>;
}

export function useFeatureEnabled(feature) {
  const features = useContext(toggledFeaturesContext);

  return features[feature];
}

ToggledFeaturesProvider.propTypes = {
  children: PropTypes.node.isRequired,

  features: PropTypes.shape().isRequired,
};
