import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../styles/colors';

import { Container, IconContainer, Icon } from './styles';

export default function Notification({ icon, active, badgeColor, className }) {
  return (
    <Container className={className}>
      <IconContainer active={active} badgeColor={badgeColor}>
        <Icon src={icon} />
      </IconContainer>
    </Container>
  );
}

Notification.propTypes = {
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  badgeColor: PropTypes.string,
  className: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  badgeColor: colors.notification.badge,
};
