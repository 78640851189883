import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { persistor } from '../../store';

import {
  Container,
  ImageContainer,
  SignUpContainer,
  SignUpForm,
  Group,
  Buttons,
  StyledButton,
  StyledLogo,
} from './styles';

import FormTextField from '../../components/Unform/FormTextField';

import Image from '../../assets/images/login-truck.png';

import Medias from '../../consts/medias';

import { cpf, telefoneCelular, telefoneFixo } from '../../consts/regex';
import { unmask, phoneMask } from '../../services/masks';
import { validaCpf } from '../../services/validators';

import { signUpRequest } from '../../store/modules/auth/actions';

const schema = Yup.object().shape({
  nome: Yup.string().required('Nome deve ser informado'),
  cpf: Yup.string().test(
    'validaCPF',
    'CPF inválido',
    value => !value || validaCpf(value)
  ),
  telefone: Yup.string().test('Telefone', 'Telefone inválido', telefone => {
    if (!telefone) return true;

    if (unmask(telefone).length > 10) {
      return telefoneCelular.regex.test(telefone);
    }
    return telefoneFixo.regex.test(telefone);
  }),
  senha: Yup.string()
    .min(6, 'Senha deve ter no mínimo 6 caracteres')
    .required('Senha deve ser informada'),
  confirmSenha: Yup.string()
    .oneOf([Yup.ref('senha')], 'Confirmação da senha difere da senha informada')
    .required('Confirmação da senha deve ser informada'),
});

export default function SignUpPage({ match }) {
  const sm = useMediaQuery(Medias.sm);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = data => {
    persistor.persist();
    if (!data.lembra) persistor.pause();
    data.cpf = unmask(data.cpf);
    data.telefone = unmask(data.telefone);
    if (!data.cpf) delete data.cpf;
    if (!data.telefone) delete data.telefone;
    const { hash } = match.params;
    if (data) dispatch(signUpRequest(data, decodeURIComponent(hash)));
  };

  return (
    <Container>
      <Group>
        {!sm && (
          <ImageContainer>
            <img src={Image} alt="Imagem de Caminhão" />
          </ImageContainer>
        )}
        <SignUpContainer>
          <StyledLogo />
          {!sm && (
            <p className="subtitle">
              Preencha seus dados para se cadastrar no sistema.
            </p>
          )}
          <SignUpForm
            schema={schema}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <FormTextField
              className="field"
              name="nome"
              type="text"
              label="Nome"
              spellCheck="false"
              required
              inputProps={{ maxLength: 120 }}
            />
            <FormTextField
              className="field"
              name="cpf"
              type="text"
              label="CPF"
              spellCheck="false"
              textMaskProps={{
                mask: cpf.array,
              }}
            />
            <FormTextField
              className="field"
              name="telefone"
              type="text"
              label="Telefone"
              spellCheck="false"
              textMaskProps={{
                mask: phoneMask,
              }}
            />
            <FormTextField
              className="field"
              name="senha"
              type="password"
              label="Senha"
              required
              spellCheck="false"
            />
            <FormTextField
              className="field"
              name="confirmSenha"
              type="password"
              label="Confirmação da Senha"
              required
              spellCheck="false"
            />
            <Buttons>
              <StyledButton variant="contained" type="submit">
                {loading ? 'Carregando...' : 'Enviar'}
              </StyledButton>
            </Buttons>
          </SignUpForm>
          <p className="copyright">
            Copyright © 2019 Ecosis. Todos os direitos reservados.
          </p>
        </SignUpContainer>
      </Group>
    </Container>
  );
}

SignUpPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      hash: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
