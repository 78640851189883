import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { lighten } from 'polished';

import Paper from '../../components/Paper';

import { colors } from '../../styles/colors';

export const ContentContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  padding: 20px 12px;
  width: 100%;
  background: ${colors.pedidos.background};
`;

export const Bar = styled(Paper)`
  background: ${colors.menu.background};
  color: white;
`;
export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background: ${colors.menu.item.active};
    height: 5px;
  }
`;

export const StyledTab = styled(Tab)`
  text-transform: none;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &.Mui-selected {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const StyledButton = styled(Button)`
  height: 46px;
  min-width: 120px;
  font-size: 18px;
  margin-left: 32px;
  padding: 6px;
  border-radius: 4px;
  line-height: 23px;
  text-transform: none;
  background: #4ad991;
  color: white;

  &:hover {
    background: ${lighten(0.12, '#4ad991')};
  }
`;

export const StyledAddCircleOutlineIcon = styled(AddCircleOutlineIcon)`
  color: white;
`;

export const StyledFab = styled(Fab)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: #4ad991;
`;

export const StyledAddIcon = styled(AddIcon)`
  color: white;
`;
