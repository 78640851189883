import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';

import {
  getColetasEmitidasSuccess,
  getColetasEmitidasFailure,
} from './actions';
import { Types } from './types';

export function* getColetasEmitidas({ payload }) {
  try {
    const { page, pageSize } = payload;

    const responseColetas = yield call(
      api.get,
      ApiEndPoints.cliente.coletasEmitidas(page, pageSize)
    );

    const { rows: coletas, count } = responseColetas.data;

    yield put(getColetasEmitidasSuccess(coletas, count));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar as coletas emitidas');
    yield put(getColetasEmitidasFailure());
  }
}

export default all([
  takeLatest(Types.GET_COLETAS_EMITIDAS_REQUEST, getColetasEmitidas),
]);
