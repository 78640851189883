import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '../../../components/Table';
import {
  getDatasRequest,
  deleteDataRequest,
} from '../../../store/modules/comuns/datasDisponiveis/actions';

import { TableContainer, DeleteIcon } from './styles';

export default function DatasDisponiveis() {
  const dispatch = useDispatch();
  const [table, setTable] = useState({});

  const datasDisponiveis = useSelector(
    state => state.comuns.datasDisponiveis.datas
  );
  const loading = useSelector(state => state.comuns.datasDisponiveis.loading);

  useEffect(() => {
    dispatch(getDatasRequest());
  }, [dispatch]);

  useEffect(() => {
    function tableData() {
      return datasDisponiveis.map(data => ({ ...data }));
    }

    function remove(data) {
      dispatch(deleteDataRequest(data));
    }

    function renderExcluirData() {
      return {
        icon: () => <DeleteIcon />,
        tooltip: 'Excluir',
        onClick: (event, data) => remove(data),
      };
    }

    setTable({
      columns: [{ title: 'DATA', field: 'data' }],
      data: tableData(),
      actions: [rowData => renderExcluirData(rowData)],
      options: {
        actionsColumnIndex: -1,
        toolbar: false,
        paging: false,
      },
    });
  }, [dispatch, datasDisponiveis]);

  return (
    <>
      <TableContainer>
        <Table
          columns={table.columns}
          data={table.data}
          actions={table.actions}
          options={table.options}
          isLoading={loading}
          autoSizing
        />
      </TableContainer>
    </>
  );
}
