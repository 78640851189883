import React from 'react';
import PropTypes from 'prop-types';

import { Container, IconContainer, Infos, Title, Info } from './styles';

export default function InfoCard({ icon, title, info }) {
  return (
    <Container>
      <IconContainer>{icon()}</IconContainer>
      <Infos>
        <Title>{title}</Title>
        <Info>{info}</Info>
      </Infos>
    </Container>
  );
}

InfoCard.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string,
  info: PropTypes.string,
};

InfoCard.defaultProps = {
  title: '',
  info: '',
};
