import produce from 'immer';
import { Types } from './types';

const INITIAL_STATE = {
  motoristas: [],
  loading: false,
};

export default function motoristas(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_MOTORISTAS_REQUEST:
      case Types.CREATE_MOTORISTA_REQUEST:
      case Types.UPDATE_MOTORISTA_REQUEST:
      case Types.DELETE_MOTORISTA_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_MOTORISTAS_SUCCESS: {
        draft.motoristas = action.payload.motoristas;
        draft.loading = false;
        break;
      }
      case Types.CREATE_MOTORISTA_SUCCESS: {
        draft.motoristas = [...state.motoristas, action.payload.motorista];
        draft.loading = false;
        break;
      }
      case Types.UPDATE_MOTORISTA_SUCCESS: {
        const index = draft.motoristas.findIndex(
          motorista => motorista.id === action.payload.motorista.id
        );
        draft.motoristas[index] = action.payload.motorista;
        draft.loading = false;
        break;
      }
      case Types.DELETE_MOTORISTA_SUCCESS: {
        draft.motoristas = state.motoristas.filter(
          motorista => motorista.id !== action.payload.motoristaId
        );
        draft.loading = false;
        break;
      }
      case Types.GET_MOTORISTAS_FAILURE:
      case Types.CREATE_MOTORISTA_FAILURE:
      case Types.UPDATE_MOTORISTA_FAILURE:
      case Types.DELETE_MOTORISTA_FAILURE:
      case Types.DELETE_MOTORISTA_CANCEL: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
