import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import trataErros from '../../../services/trataErros';
import ApiEndPoints from '../../../consts/apiEndpoints';

import { updateUsuarioSuccess, updateUsuarioFailure } from './actions';
import { Types } from './types';

export function* updateUsuario({ payload }) {
  try {
    const { avatarId, nome, cpf, telefone, ...rest } = payload.usuario;

    const usuario = {
      avatarId,
      nome,
      cpf,
      telefone,
      ...(rest.senha ? rest : {}),
    };

    const response = yield call(api.put, ApiEndPoints.comuns.usuario, usuario);

    yield put(updateUsuarioSuccess(response.data));
    toast.success('Perfil atualizado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar seu perfil');
    yield put(updateUsuarioFailure());
  }
}

export default all([takeLatest(Types.UPDATE_USUARIO_REQUEST, updateUsuario)]);
