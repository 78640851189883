import { Types } from './types';

export function getUsuariosRequest() {
  return {
    type: Types.GET_USUARIOS_REQUEST,
  };
}

export function getUsuariosSuccess(usuarios) {
  return {
    type: Types.GET_USUARIOS_SUCCESS,
    payload: { usuarios },
  };
}

export function getUsuariosFailure() {
  return {
    type: Types.GET_USUARIOS_FAILURE,
  };
}

export function updateUsuarioRequest(usuario) {
  return {
    type: Types.UPDATE_USUARIO_REQUEST,
    payload: { usuario },
  };
}

export function updateUsuarioSuccess(usuario) {
  return {
    type: Types.UPDATE_USUARIO_SUCCESS,
    payload: { usuario },
  };
}

export function updateUsuarioFailure() {
  return {
    type: Types.UPDATE_USUARIO_FAILURE,
  };
}

export function ativaUsuarioRequest(usuarioId) {
  return {
    type: Types.ATIVA_USUARIO_REQUEST,
    payload: { usuarioId },
  };
}

export function ativaUsuarioSuccess(usuarioId) {
  return {
    type: Types.ATIVA_USUARIO_SUCCESS,
    payload: { usuarioId },
  };
}

export function ativaUsuarioFailure() {
  return {
    type: Types.ATIVA_USUARIO_FAILURE,
  };
}

export function desativaUsuarioRequest(usuarioId) {
  return {
    type: Types.DESATIVA_USUARIO_REQUEST,
    payload: { usuarioId },
  };
}

export function desativaUsuarioSuccess(usuarioId) {
  return {
    type: Types.DESATIVA_USUARIO_SUCCESS,
    payload: { usuarioId },
  };
}

export function desativaUsuarioFailure() {
  return {
    type: Types.DESATIVA_USUARIO_FAILURE,
  };
}

export function getConvitesRequest() {
  return {
    type: Types.GET_CONVITES_REQUEST,
  };
}

export function getConvitesSuccess(convites) {
  return {
    type: Types.GET_CONVITES_SUCCESS,
    payload: { convites },
  };
}

export function getConvitesFailure() {
  return {
    type: Types.GET_CONVITES_FAILURE,
  };
}

export function createConviteRequest(convite) {
  return {
    type: Types.CREATE_CONVITE_REQUEST,
    payload: { convite },
  };
}

export function createConviteSuccess(convite) {
  return {
    type: Types.CREATE_CONVITE_SUCCESS,
    payload: { convite },
  };
}

export function createConviteFailure() {
  return {
    type: Types.CREATE_CONVITE_FAILURE,
  };
}

export function reenviaConviteRequest(conviteId) {
  return {
    type: Types.REENVIA_CONVITE_REQUEST,
    payload: { conviteId },
  };
}

export function reenviaConviteSuccess(convite) {
  return {
    type: Types.REENVIA_CONVITE_SUCCESS,
    payload: { convite },
  };
}

export function reenviaConviteFailure() {
  return {
    type: Types.REENVIA_CONVITE_FAILURE,
  };
}
