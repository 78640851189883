import styled from 'styled-components';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { lighten } from 'polished';

import Paper from '../../../components/Paper';

import { colors } from '../../../styles/colors';

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${props => (props.ativo ? '#4ad991' : 'lightgrey')};
`;

export const StyledRemoveCircleIcon = styled(RemoveCircle)`
  color: red;
`;

export const StyledAddCircleOutlineIcon = styled(AddCircleOutlineIcon)`
  color: white;
`;

export const StyledAddIcon = styled(AddIcon)`
  color: white;
`;

export const TableContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  background: ${colors.pedidos.background};
`;

export const StyledButton = styled(Button)`
  height: 46px;
  min-width: 120px;
  font-size: 18px;
  margin-left: 32px;
  padding: 6px;
  border-radius: 4px;
  line-height: 23px;
  text-transform: none;
  background: #4ad991;
  color: white;

  &:hover {
    background: ${lighten(0.12, '#4ad991')};
  }
`;

export const StyledFab = styled(Fab)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: #4ad991;
`;
