import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';
import trataErros from '../../../../services/trataErros';
import formatter from '../../../../services/formatter';

import { calculateCurrentPage } from '../../../../services/mathHelpes';
import ApiEndPoints from '../../../../consts/apiEndpoints';
import PATHS from '../../../../consts/paths';

import {
  getColetasEmAndamentoSuccess,
  getColetasEmAndamentoFailure,
  getColetasEmitidasSuccess,
  getColetasEmitidasFailure,
  createColetaSuccess,
  createColetaFailure,
  deleteColetaSuccess,
  deleteColetaFailure,
  finalizaColetaSuccess,
  finalizaColetaFailure,
  reabreColetaSuccess,
  reabreColetaFailure,
  addItemColetaSuccess,
  addItemColetaFailure,
  updItemColetaSuccess,
  updItemColetaFailure,
  delItemColetaSuccess,
  delItemColetaFailure,
} from './actions';
import { Types } from './types';

export function* getColetasEmAndamento() {
  try {
    const response = yield call(
      api.get,
      ApiEndPoints.transportadora.coletasEmAndamento
    );

    const coletas = response.data;

    yield put(getColetasEmAndamentoSuccess(coletas));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar as coletas em andamento');
    yield put(getColetasEmAndamentoFailure());
  }
}

export function* getColetasEmitidas({ payload }) {
  try {
    const page = calculateCurrentPage(payload.page, payload.pageSize);

    const response = yield call(
      api.get,
      ApiEndPoints.transportadora.coletasEmitidas({
        ...payload,
        page,
      })
    );

    const coletas = response.data;
    const { count } = response.headers;
    yield put(getColetasEmitidasSuccess(coletas, count));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar as coletas emitidas');
    yield put(getColetasEmitidasFailure());
  }
}

export function* createColeta() {
  try {
    const responseNewColeta = yield call(
      api.post,
      ApiEndPoints.transportadora.coletas
    );

    const newColeta = responseNewColeta.data;

    const responseColetasEmAndamento = yield call(
      api.get,
      ApiEndPoints.transportadora.coletasEmAndamento
    );

    const coletas = responseColetasEmAndamento.data;

    yield put(createColetaSuccess(newColeta, coletas));
  } catch (err) {
    trataErros(err, 'Não foi possível criar coleta');
    yield put(createColetaFailure());
  }
}

export function* deleteColeta({ payload }) {
  try {
    const { coletaId } = payload;
    yield call(
      api.delete,
      `${ApiEndPoints.transportadora.coletas}/${coletaId}`
    );

    const responseColetasEmAndamento = yield call(
      api.get,
      ApiEndPoints.transportadora.coletasEmAndamento
    );

    yield put(deleteColetaSuccess(responseColetasEmAndamento.data));
    toast.success('Coleta excluída com sucesso');
  } catch (err) {
    trataErros(err, 'Coleta não pode ser excluída');
    yield put(deleteColetaFailure());
  }
}

export function* finalizaColeta({ payload }) {
  try {
    const { coletaId, body } = payload;

    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );

    const isToday =
      formatter(body.dataPrevisaoColeta).toDayOnlyISO() ===
      formatter(today).toDayOnlyISO();

    const responseDatas = yield call(
      api.post,
      ApiEndPoints.transportadora.datas(coletaId)
    );

    const { datai /*dataf*/ } = responseDatas.data;

    if (
      !isToday &&
      body.dataPrevisaoColeta < formatter(datai).dateOnlyToISO()
    ) {
      trataErros('', 'Data da coleta menor que a data mínima permitida');
      yield put(finalizaColetaFailure());
      //} else if (body.dataPrevisaoColeta > formatter(dataf).dateOnlyToISO()) {
      //  trataErros('', 'Data da coleta maior que a data máxima permitida');
      //  yield put(finalizaColetaFailure());
    } else if (!body.motoristaId) {
      trataErros('', 'Motorista deve ser informado');
      yield put(finalizaColetaFailure());
    } else if (!body.veiculoId) {
      trataErros('', 'Veículo deve ser informado');
      yield put(finalizaColetaFailure());
    } else {
      const responseVeiculo = yield call(
        api.get,
        `${ApiEndPoints.transportadora.veiculos}/${body.veiculoId}`
      );

      const responseColeta = yield call(
        api.get,
        `${ApiEndPoints.transportadora.coletas}/${coletaId}`
      );
      const veiculo = responseVeiculo.data;
      const { peso } = { ...responseColeta.data[0] };

      if (veiculo.pesoLiquido < peso) {
        toast.error('Peso da coleta ultrapassa o peso do caminhão');
      } else {
        yield call(
          api.post,
          `${ApiEndPoints.transportadora.coletas}/${coletaId}/finalizar`,
          body
        );

        yield put(finalizaColetaSuccess([]));
        history.push(PATHS.transportadora.pedidos);

        toast.success('Coleta finalizada com sucesso');
      }
    }
  } catch (err) {
    trataErros(err, 'Coleta não pode ser finalizada');
    history.push(PATHS.transportadora.coletasEmAndamento);

    yield put(finalizaColetaFailure());
  }
}

export function* reabreColeta({ payload }) {
  try {
    const { coletaId } = payload;

    yield call(
      api.post,
      `${ApiEndPoints.transportadora.coletas}/${coletaId}/reabrir`
    );

    const responseColetasEmAndamento = yield call(
      api.get,
      ApiEndPoints.transportadora.coletasEmAndamento
    );

    const coletas = responseColetasEmAndamento.data;

    yield put(reabreColetaSuccess(coletaId, coletas));
    toast.success('Coleta reaberta com sucesso');
  } catch (err) {
    trataErros(err, 'Coleta não pode ser reaberta');
    yield put(reabreColetaFailure());
  }
}

export function* addItemColeta({ payload }) {
  try {
    const { coletaId, cargaItemId } = payload;

    const response = yield call(
      api.post,
      ApiEndPoints.transportadora.coletaItens(coletaId),
      { cargaItemId }
    );

    const item = response.data;

    yield put(addItemColetaSuccess(item, cargaItemId));
  } catch (err) {
    trataErros(err, 'Não foi possível adicionar item à coleta');
    yield put(addItemColetaFailure());
  }
}

export function* updItemColeta({ payload }) {
  try {
    const { coletaId, coletaItemId, body } = payload;

    const response = yield call(
      api.put,
      `${ApiEndPoints.transportadora.coletaItens(coletaId)}/${coletaItemId}`,
      body
    );

    const item = response.data;

    yield put(updItemColetaSuccess(item));
  } catch (err) {
    trataErros(err, 'Item não pode ser atualizado');
    yield put(updItemColetaFailure());
  }
}

export function* delItemColeta({ payload }) {
  try {
    const { coletaId, coletaItemId } = payload;

    const response = yield call(
      api.delete,
      `${ApiEndPoints.transportadora.coletaItens(coletaId)}/${coletaItemId}`
    );

    const coleta = response.data;

    yield put(delItemColetaSuccess(coletaItemId, coleta));
  } catch (err) {
    trataErros(err, 'Item não pode ser excluído');
    yield put(delItemColetaFailure());
  }
}

export default all([
  takeLatest(Types.GET_COLETAS_EM_ANDAMENTO_REQUEST, getColetasEmAndamento),
  takeLatest(Types.GET_COLETAS_EMITIDAS_REQUEST, getColetasEmitidas),
  takeLatest(Types.CREATE_COLETA_REQUEST, createColeta),
  takeLatest(Types.DELETE_COLETA_REQUEST, deleteColeta),
  takeLatest(Types.FINALIZA_COLETA_REQUEST, finalizaColeta),
  takeLatest(Types.REABRE_COLETA_REQUEST, reabreColeta),
  takeLatest(Types.ADD_ITEM_COLETA_REQUEST, addItemColeta),
  takeLatest(Types.UPD_ITEM_COLETA_REQUEST, updItemColeta),
  takeLatest(Types.DEL_ITEM_COLETA_REQUEST, delItemColeta),
]);
