import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import trataErros from '../../../../services/trataErros';
import ApiEndPoints from '../../../../consts/apiEndpoints';
import { confirmationDialog } from '../../../../components/ConfirmationDialog';

import {
  getTransportadorasSuccess,
  getTransportadorasFailure,
  createTransportadoraSuccess,
  createTransportadoraFailure,
  updateTransportadoraSuccess,
  updateTransportadoraFailure,
  createConviteSuccess,
  createConviteFailure,
} from './actions';
import { Types } from './types';

import { statusConvite as TiposStatusConvite } from '../../../../consts/types';

export function* getTransportadoras() {
  try {
    const responseTransportadoras = yield call(
      api.get,
      ApiEndPoints.cliente.transportadoras
    );

    const { data: transportadoras } = responseTransportadoras;

    yield put(getTransportadorasSuccess(transportadoras));
  } catch (err) {
    trataErros(err, 'Não foi possível buscar as transportadoras');
    yield put(getTransportadorasFailure());
  }
}

export function* createTransportadora({ payload }) {
  try {
    const { transportadora } = payload;

    const response = yield call(
      api.post,
      ApiEndPoints.cliente.transportadoras,
      transportadora
    );

    const newTransportadora = response.data;

    yield put(createTransportadoraSuccess(newTransportadora));
    toast.success('Transportadora criada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível criar a transportadora');
    yield put(createTransportadoraFailure());
  }
}

export function* updateTransportadora({ payload }) {
  try {
    const { transportadora } = payload;

    // Check if email changed when invite is accepted
    if (
      transportadora.statusConvite === TiposStatusConvite.ACEITO &&
      transportadora.email !== transportadora.emailAnterior
    ) {
      const confirm = yield call(
        confirmationDialog.open,
        'Atenção!',
        'O email da transportadora foi alterado. Deseja continuar?'
      );

      confirmationDialog.close();

      if (!confirm) {
        yield put(updateTransportadoraFailure());
        return;
      }
    }

    // Remove virtual fields
    delete transportadora.emailAnterior;
    delete transportadora.statusConvite;

    const response = yield call(
      api.put,
      `${ApiEndPoints.cliente.transportadoras}/${transportadora.id}`,
      transportadora
    );

    const updatedTransportadora = response.data;

    yield put(updateTransportadoraSuccess(updatedTransportadora));
    toast.success('Transportadora atualizada com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível atualizar a transportadora');
    yield put(updateTransportadoraFailure());
  }
}

export function* createConvite({ payload }) {
  try {
    const { transportadora } = payload;
    const { id: transportadoraId, email } = transportadora;

    const response = yield call(
      api.post,
      ApiEndPoints.cliente.convitesTransportadoras(transportadoraId),
      { email }
    );

    const updatedTransportadora = response.data;

    yield put(createConviteSuccess(updatedTransportadora));
    toast.success('Convite enviado com sucesso');
  } catch (err) {
    trataErros(err, 'Não foi possível enviar o convite');
    yield put(createConviteFailure());
  }
}

export default all([
  takeLatest(Types.GET_TRANSPORTADORAS_REQUEST, getTransportadoras),
  takeLatest(Types.CREATE_TRANSPORTADORA_REQUEST, createTransportadora),
  takeLatest(Types.UPDATE_TRANSPORTADORA_REQUEST, updateTransportadora),
  takeLatest(Types.CREATE_CONVITE_REQUEST, createConvite),
]);

