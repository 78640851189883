import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import { colors } from '../../styles/colors';

import Notification from '../Notification';

export const StyledNotification = styled(Notification)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.header`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 20px 0 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${colors.avatar.text};

    div {
      display: flex;
      flex-direction: column;
      text-align: right;

      strong {
        font-size: 16px;
      }

      span {
        font-size: 12px;
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: 15px;
      margin-right: 15px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  button {
    background: none;
    border: 0;
    padding: 6px;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const Divider = styled.div`
  height: 50%;
  width: 1px;
  background: ${colors.divider};
  margin: 0 20px;
`;

export const MenuButton = styled(IconButton)``;
