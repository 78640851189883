import styled from 'styled-components';
import Paper from '../Paper';

import { defaultColors } from '../../styles/colors';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 250px;

  padding: 12px;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const Title = styled.p`
  font-size: 16px;
`;

export const Info = styled.p`  
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${ props => props.colortype === "Normal" ?  defaultColors.color12 : '#FF6A6A' };
`;

export const StyledErrorTwoToneIcon = styled(ErrorTwoToneIcon)`  
  padding-left: 5px;
  visibility: ${ props => props.colortype === 'Normal' ? 'hidden' : 'visible' };
  color: #DC143C;
`;
