import { Types } from './types';

export function getEmpresasRequest() {
  return {
    type: Types.GET_EMPRESAS_REQUEST,
  };
}

export function getEmpresasSuccess(empresas) {
  return {
    type: Types.GET_EMPRESAS_SUCCESS,
    payload: { empresas },
  };
}

export function getEmpresasFailure() {
  return {
    type: Types.GET_EMPRESAS_FAILURE,
  };
}

export function createEmpresaRequest(empresa) {
  return {
    type: Types.CREATE_EMPRESA_REQUEST,
    payload: { empresa },
  };
}

export function createEmpresaSuccess(empresa) {
  return {
    type: Types.CREATE_EMPRESA_SUCCESS,
    payload: { empresa },
  };
}

export function createEmpresaFailure() {
  return {
    type: Types.CREATE_EMPRESA_FAILURE,
  };
}

export function updateEmpresaRequest(empresa) {
  return {
    type: Types.UPDATE_EMPRESA_REQUEST,
    payload: { empresa },
  };
}

export function updateEmpresaSuccess(empresa) {
  return {
    type: Types.UPDATE_EMPRESA_SUCCESS,
    payload: { empresa },
  };
}

export function updateEmpresaFailure() {
  return {
    type: Types.UPDATE_EMPRESA_FAILURE,
  };
}

export function ativaEmpresaRequest(empresaId) {
  return {
    type: Types.ATIVA_EMPRESA_REQUEST,
    payload: { empresaId },
  };
}

export function ativaEmpresaSuccess(empresaId) {
  return {
    type: Types.ATIVA_EMPRESA_SUCCESS,
    payload: { empresaId },
  };
}

export function ativaEmpresaFailure() {
  return {
    type: Types.ATIVA_EMPRESA_FAILURE,
  };
}

export function desativaEmpresaRequest(empresaId) {
  return {
    type: Types.DESATIVA_EMPRESA_REQUEST,
    payload: { empresaId },
  };
}

export function desativaEmpresaSuccess(empresaId) {
  return {
    type: Types.DESATIVA_EMPRESA_SUCCESS,
    payload: { empresaId },
  };
}

export function desativaEmpresaFailure() {
  return {
    type: Types.DESATIVA_EMPRESA_FAILURE,
  };
}

export function deleteEmpresaRequest(empresaId) {
  return {
    type: Types.DELETE_EMPRESA_REQUEST,
    payload: { empresaId },
  };
}

export function deleteEmpresaSuccess(empresaId) {
  return {
    type: Types.DELETE_EMPRESA_SUCCESS,
    payload: { empresaId },
  };
}

export function deleteEmpresaFailure() {
  return {
    type: Types.DELETE_EMPRESA_FAILURE,
  };
}

export function deleteEmpresaCancel() {
  return {
    type: Types.DELETE_EMPRESA_CANCEL,
  };
}
