import { Types } from './types';

export function getDatasRequest() {
  return {
    type: Types.GET_DATAS_REQUEST,
  };
}

export function getDatasSuccess(datas) {
  return {
    type: Types.GET_DATAS_SUCCESS,
    payload: { datas },
  };
}

export function getDatasFailure() {
  return {
    type: Types.GET_DATAS_FAILURE,
  };
}

export function createDataRequest(data) {
  return {
    type: Types.CREATE_DATA_REQUEST,
    payload: { data },
  };
}

export function createDataSuccess(data) {
  return {
    type: Types.CREATE_DATA_SUCCESS,
    payload: { data },
  };
}

export function createDataFailure() {
  return {
    type: Types.CREATE_DATA_FAILURE,
  };
}

export function deleteDataRequest(data) {
  return {
    type: Types.DELETE_DATA_REQUEST,
    payload: { data },
  };
}

export function deleteDataSuccess(data) {
  return {
    type: Types.DELETE_DATA_SUCCESS,
    payload: { data },
  };
}

export function deleteDataFailure() {
  return {
    type: Types.DELETE_DATA_FAILURE,
  };
}
