import styled from 'styled-components';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Paper from '../../../components/Paper';

import { colors } from '../../../styles/colors';

export const StyledAddIcon = styled(AddIcon)`
  color: white;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${props => (props.ativo ? '#4ad991' : 'lightgrey')};
`;

export const StyledMailOutlineIcon = styled(MailOutlineIcon)`
  color: dodgerblue;
`;

export const TableContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  background: ${colors.pedidos.background};
`;

export const StyledFab = styled(Fab)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: #4ad991;
`;
